import React, { useEffect } from "react"

import QuoteFormContainer from "./containers/QuoteFormContainer"

import PageWrapper from "../../../components/PageWrapper"

import { checkBroadbandAvailability as apiCheckBroadbandAvailability } from "../../../services/tenantsApiV2"

import { getMatchingQuotingToolVersion } from "../services/utils"

export default function BroadbandCheckPage({ location, toolId, siteData }) {
  const [tenancyDetails, setTenancyDetails] = React.useState({})
  const [errors, setErrors] = React.useState([""])
  const [loading, setLoading] = React.useState(false)
  const [availableProducts, storeAvailableProducts] = React.useState([])

  useEffect(() => {
    document.title = "Bunch - Broadband Check"
  })

  let matchingQuotingToolVersion = getMatchingQuotingToolVersion(
    siteData,
    toolId
  )

  // If the url that the user has visited doesn't have a tool id, then we need to pick the default tool layout, which is set in Zoho
  if (
    !toolId &&
    siteData.allSiteSettings &&
    siteData.allSiteSettings.edges[0].node
  ) {
    toolId = siteData.allSiteSettings.edges[0].node.defaultQuoteToolLayout
      .toLowerCase()
      .replace(/_/, "-")
  }

  if (!matchingQuotingToolVersion) {
    return <div />
  }

  const handleContinueFromTenancyDetailsPage = async () => {
    try {
      setLoading(true)
      storeAvailableProducts([])

      const data = await apiCheckBroadbandAvailability(tenancyDetails)

      if (data.success) {
        storeAvailableProducts(data.value)
      } else if (data.errors) {
        setErrors([data.errors[0].reason])
      } else {
        setErrors(["An error occurred trying to invite tenant"])
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
      setErrors([e.message])
    }
  }

  let currentQuotingToolPage = {
    pageNumber: 1,
    title: "Check broadband availability",
    quotingToolPageFields: [
      {
        name: "Address",
        title: "Address",
        description: "Please enter an address.",
        order: 1,
        type: "Address",
        infoText: "",
        emptyErrorText: "Please enter an address.",
        invalidErrorText: "",
        minimumValue: 0,
        maximumValue: 0,
        defaultValue: 0,
        products: null,
        required: true,
        dropdownOptions: "",
        showServicePrices: false,
        showWeeklyPrice: false,
        showMonthlyPrice: false,
        showYearlyPrice: false,
        quotingToolMultipliers: [],
      },
    ],
  }

  return (
    <PageWrapper
      location={location}
      quoteViewMode={true}
      backgroundColor="#fff"
      hideHeaderBorder={true}
    >
      {matchingQuotingToolVersion.customCSSURL && (
        <link
          rel="stylesheet"
          type="text/css"
          href={matchingQuotingToolVersion.customCSSURL}
        />
      )}
      <QuoteFormContainer
        quotingToolPage={currentQuotingToolPage}
        setErrors={setErrors}
        loading={loading}
        errors={errors}
        onContinue={() => handleContinueFromTenancyDetailsPage()}
        matchingQuotingToolVersion={matchingQuotingToolVersion}
        tenancyDetails={tenancyDetails}
        setTenancyDetails={payload => setTenancyDetails(payload)}
        backgroundColor={"#f6f8f9"}
      />
      {availableProducts && availableProducts.length > 0 && (
        <div
          style={{
            color: "#fff",
            margin: "0 auto",
            maxWidth: 700,
            padding: "0 20px",
          }}
        >
          <div style={{ fontSize: 17, marginBottom: 10 }}>
            Available broadband products
          </div>
          {availableProducts.map(availableProduct => (
            <div>
              <div style={{ fontSize: 13 }}>{availableProduct.productName}</div>
            </div>
          ))}
        </div>
      )}
    </PageWrapper>
  )
}
