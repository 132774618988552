import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import { StaticQuery, graphql } from "gatsby"

const useStyles = makeStyles((theme) => ({
  trustpilotBanner: {
    backgroundColor: "#efefef",
    width: "100%",
    padding: "80px 40px",
    marginBottom: 40,
    textAlign: 'center',
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "200px",
    },
  },
}))

const TrustpilotFooter = () => {
  // Create a reference to the <div> element which will represent the TrustpilotFooter
  const ref = React.useRef(null)
  React.useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true)
    }
  }, [])

  const classes = useStyles();

  const renderTrustpilot = data => {
    if (
      !data ||
      !data.allSiteSettings ||
      !data.allSiteSettings.edges ||
      !data.allSiteSettings.edges[0].node.enableTrustPilotCarousel
    ) {
      return <div />
    }

    return (
      <div
        className={clsx(classes.trustpilotBanner, "quotingTool-trustPilotBanner")}
      >
        <div style={{
          fontFamily: "new-spirit",
          fontSize: "36px",
          fontWeight: "600",
          marginBottom: "80px"
        }}>Join more than 25,000 <span style={{ color: "rgb(46, 182, 100)", fontFamily: "new-spirit", }}>happy customers.</span></div>
        <div
          ref={ref} // We need a reference to this element to load the TrustBox in the effect.
          className="trustpilot-widget"
          data-locale="en-US"
          data-template-id="53aa8912dec7e10d38f59f36"
          data-businessunit-id="5d4c472163c4490001ae45d0"
          data-style-height="140px"
          data-style-width="100%"
          data-theme="light"
          data-stars="4,5"
          data-review-languages="en"
          data-font-family="mundial"
          style={{ maxWidth: 1200, margin: "0 auto" }}
        >
          <a
            href="https://www.trustpilot.com/review/the-bunch.co.uk"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            Trustpilot
          </a>
        </div>
      </div>
    )
  }

  return (
    <StaticQuery
      query={graphql`
        query GetTrustPilotCarouselEnabled {
          allSiteSettings {
            edges {
              node {
                id
                enableTrustPilotCarousel
              }
            }
          }
        }
      `}
      render={data => renderTrustpilot(data)}
    />
  )
}
export default TrustpilotFooter
