import React from "react"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
    padding: "0px",
    width: "100%",
  },
  tab: {
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
    padding: "0px",
    width: "100%",
  },
  "@media only screen and (max-width: 600px)": {
    root: {
      display: "flex",
      flexDirection: "row",
      overflow: "hidden",
      padding: "0px",
      width: "100%",
    },
    tab: {
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
      padding: "0px",
      width: "100%",
    },
  },
}))

function Tabs(props) {
  const { children } = props
  const classes = useStyles()
  return (
    <div className={classes.root}>
      {children.map((t, index) => (
        <div className={classes.tab} key={index}>
          {t}
        </div>
      ))}
    </div>
  )
}
export default Tabs
