import React from "react"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    padding: "0px 10px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 5px",
      // maxWidth: "20%",
      margin: "20px 0px",
    },
  },
  disabled: {
    opacity: 0.5,
    cursor: "default",
  },
}))

export default function MarkerWrapper({
  children,
  onClick,
  disabled,
  numberOfPages,
}) {
  const classes = useStyles()

  return (
    <div
      className={clsx(classes.root, {
        [classes.disabled]: disabled,
      })}
      onClick={onClick}
      onKeyPress={onClick}
      role="button"
      tabIndex="0"
      style={{
        width: `${100 / numberOfPages}%`,
        cursor: onClick && "pointer",
      }}
    >
      {children}
    </div>
  )
}
