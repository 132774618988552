// extracted by mini-css-extract-plugin
export var favouriteProperty = "propertyResult-module--favouriteProperty--2g1V0";
export var favouritePropertyIcon = "propertyResult-module--favouritePropertyIcon--2Fi2p";
export var propertyBedsAndBathroomText = "propertyResult-module--propertyBedsAndBathroomText--f1bWd";
export var propertyBedsAndBathrooms = "propertyResult-module--propertyBedsAndBathrooms--CiPqq";
export var propertyBedsAndBathroomsIcon = "propertyResult-module--propertyBedsAndBathroomsIcon--Gl452";
export var propertyDetails = "propertyResult-module--propertyDetails--Rl-As";
export var propertyDetailsLineOne = "propertyResult-module--propertyDetailsLineOne--1Invn";
export var propertyDetailsLineTwo = "propertyResult-module--propertyDetailsLineTwo--lid19";
export var propertyImageWrapper = "propertyResult-module--propertyImageWrapper--CIRMC";
export var propertyLink = "propertyResult-module--propertyLink--vUAqM";
export var propertyPricePerWeek = "propertyResult-module--propertyPricePerWeek--Ihvv1";
export var propertyResult = "propertyResult-module--propertyResult--cFyh-";
export var swipePropertyResult = "propertyResult-module--swipePropertyResult--OHGue";