import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Button as MuiButton } from "@material-ui/core"
import clsx from "clsx"

import FormLabels from "./FormLabels"

const useStyles = makeStyles({
  option: {
    width: 125,
    height: 45,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 30,
    marginRight: 10,
    marginBottom: 10,
    color: "#000",
    border: "2px solid #f7c53d",
    cursor: "pointer",
    "-webkit-transition": "background-color 500ms ease-out",
    "-moz-transition": "background-color 500ms ease-out",
    "-o-transition": "background-color 500ms ease-out",
    transition: "background-color 500ms ease-out",
  },
  selectedOption: {
    backgroundColor: "#f7c53d",
    color: "#000",
    fontWeight: "bold",
  },
})

export default function Slider({
  value,
  onChange,
  fieldIndex,
  label,
  description,
  popupText,
  min,
  max,
  rangeUnits,
  white,
}) {
  const classes = useStyles()

  const renderButtons = () => {
    let buttons = []

    for (let i = min; i < max + 1; i++) {
      buttons.push(
        <div
          onClick={() => onChange(i)}
          className={clsx(classes.option, "quotingTool-rangeButtonOption", {
            [classes.selectedOption]: value === i,
            ["quotingTool-rangeButtonSelectedOption"]: value === i,
          })}
          tabIndex="0"
          role="button"
          onKeyPress={() => onChange(i)}
        >
          {i} {rangeUnits}
        </div>
      )
    }

    return buttons
  }

  return (
    <div style={{ width: "100%" }}>
      <FormLabels
        label={label}
        description={description}
        popupText={popupText}
        white={white}
        fieldIndex={fieldIndex}
      />
      <div style={{ maxWidth: "100%", padding: "0px 15px", marginTop: 20 }}>
        {renderButtons()}
      </div>
    </div>
  )
}
