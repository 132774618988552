import React, { useContext } from "react"

import {
  getMe as apiGetMe,
  updateProfile as apiUpdateProfile,
  updatePassword as apiUpdatePassword,
} from "../../services/tenantsApiV2"

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../../context/GlobalContextProvider"

import PageWrapper from "../../components/PageWrapper"
import Form from "../../components/Form"
import AppTitle from "../../components/appTitle/AppTitle"
import Frame from "../../components/frame/Frame"

import * as editProfileStyles from "./editProfile.module.css"

const editPasswordOptions = {
  fields: [
    {
      name: "oldPassword",
      placeholder: "Old password",
      type: "password",
      required: true,
    },
    {
      name: "newPassword",
      placeholder: "New password",
      type: "password",
      required: true,
    },
    {
      name: "repeatPassword",
      placeholder: "Repeat password",
      type: "password",
      required: true,
    },
  ],
  submitText: "Update password",
}

export default ({ location }) => {
  const dispatch = useContext(GlobalDispatchContext)
  const context = useContext(GlobalStateContext)
  const [
    updatingContactInformation,
    setUpdatingContactInformation,
  ] = React.useState(false)
  const [
    updateContactInformationError,
    setUpdateContactInformationError,
  ] = React.useState("")
  const [updatingPassword, setUpdatingPassword] = React.useState(false)
  const [updatePasswordError, setUpdatePasswordError] = React.useState("")

  const getMe = async () => {
    try {
      const data = await apiGetMe()
      if (data.value) {
        dispatch({
          type: "storeTenantUser",
          tenantUser: data.value,
        })
      }
    } catch (e) {
      console.log(e)
    }
  }

  const updateContactInformation = async payload => {
    console.log(payload)
    if (!payload || !payload.email) {
      return setUpdateContactInformationError("Please complete all fields")
    }

    setUpdateContactInformationError("")

    try {
      setUpdatingContactInformation(true)
      const data = await apiUpdateProfile({ ...context.tenantUser, ...payload })

      if (data.success) {
        if (data.value) {
          dispatch({
            type: "storeTenantUser",
            tenantUser: data.value,
          })
        }
      } else if (data.errors) {
        setUpdateContactInformationError(data.errors[0].reason)
      } else {
        setUpdateContactInformationError(
          "An error occurred trying to update profile"
        )
      }
      setUpdatingContactInformation(false)
      getMe()
    } catch (e) {
      setUpdatingContactInformation(false)
      setUpdateContactInformationError(e.message)
    }
  }

  const updatePassword = async payload => {
    if (
      !payload ||
      !payload.oldPassword ||
      !payload.newPassword ||
      !payload.repeatPassword
    ) {
      return setUpdatePasswordError("Please complete all fields")
    }

    if (payload.newPassword !== payload.repeatPassword) {
      return setUpdatePasswordError("Passwords do not match")
    }

    setUpdatePasswordError("")
    try {
      setUpdatingPassword(true)
      const data = await apiUpdatePassword(payload)
      if (data.success) {
        console.log(data)
      } else if (data.errors) {
        setUpdatePasswordError(data.errors[0].reason)
      } else {
        setUpdatePasswordError("An error occurred trying to update profile")
      }
      setUpdatingPassword(false)
    } catch (e) {
      setUpdatingPassword(false)
      setUpdatePasswordError(e.message)
    }
  }

  let editContactInformationOptions = {
    fields: [
      {
        name: "firstName",
        placeholder: "First name",
        type: "text",
        required: true,
        disabled: false,
      },
      {
        name: "lastName",
        placeholder: "Last name",
        type: "text",
        required: true,
        disabled: false,
      },
      {
        name: "email",
        placeholder: "Email address",
        type: "email",
        required: true,
        disabled: true,
      },
      {
        name: "phoneNumber",
        placeholder: "Mobile phone number",
        type: "text",
        required: false,
        disabled: true,
      },
    ],
    submitText: "Save",
  }

  return (
    <PageWrapper
      location={location}
      showLoadingIndicator={!context.tenantUser || !context.tenantUser.email}
      showSidebar={true}
      showPortalHeader={true}
      hideHeader={true}
    >
      {context.tenantUser && (
        <div className={editProfileStyles.editProfile}>
          <AppTitle title="Account Details" />
          <div>
            <Frame>
              <Form
                options={editContactInformationOptions}
                onSubmit={payload => updateContactInformation(payload)}
                submitting={updatingContactInformation}
                apiErrorMessage={updateContactInformationError}
                buttonPosition="floatLeft"
                initialPayload={{
                  firstName: context.tenantUser.firstName,
                  lastName: context.tenantUser.lastName,
                  email: context.tenantUser.email,
                  phoneNumber: context.tenantUser.phoneNumber,
                  forwardingAddress: context.tenantUser.forwardingAddress,
                }}
                showCallUs={true}
                reducedPadding={true}
              />
            </Frame>
            <Frame>
              <Form
                options={editPasswordOptions}
                onSubmit={payload => updatePassword(payload)}
                submitting={updatingPassword}
                apiErrorMessage={updatePasswordError}
                reducedPadding={true}
              />
            </Frame>
          </div>
        </div>
      )}
    </PageWrapper>
  )
}
