import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Tooltip from "@material-ui/core/Tooltip"
import InfoIcon from "@material-ui/icons/Info"

const useStyles = makeStyles({
    primary: {
        color: "#fff",
        "& .MuiButtonBase-root": {
            color: "#fff",
        },
    },
    tooltip: {
        fontSize: 17,
        opacity: 1,
        zIndex: 9999999999,
        "& a": {
            color: '#fff !important',
            textDecorationLine: 'underline'
        }
    },
    infoIcon: {
        fontSize: 15,
        cursor: "pointer",
        marginBottom: 4
    },
})

export default function ProductTooltip({
    description
}) {
    const classes = useStyles()

    return (
        <Tooltip
            title={
                <div>
                    {description && (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: description,
                            }}
                        />
                    )}
                </div>
            }
            classes={{ tooltip: classes.tooltip }}
            interactive
            disableHoverListener={description === undefined}
            enterTouchDelay={0}
            leaveTouchDelay={5000}
            role="button"
            tabIndex="0"
        >
            <InfoIcon className={classes.infoIcon} />
        </Tooltip>
    )
}