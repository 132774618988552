import React from "react"
import { makeStyles } from "@material-ui/core/styles"
const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#243373",
    width: "100%",
    height: "100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  "@media only screen and (max-width: 600px)": {
    root: {
      width: "100%",
      height: "50px",
      display: "flex",
      alignItems: "center",
    },
  },
}))

export default function TopBar(props) {
  const classes = useStyles()
  const { children } = props

  return <div className={classes.root}>{children}</div>
}
