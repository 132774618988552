import React from "react"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(() => ({
  inactive: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    color: "#b4b4b4",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "10px",
    backgroundColor: "#22544e",
    border: "1px solid white",
  },
  active: {
    color: "#22544e",
    backgroundColor: "#fff",
  },
  "@media only screen and (max-width: 600px)": {
    inactive: {
      width: "30px",
      height: "30px",
      borderRadius: "50%",
      color: "#b4b4b4",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "5px",
      backgroundColor: "#22544e",
      border: "1px solid white",
    },
    active: {
      color: "#22544e",
      backgroundColor: "#fff",
    },
  },
}))

export default function Marker({
  completed,
  active,
  children,
  activePageStepHexCode,
  completedPageStepHexCode,
  incompletePageStepHexCode,
}) {
  const classes = useStyles()

  const parseHexCode = hexCode => {
    return `#${hexCode.replace("#", "")}`
  }

  const getCustomisedClassName = () => {
    if (completed) {
      return "quotingTool-completedStepCircle"
    }

    if (active) {
      return "quotingTool-activeStepCircle"
    }

    return "quotingTool-stepCircle"
  }

  return (
    <div>
      <div
        className={clsx(
          classes.inactive,
          {
            [classes.active]: active,
          },
          getCustomisedClassName()
        )}
      >
        {children}
      </div>
    </div>
  )
}
