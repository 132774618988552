import React, { useState } from "react"
import { Popper } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import MenuItem from "@material-ui/core/MenuItem"
import NativeSelect from "@material-ui/core/NativeSelect"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"

import FormLabels from "./FormLabels"

export default function Dropdown({
  label,
  description,
  popupText,
  value,
  setValue,
  error,
  helperText,
  labels,
  disabled,
  placeholder,
  options,
  autocomplete,
  setSearchTerm,
  multiple,
  setDefaultOpen,
  fieldIndex,
  studioOption
}) {
  const useStyles = makeStyles(theme => ({
    root: {
      border: "1px solid #CCCCCC",
      backgroundColor: "#fff",
      borderRadius: "4px",
      display: "flex",
      flexGrow: "1",
      fontSize: "16px",
      lineHeight: "16px",
      fontFamily: "mundial",
      width: "100%",
      maxWidth: "100%",
      height: "51px",
      marginTop: "10px",
      "& .MuiInputBase-input": {
        padding: "10px",
      },
    },
    error: {
      border: "1px solid #F44336",
      color: "#F44336",
    },
    errorText: {
      color: "#F44336",
      fontSize: "12px",
      fontFamily: "mundial",
    },
    autocomplete: {
      "& .MuiInputBase-root": {
        backgroundColor: "#fff",
      },
      width: "100%",
      maxWidth: "100%",
      marginTop: "10px",
      marginBottom: "10px",
    },
  }))
  const classes = useStyles()

  const [open, setOpen] = useState(setDefaultOpen)

  const classesToApply = error
    ? `${classes.root} ${classes.error}`
    : classes.root

  let dropdownOptions = options
  if (labels) {
    dropdownOptions = labels.map(label => ({
      value: label,
      label,
    }))
    if (studioOption) {
      dropdownOptions[0].label = "Studio / 1"
    }
  }

  const renderElement = () => {
    if (dropdownOptions && autocomplete) {
      return (
        <Autocomplete
          id="combo-box-demo"
          options={dropdownOptions}
          className={classes.autocomplete}
          value={value && dropdownOptions.find(_ => _.label === value)}
          onChange={(e, val) => (val ? setValue(val.value) : setValue(null))}
          getOptionLabel={option => option.label}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          renderInput={params => (
            <TextField
              {...params}
              error={error}
              variant={"outlined"}
              onChange={e => setSearchTerm && setSearchTerm(e.target.value)}
            />
          )}
        />
      )
    }

    let currentValue = value
    if (multiple) {
      if (value) {
        currentValue = value.split(",")
      } else {
        currentValue = []
      }
    } else if (!value) {
      currentValue = ""
    }

    return (
      <NativeSelect
        disableUnderline
        value={currentValue}
        className={classesToApply}
        onChange={e => {
          if (multiple) {
            if (e.target.value) {
              setValue(e.target.value.join(","))
            } else {
              setValue("")
            }
          } else {
            setValue(e.target.value)
          }
        }}
        disabled={disabled}
        multiple={multiple}
      >
        {placeholder && <option value={""} disabled>{placeholder}</option>}
        {dropdownOptions &&
          dropdownOptions.map((option, index) => (
            <option key={index + 1} value={option.value}>
              {option.label}
            </option>
          ))}
      </NativeSelect>
    )
  }

  return (
    <>
      <div style={{ width: "100%" }}>
        <FormLabels
          label={label}
          description={description}
          popupText={popupText}
          fieldIndex={fieldIndex}
        />
        {renderElement()}
        {error ? <div className={classes.errorText}>{helperText}</div> : null}
      </div>
    </>
  )
}
