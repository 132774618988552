import React, { useEffect, useState } from "react"
import clsx from "clsx"
import { navigate } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import {
  FormLine,
  InputSection,
  Errors,
  Centered1200Wrapper,
  Button,
  PageTitle,
} from "../../components"
import {
  validateName,
  validateEmail,
  validateMobilePhone,
  uniqueMobilePhone,
} from "../../services/errorValidation"
import Checkbox from "@material-ui/core/Checkbox"

const useStyles = makeStyles({
  checkbox: {
    color: "#000 !important",
    paddingLeft: "0px",
  },
  checkboxLabel: {
    fontSize: "15px",
    fontFamily: "mundial",
    color: "#000 !important",
    "& a": {
      color: "#000 !important",
    },
  },
})

const isNullOrEmptyString = val =>
  val === "" || val === undefined || val === null

export default function InviteFormContainer({
  inviteDetails,
  setInviteDetails,
  groupLeadEmail,
  groupLeadPhoneNumber,
  buttonOnClick,
  buttonSubmitting,
  errors,
  successfulResponse,
  isHomeownerOrRenter,
}) {
  const classes = useStyles()

  React.useEffect(() => {
    document.title = "Bunch - Invite Housemates"
  }, [])

  const [submitAttempted, setSubmitAttempted] = useState(false)

  const initialErrorState = []
  for (let i = 0; i < inviteDetails.length; i++) {
    initialErrorState.push({
      firstName: null,
      lastName: null,
      email: null,
      number: null,
    })
  }
  const [validationErrors, setValidationErrors] = React.useState(
    initialErrorState
  )

  useEffect(() => {
    if (
      errors &&
      errors.filter(error => error === "Please enter a valid email address")
        .length > 0
    ) {
      for (let i = 0; i < errors.length; i++) {
        let newErrs = [...validationErrors]
        newErrs[i].email = errors[i]
        setValidationErrors(newErrs)
      }
    }
  }, [errors, validationErrors])

  return (
    <Centered1200Wrapper>
      <div style={{ marginBottom: "20px" }}>
        <PageTitle
          style={{ textAlign: "left" }}
        >
          <div className="quotingTool-pageTitle">
            {isHomeownerOrRenter ? "Invite bill payers" : "Invite housemates"}
          </div>
        </PageTitle>
        <div
          style={{
            fontWeight: "400",
            color: "#000",
            opacity: "0.54",
            marginBottom: 20,
          }}
          className="quotingTool-pageDescription"
        >
          {isHomeownerOrRenter
            ? "Enter the bill payer's emails below - we will ask them to create their accounts"
            : "Enter your housemates' emails below - we will ask them to create their accounts"}
        </div>
      </div>

      {inviteDetails.map((lead, index) => (
        <div key={index}>
          {inviteDetails.length > 0 && (
            <div style={{ color: "#000", fontWeight: "bold" }}>
              {isHomeownerOrRenter ? "Bill payer" : "Housemate"} {index + 1}
            </div>
          )}
          <FormLine>
            <InputSection
              label="First name*"
              popupText="This will be the name we email a link to"
              error={validationErrors[index].firstName ?? false}
              helperText={validationErrors[index].firstName ?? null}
              value={lead.firstName}
              submitAttempted={submitAttempted}
              onChange={e => {
                const temp = [...inviteDetails]
                temp[index] = {
                  email: lead.email,
                  firstName: e.target.value,
                  lastName: lead.lastName,
                  number: lead.number,
                  isMissingInformation: lead.isMissingInformation,
                }
                const err =
                  e.target.value === "" ? "First name is required" : null
                let newErrs = [...validationErrors]
                newErrs[index].firstName = err
                setValidationErrors(newErrs)
                setInviteDetails(temp)
              }}
            />
          </FormLine>
          <FormLine>
            <InputSection
              label="Last name*"
              popupText={
                isHomeownerOrRenter
                  ? "We need the surname to make sure we’ve got the right bill payer"
                  : "We need the surname to make sure we’ve got the right housemate"
              }
              value={lead.lastName}
              error={validationErrors[index].lastName ?? false}
              helperText={validationErrors[index].lastName ?? null}
              submitAttempted={submitAttempted}
              onChange={e => {
                const temp = [...inviteDetails]
                let newErrs = [...validationErrors]
                temp[index] = {
                  email: lead.email,
                  firstName: lead.firstName,
                  lastName: e.target.value,
                  number: lead.number,
                  isMissingInformation: lead.isMissingInformation,
                }
                const err =
                  e.target.value === "" ? "Last name is required" : null
                newErrs[index].lastName = err
                setValidationErrors(newErrs)
                setInviteDetails(temp)
              }}
            />
          </FormLine>
          <FormLine>
            <InputSection
              label="Email"
              popupText="This is the email we’ll contact them on"
              value={lead.email}
              error={validationErrors[index].email ?? false}
              helperText={validationErrors[index].email ?? null}
              submitAttempted={submitAttempted}
              onChange={e => {
                const temp = [...inviteDetails]
                temp[index] = {
                  email: e.target.value,
                  firstName: lead.firstName,
                  lastName: lead.lastName,
                  number: lead.number,
                  isMissingInformation: lead.isMissingInformation,
                }
                let newErrs = [...validationErrors]

                // This is pretty cursed - on changing one email, we must check all others and update errors for all email fields
                // in case there is a match.

                // but ofc we can't have normal checks overriding everything with 'null' if there are no (other) errors
                // so we should check for all errors on all fields every time we update email.

                for (let i = 0; i < inviteDetails.length; i++) {
                  for (let j = 0; j < inviteDetails.length; j++) {
                    if (i === j) {
                      if (
                        temp[i].firstName &&
                        !validateName(temp[i].firstName)
                      ) {
                        newErrs[i].firstName = "Please enter a valid name"
                        continue
                      }

                      if (temp[i].lastName && !validateName(temp[i].lastName)) {
                        newErrs[i].lastName = "Please enter a valid name"
                        continue
                      }

                      if (
                        !temp[i].isMissingInformation &&
                        temp[i].email === ""
                      ) {
                        newErrs[i].email = "Email address is required"
                        continue
                      }

                      if (temp[i].email && !validateEmail(temp[i].email)) {
                        newErrs[i].email = "Please enter a valid email address"
                        continue
                      }

                      if (temp[i].email === groupLeadEmail) {
                        newErrs[i].email =
                          "This email address already exists in your group"
                      } else {
                        newErrs[i].email = null
                      }

                      continue
                    }

                    if (
                      temp[i].email === temp[j].email &&
                      temp[i].email !== "" &&
                      temp[j].email !== ""
                    ) {
                      newErrs[i].email =
                        "This email address already exists in your group"
                      newErrs[j].email =
                        "This email address already exists in your group"
                    } else {
                      if (temp[i].email === groupLeadEmail) {
                        newErrs[i].email =
                          "This email address already exists in your group"
                      } else {
                        newErrs[i].email = null
                      }
                    }
                  }
                }

                setValidationErrors(newErrs)
                setInviteDetails(temp)
              }}
            />
          </FormLine>
          <FormLine>
            <InputSection
              label="Mobile phone number"
              value={lead.number}
              popupText="If we can’t get hold of them on email, we’ll text them on this number"
              error={validationErrors[index].number ?? false}
              helperText={validationErrors[index].number ?? null}
              submitAttempted={submitAttempted}
              onChange={e => {
                const val = e.target.value
                const temp = [...inviteDetails]
                temp[index] = {
                  email: lead.email,
                  firstName: lead.firstName,
                  lastName: lead.lastName,
                  number: val,
                  isMissingInformation: lead.isMissingInformation,
                }
                let newErrs = [...validationErrors]

                if (!temp[index].isMissingInformation && !temp[index].number) {
                  newErrs[index].number = "Phone number is required"
                } else if (temp[index].number) {
                  if (!validateMobilePhone(temp[index].number)) {
                    newErrs[index].number = "Please enter a valid phone number"
                  } else if (
                    !uniqueMobilePhone(temp[index].number, groupLeadPhoneNumber)
                  ) {
                    newErrs[index].number =
                      "This phone number already exists in your group"
                  } else {
                    for (let i = 0; i < inviteDetails.length; i++) {
                      newErrs[i].number = null
                      if (index !== i && temp[i].number && temp[index].number) {
                        if (
                          !uniqueMobilePhone(temp[i].number, temp[index].number)
                        ) {
                          newErrs[i].number =
                            "This phone number already exists in your group"
                          newErrs[index].number =
                            "This phone number already exists in your group"
                        }
                      }
                    }
                  }
                } else {
                  newErrs[index].number = ""
                }

                setValidationErrors(newErrs)
                setInviteDetails(temp)
              }}
            />
          </FormLine>{" "}
          <FormLine>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  color="primary"
                  id="agree"
                  name="agree"
                  checked={lead.isMissingInformation}
                  value={lead.isMissingInformation}
                  className={clsx(classes.checkbox, "quotingTool-checkbox")}
                  onChange={e => {
                    const temp = [...inviteDetails]
                    temp[index] = {
                      email: lead.email,
                      firstName: lead.firstName,
                      lastName: lead.lastName,
                      number: lead.number,
                      isMissingInformation: e.target.checked,
                    }

                    let newErrs = [...validationErrors]

                    if (e.target.checked) {
                      newErrs[index] = {
                        firstName:
                          isNullOrEmptyString(lead.firstName) &&
                          newErrs[index].firstName,
                        lastName:
                          isNullOrEmptyString(lead.lastName) &&
                          newErrs[index].lastName,
                        email: isNullOrEmptyString(lead.email) && "",
                        number: isNullOrEmptyString(lead.number) && "",
                      }
                    }
                    setValidationErrors(newErrs)
                    setInviteDetails(temp)
                  }}
                />
                <div
                  htmlFor="agree"
                  style={{
                    fontSize: "15px",
                    fontFamily: "mundial",
                    color: "#000",
                  }}
                  className={clsx(
                    classes.checkboxLabel,
                    "quotingTool-checkboxLabel"
                  )}
                >
                  {isHomeownerOrRenter
                    ? "I am missing information for this bill payer"
                    : "I am missing information for this housemate"}
                </div>
              </div>
            </div>
          </FormLine>
        </div>
      ))}
      <Errors>{errors}</Errors>
      {!successfulResponse && (
        <Button
          className={clsx(
            "SignUp-Step-04-InviteHousemates",
            "quotingTool-submitButton"
          )}
          fullWidth
          onClick={() => {
            setSubmitAttempted(true)

            let newErrs = [...validationErrors]

            inviteDetails.forEach((x, index) => {
              newErrs[index] = {}

              if (
                x.firstName === null ||
                x.firstName === undefined ||
                x.firstName === ""
              ) {
                newErrs[index].firstName = "First name is required"
              }

              if (
                x.lastName === null ||
                x.lastName === undefined ||
                x.lastName === ""
              ) {
                newErrs[index].lastName = "Last name is required"
              }

              if (x.firstName && !validateName(x.firstName)) {
                newErrs[index].firstName = "Please enter a valid name"
              }

              if (x.lastName && !validateName(x.lastName)) {
                newErrs[index].lastName = "Please enter a valid name"
              }

              if (!x.isMissingInformation && !x.email) {
                newErrs[index].email = "Email address is required"
              }

              if (x.email && !validateEmail(x.email)) {
                newErrs[index].email = "Please enter a valid email address"
              }

              if (x.email && x.email === groupLeadEmail) {
                newErrs[index].email = "This email already exists in your group"
              }

              for (let i = 0; i < inviteDetails.length; i++) {
                if (
                  i !== index &&
                  x.email &&
                  inviteDetails[i].email === x.email
                ) {
                  newErrs[index].email =
                    "This email already exists in your group"
                }
              }

              if (!x.isMissingInformation && !x.number) {
                newErrs[index].number =
                  "Please enter a valid mobile phone number"
              } else if (x.number) {
                if (!uniqueMobilePhone(x.number, groupLeadPhoneNumber)) {
                  newErrs[index].number =
                    "This phone number already exists in your group"
                } else {
                  for (let i = 0; i < inviteDetails.length; i++) {
                    if (
                      i !== index &&
                      !uniqueMobilePhone(x.number, inviteDetails[i].number)
                    ) {
                      newErrs[index].number =
                        "This phone number already exists in your group"
                    }
                  }
                }
              }
            })
            console.log("newErrs", newErrs)
            setValidationErrors(newErrs)

            let err = false
            newErrs.forEach(x => {
              if (x.email || x.firstName || x.lastName || x.number) {
                err = true
              }
            })

            if (!err) {
              buttonOnClick()
            }
          }}
        >
          {buttonSubmitting ? "Saving..." : "Continue"}
        </Button>
      )}
      {successfulResponse && (
        <div style={{ marginTop: 20, color: "#000" }}>
          An email will be sent to the tenant shortly
        </div>
      )}
    </Centered1200Wrapper>
  )
}
