import React from "react"
import { Link } from "gatsby"

import { requestPasswordReset as apiRequestPasswordReset } from "../../services/tenantsApiV2"

import PageWrapper from "../../components/PageWrapper"
import AuthenticationWrapper from "../../components/authenticationWrapper/AuthenticationWrapper"
import Form from "../../components/Form"

import * as authPageStyles from "../authPage.module.css"

const forgotPasswordFormOptions = {
  fields: [
    {
      placeholder: "Email address",
      type: "email",
      required: true,
      name: "email",
    },
  ],
  submitText: "Request password reset",
}

const ForgotPassword = ({ location }) => {
  const [requestingPasswordReset, setRequestingPasswordReset] = React.useState(
    false
  )
  const [
    requestPasswordResetError,
    setRequestPasswordResetError,
  ] = React.useState("")
  const [
    requestPasswordResetMessage,
    setRequestPasswordResetMessage,
  ] = React.useState("")

  const requestPasswordReset = async payload => {
    setRequestPasswordResetError("")

    try {
      setRequestingPasswordReset(true)
      await apiRequestPasswordReset(payload)
      setRequestPasswordResetMessage(
        "If this email exists, we have sent you a password reset email"
      )
      setRequestingPasswordReset(false)
    } catch (e) {
      console.log(e)
      setRequestingPasswordReset(false)
      setRequestPasswordResetMessage(
        "If this email exists, we have sent you a password reset email"
      )
    }
  }

  return (
    <PageWrapper location={location} hideHeader={true} hideHeaderBorder={true}>
      <div className={authPageStyles.authPage}>
        <AuthenticationWrapper
          title="Tenant portal"
          description={"Request a password reset email"}
        >
          <Form
            options={forgotPasswordFormOptions}
            onSubmit={payload => requestPasswordReset(payload)}
            submitting={requestingPasswordReset}
            apiErrorMessage={requestPasswordResetError}
            apiSuccessMessage={requestPasswordResetMessage}
          />
          <div>
            Already have an account?{" "}
            <Link to="/tenants/app/login">Sign in</Link>
          </div>
        </AuthenticationWrapper>
      </div>
    </PageWrapper>
  )
}

export default ForgotPassword
