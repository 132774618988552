import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Tooltip from "@material-ui/core/Tooltip"
import InfoIcon from "@material-ui/icons/Info"

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexGrow: "1",
    flexDirection: "column",
  },
  text: {
    fontSize: "24px",
    lineHeight: "32px",
    color: "#000",
    marginBottom: 10,
    // fontWeight: "bold",
  },
  subtext: {
    fontSize: "16px",
    lineHeight: "24px",
    color: "#000",
  },
  tooltip: {
    fontSize: 17,
    opacity: 1,
    zIndex: 9999999999,
  },
  infoIcon: {
    fontSize: 15,
    cursor: "pointer",
  },
}))

export default function TextWithSubtext({ text, subtext, popupText }) {
  const styles = useStyles()

  const infoIcon = tooltipText => {
    return (
      <Tooltip
        title={
          <div>
            {tooltipText && (
              <div
                dangerouslySetInnerHTML={{
                  __html: tooltipText,
                }}
              />
            )}
          </div>
        }
        classes={{ tooltip: styles.tooltip }}
        interactive
        disableHoverListener={tooltipText === undefined}
        enterTouchDelay={0}
        leaveTouchDelay={5000}
        role="button"
        tabIndex="0"
      >
        <InfoIcon className={styles.infoIcon} />
      </Tooltip>
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.text}>
        {text} {popupText && infoIcon(popupText)}
      </div>
      <div className={styles.subtext}>{subtext}</div>
    </div>
  )
}
