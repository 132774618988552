import React from "react"
import { Link, navigate } from "gatsby"
import {
  Centered1200Wrapper,
  TextWithSubtext,
  Button,
  PageTitle,
} from "../../components"

import QuoteSummary from "../../quote/containers/quoteCreation/QuoteSummary"

export default function SummaryContainer({
  quote,
  onContinue,
  queueJump,
  matchingQuotingToolVersion,
  isHomeownerOrRenter,
}) {
  return (
    <div style={{ minHeight: "calc(100vh - 384px)" }}>
      <Centered1200Wrapper>
        <div>
          <div style={{ marginBottom: "20px" }}>
            <PageTitle
              style={{ textAlign: "left" }}
            >
              <div className="quotingTool-pageTitle">Package confirmation</div>
            </PageTitle>
            <div
              style={{
                fontWeight: "400",
                color: "#000",
                opacity: "0.54",
                marginBottom: 20,
              }}
              className="quotingTool-pageDescription"
            >
              The prices might have changed from the original quote for a number
              of reasons. You will be charged the prices shown below for the
              duration of your contract.
            </div>
          </div>
          <div>
            <div
              style={{
                overflow: "hidden",
                margin: "20px 0",
              }}
            >
              <QuoteSummary
                quote={quote}
                matchingQuotingToolVersion={matchingQuotingToolVersion}
                isSummaryPage
                isHomeownerOrRenter={isHomeownerOrRenter}
              />
            </div>
          </div>
          <div style={{ marginBottom: 20, padding: "0 20px" }}>
            <Button
              onClick={() => onContinue()}
              style={{ width: "100%", marginBottom: 20 }}
              className="quotingTool-submitButton"
            >
              Continue to payment
            </Button>
            <Link
              to={`${window.location.origin}/tenants/app/${matchingQuotingToolVersion.versionId}/signup/${quote.id}/#Quote-Step-0${matchingQuotingToolVersion.quotingToolPages.length}`}
            >
              <Button white style={{ marginBottom: 20, width: "100%" }}>
                Edit package
              </Button>
            </Link>
          </div>
        </div>
      </Centered1200Wrapper>
    </div>
  )
}
