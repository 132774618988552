import React, { useContext, useEffect, useCallback } from "react"
import { navigate } from "gatsby-link"
import { Link } from "gatsby"

import { loginWithPaymentDetailsToken as apiLoginWithPaymentDetailsToken } from "../../services/tenantsApiV2"

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../../context/GlobalContextProvider"

import LoadingIcon from "../../components/loadingIcon/LoadingIcon"
import PageWrapper from "../../components/PageWrapper"
import AuthenticationWrapper from "../../components/authenticationWrapper/AuthenticationWrapper"
import Form from "../../components/Form"

import * as authPageStyles from "../authPage.module.css"

const UpdateBankDetailsRequest = ({ location, updatePaymentDetailsToken }) => {
  const dispatch = useContext(GlobalDispatchContext)
  const context = useContext(GlobalStateContext)

  const [loginAndRedirectError, setLoginAndRedirectError] = React.useState("")

  const loginAndRedirect = useCallback(async () => {
    if (context.portalLoaded) {
      const data = await apiLoginWithPaymentDetailsToken({
        token: updatePaymentDetailsToken,
      })

      if (data.success) {
        dispatch({
          type: "loggedInToTenantsPortalForUpdatingPaymentDetails",
          tokens: {
            accessToken: data.value.accessToken,
            refreshToken: data.value.refreshToken,
          },
        })
        navigate(
          `/tenants/app/update-payment-details-request/${data.value.tenancyId}/bank-details`
        )
      } else if (data.errors) {
        setLoginAndRedirectError(
          <div>
            Looks like something has gone wrong! Please login to your portal with the link below.
          </div>
        )
      } else {
        setLoginAndRedirectError(
          <div>
            Looks like something has gone wrong! Please login to your portal with the link below.
          </div>
        )
      }
    }
  }, [context.portalLoaded, updatePaymentDetailsToken, dispatch])

  useEffect(() => {
    loginAndRedirect()
  }, [loginAndRedirect])

  if (!context.portalLoaded) {
    return (
      <div className="loadingWrapper">
        <LoadingIcon />
      </div>
    )
  }

  return (
    <PageWrapper location={location} hideHeader={true} hideHeaderBorder={true}>
      <div className={authPageStyles.authPage}>
        <AuthenticationWrapper
          title="Update bank details request"
          description="You will be redirected shortly"
        >
          <>
            <Form
              options={{ fields: [] }}
              apiErrorMessage={loginAndRedirectError}
              showMessageOverride={true}
            />
            <div>
              <div>Link not working? Login to your portal <Link style={{ textDecorationLine: 'underline' }} to="/tenants/app/tenancies">here</Link></div>
            </div>
          </>
        </AuthenticationWrapper>
      </div>
    </PageWrapper>
  )
}

export default UpdateBankDetailsRequest
