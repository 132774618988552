import React, { useCallback } from "react"
import CheckIcon from "@material-ui/icons/Check"
import ClearIcon from "@material-ui/icons/Clear"

import moveClasses from "./move.module.css"
import AppTitle from "../../components/appTitle/AppTitle"
import PageWrapper from "../../components/PageWrapper"
import {
  getMyTenancy as apiGetMyTenancy,
  getTenancyTenants as apiGetTenancyTenants,
  getTenancyContractServices as apiGetTenancyContractServices,
  getTenancyMeters as apiGetTenancyMeters,
  getTenancyMeterReadings as apiGetTenancyMeterReadings,
} from "../../services/tenantsApiV2"
import { GlobalStateContext } from "../../context/GlobalContextProvider"
import OptimizedTable from "../../components/optimizedTable/OptimizedTable"
import VerifyAccountToContinue from "../../components/verifyAccountToContinue/VerifyAccountToContinue"
import Frame from "../../components/frame/Frame"

export default ({ location, tenancyId }) => {
  const context = React.useContext(GlobalStateContext)

  const [gettingTenancies, setGettingTenancies] = React.useState(true)
  const [tenancy, storeTenancy] = React.useState(null)
  const [gettingTenancyDetails, setGettingTenancyDetails] = React.useState(
    false
  )
  const [tenants, storeTenants] = React.useState([])
  const [meters, storeMeters] = React.useState([])
  const [contractServices, storeContractServices] = React.useState([])
  const [meterReadings, storeMeterReadings] = React.useState([])
  const [getMyTenancyError, setGetMyTenancyError] = React.useState(null)

  const getTenancyDetails = useCallback(async () => {
    try {
      setGettingTenancyDetails(true)
      const data = await Promise.all([
        apiGetTenancyContractServices(tenancyId),
        apiGetTenancyMeterReadings(tenancyId),
        apiGetTenancyTenants(tenancyId),
        apiGetTenancyMeters(tenancyId),
      ])

      if (data[0] && data[0].value) {
        storeContractServices(data[0].value)
      }

      if (data[1] && data[1].meterReadings) {
        storeMeterReadings(data[1].meterReadings)
      }

      if (data[2] && data[2].tenants) {
        storeTenants(data[2].tenants)
      }

      if (data[3] && data[3].meters) {
        storeMeters(data[3].meters)
      }
    } catch (e) {
      console.log(e)
    }
    setGettingTenancyDetails(false)
  }, [tenancyId])

  const getMyTenancy = useCallback(async () => {
    try {
      setGettingTenancies(true)
      const data = await apiGetMyTenancy(tenancyId)
      if (data.value.tenancy) {
        storeTenancy(data.value.tenancy)
        getTenancyDetails(data.value.tenancy.zohoEntityId)
      }
    } catch (e) {
      console.log(e)
      setGetMyTenancyError(e.message)
    }
    setGettingTenancies(false)
  }, [tenancyId, getTenancyDetails])

  React.useEffect(() => {
    getMyTenancy()
  }, [context.loggedIn, getMyTenancy])

  const getServiceDetails = cs => {
    const csStatus = cs.status.replace(/^\s*(?:[\dA-Z]+\.|[a-z]\)|•)\s+/g, "")
    switch (csStatus) {
      case "Requested":
        return tenancy.startDate && tenancy.startDate !== "0001-01-01T00:00:00"
          ? `Requested on ${new Date(tenancy.startDate).toLocaleDateString("en-GB")}`
          : "Requested"
      case "Ordered":
        return cs.supplyOrderedDate &&
          cs.supplyOrderedDate !== "0001-01-01T00:00:00"
          ? `Ordered on ${new Date(cs.supplyOrderedDate).toLocaleDateString("en-GB")}`
          : "Ordered"
      case "Order Acknowledged":
        if (
          cs.name === "Broadband" &&
          cs.installDate &&
          cs.installDate !== "0001-01-01T00:00:00"
        ) {
          return `Active from ${new Date(cs.installDate).toLocaleDateString("en-GB")} ${cs.installTime
            }`
        } else {
          return `Active from ${new Date(tenancy.startDate).toLocaleDateString("en-GB")}`
        }
      // case "Retrying":
      //   return `Retrying on ${moment().format("DD/MM/YYYY")}`
      case "Live":
        return cs.supplyCompletedDate &&
          cs.supplyCompletedDate !== "0001-01-01T00:00:00"
          ? `Live since ${new Date(cs.supplyCompletedDate).toLocaleDateString("en-GB")}`
          : "Live"
      case "Closing Down":
        return cs.dateTerminated && cs.dateTerminated !== "0001-01-01T00:00:00"
          ? `Terminated on ${new Date(cs.dateTerminated).toLocaleDateString("en-GB")}`
          : "Terminated"
      case "Reconciled":
        return cs.dateReconciled && cs.dateReconciled !== "0001-01-01T00:00:00"
          ? `Reconciled on ${new Date(cs.dateReconciled).toLocaleDateString("en-GB")}`
          : "Reconciled"
      default:
        return csStatus
    }
  }

  const getTickOrCrossField = (bool, text) => {
    return (
      <div
        style={{
          color: bool ? "#4CAF50" : "rgba(0, 0, 0, 0.54)",
          fontWeight: "normal",
        }}
      >
        {bool ? <CheckIcon fontSize="small" /> : <ClearIcon fontSize="small" />}{" "}
        {text}
      </div>
    )
  }

  const displayMoveInOrMoveOut = () => {
    const currentDate = new Date();
    const fieldsToDisplay = [];

    if (tenancy.moveInChecklist) {
      const dateConfirmed = tenancy.moveInDateConfirmed;
      fieldsToDisplay.push({
        title: getTickOrCrossField(dateConfirmed, "Move in date confirmed"),
      });

      const allTenantsSigned =
        tenants.filter(x => x.hasSignedUp).length === tenancy.numberOfTenants;
      fieldsToDisplay.push({
        title: getTickOrCrossField(allTenantsSigned, "All tenants signed"),
      });

      contractServices.forEach(cs => {
        fieldsToDisplay.push({
          title: cs.name,
          details: [getServiceDetails(cs)],
        });
      });

      const meterTypes = meters.map(x => x.type);
      let openingReadingsComplete = true;
      meterTypes.forEach(type => {
        const openingReadings = meterReadings.filter(
          r =>
            (r.type === type || (r.type === "Electric" && type === "Economy 7")) &&
            r.readingPeriod === "Opening Read" &&
            new Date(r.createdAt) > new Date(tenancy.startDate - 7 * 24 * 60 * 60 * 1000)
        );
        if (openingReadings.length === 0) {
          openingReadingsComplete = false;
        }
      });

      fieldsToDisplay.push(
        {
          title: getTickOrCrossField(
            openingReadingsComplete,
            "Opening meter reads submitted"
          ),
        },
        {
          title: getTickOrCrossField(tenancy.houseStatus, "House status"),
          details: [tenancy.houseStatus],
        }
      );

      return (
        <div>
          <AppTitle
            title={"Move in progress"}
            description={
              "A checklist of the steps you need to take when moving into your property."
            }
          />
          <Frame>
            <OptimizedTable
              rows={fieldsToDisplay.map(s => ({
                title: s.title,
                details: s.details,
              }))}
            />
          </Frame>
        </div>
      );
    } else {
      const dateConfirmed = tenancy.moveOutDateConfirmed;
      fieldsToDisplay.push({
        title: getTickOrCrossField(dateConfirmed, "Move out date confirmed"),
      });

      const forwardingAddress = true;
      fieldsToDisplay.push({
        title: getTickOrCrossField(
          forwardingAddress,
          "Forwarding address completed"
        ),
      });

      const meterTypes = meters.map(x => x.type);
      let closingReadingsComplete = true;
      meterTypes.forEach(type => {
        const closingReadings = meterReadings.filter(
          r =>
            (r.type === type || (r.type === "Electric" && type === "Economy 7")) &&
            r.readingPeriod === "Closing Read" &&
            new Date(r.createdAt) > new Date(tenancy.endDate - 4 * 7 * 24 * 60 * 60 * 1000)
        );
        if (closingReadings.length === 0) {
          closingReadingsComplete = false;
        }
      });

      fieldsToDisplay.push({
        title: getTickOrCrossField(
          closingReadingsComplete,
          "Closing meter reads submitted"
        ),
      });

      contractServices.forEach(cs => {
        fieldsToDisplay.push({
          title: cs.name,
          details: [getServiceDetails(cs)],
        });
      });

      fieldsToDisplay.push(
        {
          title: getTickOrCrossField(
            tenancy.hardwareReturned,
            "Hardware returned"
          ),
        },
        {
          title: getTickOrCrossField(tenancy.houseStatus, "House status"),
          details: [tenancy.houseStatus],
        }
      );

      return (
        <div>
          <AppTitle
            title={"Move in progress"}
            description={
              "A checklist of the steps you need to take when moving out of your property."
            }
          />
          <div className={moveClasses.description}>
            A checklist of the steps you need to take when moving out of your
            property.
          </div>
          <Frame>
            <OptimizedTable
              rows={fieldsToDisplay.map(s => ({
                title: s.title,
                details: s.details,
              }))}
            />
          </Frame>
        </div>
      );
    }
  };

  return (
    <PageWrapper
      location={location}
      showSidebar={true}
      showPortalHeader={true}
      hideHeader={true}
      showLoadingIndicator={gettingTenancies || gettingTenancyDetails}
      showLoadingText="Getting your live data"
      tenancy={tenancy}
    >
      <div className={moveClasses.moveStatus}>
        {getMyTenancyError &&
          getMyTenancyError === "Please verify your account to continue" && (
            <VerifyAccountToContinue />
          )}
        {tenancy && <div>{displayMoveInOrMoveOut()}</div>}
      </div>
    </PageWrapper>
  )
}
