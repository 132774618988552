import React, { useContext, useEffect, useCallback } from "react"
import { navigate } from "gatsby"
import qs from "qs"

import { GlobalDispatchContext } from "../../context/GlobalContextProvider"
import { adminLogin as apiAdminLogin } from "../../services/tenantsApiV2"

import PageWrapper from "../../components/PageWrapper"
import AuthenticationWrapper from "../../components/authenticationWrapper/AuthenticationWrapper"
import Form from "../../components/Form"

import * as authPageStyles from "../authPage.module.css"

const AdminLogin = ({ location }) => {
  const dispatch = useContext(GlobalDispatchContext)

  // const [loggingIn, setLoggingIn] = React.useState(false)
  const [loginError, setLoginError] = React.useState("")

  const login = useCallback(async () => {
    const payload = qs.parse(location.hash.replace("#", ""), {
      ignoreQueryPrefix: true,
    })

    setLoginError("")

    try {
      dispatch({
        type: "logoutOfTenantsPortal",
      })

      // setLoggingIn(true)
      const data = await apiAdminLogin({
        accessToken: payload["access_token"],
        contactId: payload["state"],
      })

      if (data.success) {
        dispatch({
          type: "loggedInToTenantsPortal",
          tokens: {
            accessToken: data.value.accessToken,
          },
          adminMode: true,
        })

        navigate("/tenants/app/tenancies")
      } else if (data.errors) {
        setLoginError(data.errors[0].reason)
      } else {
        setLoginError("An error occurred trying to login")
      }
      // setLoggingIn(false)
    } catch (e) {
      // setLoggingIn(false)
      setLoginError(e.message)
    }
  }, [dispatch, location.hash])

  useEffect(() => {
    login()
  }, [login])

  return (
    <PageWrapper location={location} hideHeader={true} hideHeaderBorder={true}>
      <div className={authPageStyles.authPage}>
        <AuthenticationWrapper
          title="Admin login"
          description="You will be redirected shortly"
        >
          <Form
            options={{ fields: [] }}
            apiErrorMessage={loginError}
            showMessageOverride={true}
          />
        </AuthenticationWrapper>
      </div>
    </PageWrapper>
  )
}

export default AdminLogin
