import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import TenantButton from "../components/TenantButton"
import { navigate } from "gatsby"

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "white",
    width: "100%",
    border: "0.5px solid rgba(0,0,0,0.23)",
    borderRadius: "10px",
    padding: "12px 16px",
  },
  section: {
    width: "100%",
  },
  title: {
    fontWeight: "bold",
  },
}))

export default function TenantsSummaryContainer({
  primaryLead,
  otherleads,
  leadPaid,
  isPartnerPrimaryLead,
  frontEndUrl,
  groupId,
  numMissingTenants,
  paid,
}) {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <TenantButton
        lead
        name={primaryLead.firstName + " " + primaryLead.lastName}
        onClick={() =>
          navigate(
            frontEndUrl +
            "/tenants/app/signup/complete/" +
            groupId +
            "/" +
            primaryLead.id
          )
        }
        disabled={leadPaid}
        paid={leadPaid}
      />
      <hr style={{ width: "100%" }} />

      {(leadPaid || isPartnerPrimaryLead) &&
        otherleads &&
        otherleads.map((lead, index) => {
          const hasPaid = Boolean(
            paid.filter(
              p => p.customerId === lead.customerID && p.hasPayed === true
            ).length
          )

          return (
            <>
              <TenantButton
                name={
                  lead.firstName && lead.firstName !== "Housemate"
                    ? lead.firstName + " " + lead.lastName
                    : lead.email
                      ? lead.email
                      : "Housemate"
                }
                onClick={() =>
                  navigate(
                    frontEndUrl +
                    "/tenants/app/signup/complete/" +
                    groupId +
                    "/" +
                    lead.id
                  )
                }
                disabled={hasPaid || (!leadPaid && !isPartnerPrimaryLead)}
                key={index}
                leadPaid={leadPaid}
                paid={hasPaid}
                isPartnerPrimaryLead={isPartnerPrimaryLead}
              />

              <hr style={{ width: "100%" }} />
            </>
          )
        })}
      {leadPaid &&
        numMissingTenants > 0 &&
        Array(numMissingTenants)
          .fill(0)
          .map((m, i, arr) => {
            return (
              <>
                <TenantButton
                  name={"Housemate"}
                  disabled
                  key={i}
                ></TenantButton>
              </>
            )
          })}
    </div>
  )
}
