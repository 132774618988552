import React from "react";
import clsx from "clsx";
import ButtonBase from "@material-ui/core/ButtonBase";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(() => ({
  primary: {
    padding: "20px 24px",
    fontSize: "18px",
    lineHeight: "21px",
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    flexGrow: "1",
    flexDirection: "column"
    //backgroundColor: "#f5f5f5",
  },
  secondary: {
    //backgroundColor: "#243373",
    padding: "20px 24px",
  },
  "@media only screen and (max-width: 600px)": {
    primary: {
      padding: "15px 10px",
      fontSize: "14px",
      lineHeight: "16px",
      fontWeight: "bold",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      flexGrow: "1",
    },
    secondary: {
      color: "white",
      backgroundColor: "#243373",
    },
  },
}));

function Tab(props) {
  const classes = useStyles();
  const { children, secondary, value, index, selectedTab, ...rest } = props;

  return (
    <ButtonBase
      className={clsx(classes.primary, {
        [classes.secondary]: selectedTab,
      })}
      {...rest}
    >
      {children}
    </ButtonBase>
  );
}

export default Tab;
