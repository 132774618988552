import React from "react"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"
import { ProductTooltip } from "./";

const useStyles = makeStyles({
  inputSectionLabel: {
    fontSize: 17,
    marginBottom: 2,
    color: "#000",
    fontWeight: "bold",
  },
  inputSectionDescription: {
    fontSize: 12,
    marginBottom: 5,
  },
  whiteText: {
    color: "#fff !important",
  },
  fieldIndex: {
    background: "#f7c53d",
    height: 30,
    width: 30,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 15,
    marginRight: 5,
    paddingBottom: 3
  },
})

export default function FormLabels({
  fieldIndex,
  label,
  description,
  popupText,
  white,
}) {
  const classes = useStyles()

  if (!label && !description) {
    return <div />
  }

  return (
    <div
      style={{ width: "100%" }}
      className={clsx({
        [classes.whiteText]: white,
      })}
    >
      <div className={classes.inputSectionLabel}>
        {fieldIndex != null && typeof fieldIndex !== undefined && (
          <span className={clsx(classes.fieldIndex, "quotingTool-fieldIndex")}>
            {fieldIndex + 1}
          </span>
        )}{" "}
        {label && <span className="quotingTool-fieldTitle">{label}</span>}{" "}
        {popupText && <ProductTooltip description={popupText} />}
      </div>
      {description && (
        <div className="quotingTool-fieldDescription">
          <div
            className={classes.inputSectionDescription}
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        </div>
      )}
    </div>
  )
}
