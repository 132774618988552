import React from "react"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
  },
})

function StepperWrapper({ children }) {
  const classes = useStyles()

  return <div className={classes.root}>{children}</div>
}

export default StepperWrapper
