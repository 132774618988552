import React from "react"
import {
  FormLine,
  InputSection,
  Button,
  Centered1200Wrapper,
  TextWithSubtext,
  Errors,
  FormLabels,
  SelectionButtons,
} from "../../components"

import {
  validateName,
  validateEmail,
  validateMobilePhone,
} from "../../services/errorValidation"

export default function PersonalFormContainer({
  onContinue,
  personalInformation,
  setPersonalInformation,
  submitting,
  success,
}) {
  const [errors, setErrors] = React.useState(null)
  const year = new Date().getFullYear()
  const [localErrors, setLocalErrors] = React.useState(null)

  const [validationErrors, setValidationErrors] = React.useState({
    firstName: {
      error: false,
      helperText: "",
    },
    lastName: {
      error: false,
      helperText: "",
    },
    email: {
      error: false,
      helperText: "",
    },
    number: {
      error: false,
      helperText: "",
    },
    city: {
      error: false,
      helperText: "",
    },
  })

  const validateFieldsOnSubmit = newErrors => {
    if (!personalInformation.firstName) {
      newErrors.firstName = {
        error: true,
        helperText: "First name is required",
      }
    }

    if (!personalInformation.lastName) {
      newErrors.lastName = { error: true, helperText: "Last name is required" }
    }

    if (
      personalInformation.firstName &&
      !validateName(personalInformation.firstName)
    ) {
      newErrors.firstName = {
        error: true,
        helperText: "Please enter a valid name",
      }
    }

    if (
      personalInformation.lastName &&
      !validateName(personalInformation.lastName)
    ) {
      newErrors.lastName = {
        error: true,
        helperText: "Please enter a valid name",
      }
    }

    if (personalInformation.lastName === "") {
      newErrors.lastName = { error: true, helperText: "Last name is required" }
    }

    if (
      personalInformation.phoneNumber &&
      !validateMobilePhone(personalInformation.phoneNumber)
    ) {
      newErrors.number = {
        error: true,
        helperText: "Please enter a valid phone number",
      }
    }

    if (!personalInformation.phoneNumber) {
      newErrors.number = {
        error: true,
        helperText: "Mobile phone number is required",
      }
    }

    if (!personalInformation.email) {
      newErrors.email = { error: true, helperText: "Email address is required" }
    }

    if (
      personalInformation.email &&
      !validateEmail(personalInformation.email)
    ) {
      newErrors.email = {
        error: true,
        helperText: "Please enter a valid email address",
      }
    }

    if (!personalInformation.city) {
      newErrors.city = {
        error: true,
        helperText: "City is required",
      }
    }

    setValidationErrors(newErrors)
    return newErrors
  }

  return (
    <>
      <Centered1200Wrapper>
        <div style={{ padding: 20 }}>
          <TextWithSubtext
            text={"We can help soon… but not right now!"}
            subtext={
              <div>
                <div style={{ marginBottom: 10 }}>
                  Bunch has to date been focused on resolving the hassle of
                  student bill sharing. As we do such a great job of this, we
                  are working on expansion plans to help non-students, like you,
                  too!
                </div>
                <div style={{ marginBottom: 20 }}>
                  The energy crisis has increased the need for our service
                  across the board, so the great news is you will be able to
                  join Bunch soon. However, we are not quite ready.. We are
                  thrilled to see you checking us out and look forward to
                  welcoming you on board when we’ve perfected the right product
                  for you.
                </div>
              </div>
            }
          />
        </div>
        <div style={{ backgroundColor: "#ffc400", padding: 20 }}>
          <TextWithSubtext
            text={
              <div style={{ color: "#2eb664" }}>
                So, what is our plan?
              </div>
            }
            subtext={
              <div>
                <div style={{ marginBottom: 20, color: "#2eb664" }}>
                  We are working really hard behind the scenes to transform your
                  bills experience like we have with students nationwide. We
                  expect to be ready to offer you a service by the end of July.
                </div>
              </div>
            }
          />
        </div>
        <div style={{ padding: 20 }}>
          <TextWithSubtext
            text={"What do we do?"}
            subtext={
              <div>
                <div style={{ marginBottom: 10 }}>
                  Gone are the days of one person paying for all of the bills in
                  a property and then arguing with housemates to receive the
                  payments. You can also forget calling up multiple companies
                  for your Energy, Water, Broadband and TV Licence. We
                  centralise all of this for you and, if necessary, divide up
                  these payments equally between you and the bill payers in the
                  property. It just makes life easier.
                </div>
                <div style={{ marginBottom: 20 }}>
                  Register your interest below. We can’t wait to release you
                  from the shackles of bills hassle as we have for over 15,000
                  students.
                </div>
              </div>
            }
          />
        </div>
        <FormLine>
          <InputSection
            error={validationErrors.firstName.error}
            helperText={validationErrors.firstName.helperText}
            label="First name*"
            value={personalInformation.firstName}
            onChange={e => {
              let vErrors = {
                ...validationErrors,
                firstName: { ...validationErrors.firstName },
              }
              if (e.target.value === "") {
                vErrors.firstName = {
                  error: true,
                  helperText: "First name is required",
                }
              } else if (!validateName(e.target.value)) {
                vErrors.firstName = {
                  error: true,
                  helperText: "Please enter a valid name",
                }
              } else {
                vErrors.firstName = { error: false, helperText: "" }
              }
              setValidationErrors(vErrors)
              setPersonalInformation({ firstName: e.target.value })
            }}
          />
        </FormLine>
        <FormLine>
          <InputSection
            error={validationErrors.lastName.error}
            helperText={validationErrors.lastName.helperText}
            label="Last name*"
            value={personalInformation.lastName}
            onChange={e => {
              let vErrors = {
                ...validationErrors,
                lastName: { ...validationErrors.lastName },
              }
              if (e.target.value === "") {
                vErrors.lastName = {
                  error: true,
                  helperText: "Last name is required",
                }
              } else if (!validateName(e.target.value)) {
                vErrors.lastName = {
                  error: true,
                  helperText: "Please enter a valid name",
                }
              } else {
                vErrors.lastName = { error: false, helperText: "" }
              }
              setValidationErrors(vErrors)
              setPersonalInformation({ lastName: e.target.value })
            }}
          />
        </FormLine>
        <FormLine>
          <InputSection
            error={validationErrors.email.error}
            helperText={validationErrors.email.helperText}
            label="Email*"
            value={personalInformation.email}
            onChange={e => {
              let vErrors = {
                ...validationErrors,
                email: { ...validationErrors.email },
              }
              if (e.target.value === "") {
                vErrors.email = {
                  error: true,
                  helperText: "Email address is required",
                }
              } else if (!validateEmail(e.target.value)) {
                vErrors.email = {
                  error: true,
                  helperText: "Please enter a valid email",
                }
              } else {
                vErrors.email = { error: false, helperText: "" }
              }
              setValidationErrors(vErrors)
              setPersonalInformation({ email: e.target.value })
            }}
          />
        </FormLine>
        <FormLine>
          <InputSection
            error={validationErrors.number.error}
            helperText={validationErrors.number.helperText}
            label="Mobile phone number*"
            value={personalInformation.phoneNumber}
            // type="phone"
            onChange={e => {
              let val = e.target.value
              let vErrors = {
                ...validationErrors,
                number: { ...validationErrors.number },
              }
              if (val === "") {
                vErrors.number = {
                  error: true,
                  helperText: "Mobile phone number is required",
                }
              } else if (!validateMobilePhone(val)) {
                vErrors.number = {
                  error: true,
                  helperText: "Please enter a valid mobile phone number",
                }
              } else {
                vErrors.number = { error: false, helperText: "" }
              }
              setValidationErrors(vErrors)
              setPersonalInformation({ phoneNumber: val })
            }}
          />
        </FormLine>
        <FormLine>
          <InputSection
            error={validationErrors.city.error}
            helperText={validationErrors.city.helperText}
            label="City*"
            value={personalInformation.city}
            // type="phone"
            onChange={e => {
              let vErrors = {
                ...validationErrors,
                city: { ...validationErrors.city },
              }
              if (e.target.value === "") {
                vErrors.city = {
                  error: true,
                  helperText: "City is required",
                }
              } else if (!validateName(e.target.value)) {
                vErrors.city = {
                  error: true,
                  helperText: "Please enter a valid city",
                }
              } else {
                vErrors.city = { error: false, helperText: "" }
              }
              setValidationErrors(vErrors)
              setPersonalInformation({ city: e.target.value })
            }}
          />
        </FormLine>
        <FormLine>
          <FormLabels label={"Are you:"} />
          <SelectionButtons
            selector={personalInformation.householdType}
            btn1Text={"In a house share"}
            btn2Text={"The only bill payer"}
            onClick1={() =>
              setPersonalInformation({ householdType: "In a house share" })
            }
            onClick2={() => {
              setPersonalInformation({ householdType: "The only bill payer" })
            }}
          />
        </FormLine>
        <div>
          {!success && (
            <Button
              className="SignUp-Step-02-Details"
              fullWidth
              onClick={() => {
                // validate everything exists!

                const newErrors = validateFieldsOnSubmit(
                  JSON.parse(JSON.stringify(validationErrors))
                )
                setLocalErrors(null)
                let localErrors = []
                let hasError = false
                Object.values(newErrors).forEach(value => {
                  if (value.error) {
                    localErrors.push(value.helperText)
                    hasError = true
                  }
                })

                if (hasError) {
                  setLocalErrors(localErrors)
                  return false
                }
                setErrors(null)
                onContinue()
              }}
            >
              {submitting ? "Saving..." : "Continue"}
            </Button>
          )}
          {success && <div>Thanks, we'll be in touch!</div>}
          <Errors>{localErrors || errors}</Errors>
        </div>
      </Centered1200Wrapper>
    </>
  )
}
