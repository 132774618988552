import React, { useContext, useEffect } from "react"

import { getFirstPromoterInformation as apiGetFirstPromoterInformation } from "../../services/tenantsApi"

import { GlobalStateContext } from "../../context/GlobalContextProvider"

import PageWrapper from "../../components/PageWrapper"
import AppTitle from "../../components/appTitle/AppTitle"

import * as shareAndEarnStyles from "./shareAndEarn.module.css"

export default ({ location }) => {
  const context = useContext(GlobalStateContext)

  const [
    gettingFirstPromoterInformation,
    setGettingFirstPromoterInformation,
  ] = React.useState()
  const [
    firstPromoterInformation,
    storeFirstPromoterInformation,
  ] = React.useState(null)

  const getFirstPromoterInformation = async () => {
    try {
      setGettingFirstPromoterInformation(true)
      const data = await apiGetFirstPromoterInformation()
      if (data.firstPromoterInformation) {
        storeFirstPromoterInformation(data.firstPromoterInformation)
      }
    } catch (e) {
      console.log(e)
    }
    setGettingFirstPromoterInformation(false)
  }

  useEffect(() => {
    getFirstPromoterInformation()
  }, [context.loggedIn])

  return (
    <PageWrapper
      location={location}
      showSidebar={true}
      showPortalHeader={true}
      hideHeader={true}
      showLoadingIndicator={gettingFirstPromoterInformation}
    >
      <div className={shareAndEarnStyles.shareAndEarn}>
        <div className={shareAndEarnStyles.shareAndEarn}>
          {firstPromoterInformation && <AppTitle title="Share &amp; Earn" />}
          {firstPromoterInformation &&
            firstPromoterInformation.promotions &&
            firstPromoterInformation.promotions.map(promotion => {
              return (
                <div>
                  <div className={shareAndEarnStyles.shareAndEarnDetail}>
                    <div className={shareAndEarnStyles.shareAndEarnDetailLabel}>
                      Referral code
                    </div>
                    <div className={shareAndEarnStyles.shareAndEarnDetailValue}>
                      {firstPromoterInformation.default_ref_id}
                    </div>
                  </div>
                  <div className={shareAndEarnStyles.shareAndEarnStatuses}>
                    <ShareAndEarnDetail
                      label="Clicks"
                      value={promotion.visitors_count}
                    />
                    <ShareAndEarnDetail
                      label="Sign ups"
                      value={promotion.leads_count}
                    />
                    <ShareAndEarnDetail
                      label="Customers"
                      value={promotion.customers_count}
                    />
                  </div>
                </div>
              )
            })}
          <div>
            Need to update your payment details?{" "}
            <a href="http://the-bunch.firstpromoter.com/login">Click here</a>
          </div>
        </div>
      </div>
    </PageWrapper>
  )
}

const ShareAndEarnDetail = ({ label, value }) => {
  return (
    <div className={shareAndEarnStyles.shareAndEarnStatus}>
      <div>{label}</div>
      <div className={shareAndEarnStyles.shareAndEarnStatusCircle}>{value}</div>
    </div>
  )
}
