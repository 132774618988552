import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";

const useStyles = makeStyles(() => ({
  primary: {
    width: "30px",
    height: "30px"
  },
  popper: {
    fontSize: "30px"
  }
}));

export default function Help({ title }) {
  const classes = useStyles();
  return (
    <Tooltip title={<div style={{ fontSize: "20px" }}> {title}</div>}>
      <HelpOutlineOutlinedIcon className={classes.primary} />
    </Tooltip>
  );
}
