import React from "react"
import { makeStyles, withStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#021A3A",
    width: "100%",
    height: "80px",
    display: "flex",
    alignItems: "center",
    bottom: 0,
  },
  // "@media only screen and (max-width: 600px)": {
  //   root: {
  //     width: "100%",
  //     height: "80px",
  //     display: "flex",
  //     alignItems: "center",
  //   },
  // },
}))

export default function BottomBar(props) {
  const classes = useStyles()
  const { children } = props

  return (
    <div
      className={classes.root}
      style={{ position: props.webView ? "relative" : "fixed" }}
    >
      {children}
    </div>
  )
}

const bottomBarSectionStyles = theme => ({
  bottomBarSection: {
    display: "flex",
    flexDirection: "row",
    color: "white",
    padding: "5px 5%",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      padding: "5px 10px",
    },
  },
})

BottomBar.Section = withStyles(bottomBarSectionStyles)(function ({
  children,
  classes,
}) {
  return <div className={classes.bottomBarSection}>{children}</div>
})
