import React, { useEffect } from "react"
import { StaticQuery, graphql, navigate } from "gatsby"
import { DateTime } from "luxon"
import CircularProgress from "@material-ui/core/CircularProgress"
import TrustpilotFooter from "../components/TrustpilotFooter"

import PageWrapper from "../../../components/PageWrapper"

import {
  getNewTenantDetails as apiGetNewTenantDetails,
  updateNewTenantDetails as apiUpdateNewTenantDetails,
  newTenantSigned as apiNewTenantSigned,
  useSetupStripePayment,
} from "../../../services/tenantsApiV2"

import { PersonalFormContainer } from "./containers"
import CardSection from "./containers/CardSection"
import TopBarContainer from "./containers/TopBarContainer"

import {
  AddressLookup,
  Button,
  Errors,
  FormLine,
  SubTitleText,
  Center,
  Centered1200Wrapper,
  TextWithSubtext,
  FormLabels,
} from "../components"
import InputSection from "../components/InputSection"

function AcceptTenantInvitePage({ location, inviteId }) {
  const frontEndUrl = window.location.origin

  const tabs = ["details", "payment"]
  const [tab, setTab] = React.useState("details") // details | payment

  const [newTenantDetails, setNewTenantDetails] = React.useState({})
  const [paymentClientSecret, setPaymentClientSecret] = React.useState({})
  const [paymentDetails, setPaymentDetails] = React.useState({})
  const [chargeNow, setChargeNow] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [errors, setErrors] = React.useState([])

  const getNewTenantDetails = async () => {
    setErrors([])

    try {
      setLoading(true)

      const data = await apiGetNewTenantDetails(inviteId)

      if (data.success) {
        setNewTenantDetails(data.value)
      } else if (data.errors) {
        setErrors([data.errors[0].reason])
      } else {
        setErrors(["An error occurred trying to invite tenant"])
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)

      if (e.message === "This link has already been used") {
        navigate(`/tenants/app/new-tenant/${inviteId}/sign-up-success`)
      }
      setErrors([e.message])
    }
  }

  const updateNewTenantDetails = async payload => {
    setErrors([])

    try {
      setLoading(true)

      const data = await apiUpdateNewTenantDetails(inviteId, {
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
        phone: payload.phoneNumber,
        dateOfBirth: payload.dateOfBirth,
        isVulnerablePerson: payload.isVulnerablePerson,
      })

      if (data.success) {
        setPaymentClientSecret(data.value.stripeClientSecret)
        setChargeNow(data.value.chargeNow)
        setTab("payment")
        setPaymentDetails({
          costPerMonth: data.value.costPerMonth,
          proRataSignUpFeeToday: data.value.proRataSignUpFeeToday,
        })
      } else if (data.errors) {
        setErrors([data.errors[0].reason])
      } else {
        setErrors(["An error occurred trying to invite tenant"])
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
      setErrors([e.message])
    }
  }

  const newTenantSigned = async () => {
    setErrors([])

    try {
      setLoading(true)

      const data = await apiNewTenantSigned(inviteId)

      if (data.success) {
        navigate(`/tenants/app/new-tenant/${inviteId}/sign-up-success`)
      } else if (data.errors) {
        setErrors([data.errors[0].reason])
      } else {
        setErrors(["An error occurred trying to invite tenant"])
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
      setErrors([e.message])
    }
  }

  useEffect(() => {
    getNewTenantDetails()
  }, [inviteId])

  if (loading) {
    return (
      <PageWrapper
        location={location}
        quoteViewMode={true}
        backgroundColor="#fff"
      >
        <Center>
          <CircularProgress />
        </Center>
      </PageWrapper>
    )
  }

  if (tab === "details") {
    return (
      <PageWrapper
        location={location}
        quoteViewMode={true}
        backgroundColor="#fff"
      >
        <TopBarContainer
          frontEndUrl={frontEndUrl}
          currentStep={tabs.indexOf(tab)}
          setCurrentStep={newStepNo => setTab(tabs[newStepNo])}
          goToPage={newStepNo => setTab(tabs[newStepNo])}
          progress={0}
        />
        <PersonalInformationTab
          onContinue={payload => {
            updateNewTenantDetails(payload)
          }}
          newTenantDetails={newTenantDetails}
        />
        <TrustpilotFooter />
      </PageWrapper>
    )
  }
  if (tab === "payment") {
    return (
      <PageWrapper
        location={location}
        quoteViewMode={true}
        backgroundColor="#fff"
      >
        <TopBarContainer
          frontEndUrl={frontEndUrl}
          currentStep={tabs.indexOf(tab)}
          setCurrentStep={newStepNo => setTab(tabs[newStepNo])}
          goToPage={newStepNo => setTab(tabs[newStepNo])}
          progress={1}
        />
        <PaymentTab
          onContinue={() => {
            newTenantSigned()
          }}
          paymentClientSecret={paymentClientSecret}
          chargeNow={chargeNow}
          costPerMonth={paymentDetails && paymentDetails.costPerMonth}
          proRataSignUpFeeToday={
            paymentDetails && paymentDetails.proRataSignUpFeeToday
          }
        />
        <TrustpilotFooter />
      </PageWrapper>
    )
  }
}

function PersonalInformationTab({ newTenantDetails, onContinue }) {
  const [tenancyInformation, setTenancyInformation] = React.useState({})

  const [personalInformation, setPersonalInformation] = React.useState({
    firstName: newTenantDetails.firstName,
    lastName: newTenantDetails.lastName,
    email: newTenantDetails.email,
    phoneNumber: newTenantDetails.phone,
    dateOfBirth:
      newTenantDetails.dateOfBirth &&
      DateTime.fromISO(newTenantDetails.dateOfBirth).toFormat("yyyy-MM-dd"),
    isVulnerablePerson: newTenantDetails.isVulnerablePerson,
  })

  console.log("Personal information", personalInformation)
  return (
    <PersonalFormContainer
      primary={false}
      skipPostPersonalInformation={true}
      personalInformation={personalInformation}
      onContinue={() => onContinue(personalInformation)}
      setPersonalInformation={update =>
        setPersonalInformation(h => ({ ...h, ...update }))
      }
      tenancyInformation={tenancyInformation}
      setTenancyInformation={update =>
        setTenancyInformation(h => ({ ...h, ...update }))
      }
    />
  )
}

function PaymentTab({
  chargeNow,
  paymentClientSecret,
  onContinue,
  costPerMonth,
  proRataSignUpFeeToday,
}) {
  const [houseDetails, setHouseDetails] = React.useState({
    address: "",
  })

  const [validationErrors, setValidationErrors] = React.useState({
    postcode: {
      error: false,
      helperText: "",
    },
    postcodeLookup: {
      error: false,
      helperText: "",
    },
    addressLine1: {
      error: false,
      helperText: "",
    },
    city: {
      error: false,
      helperText: "",
    },
    country: {
      error: false,
      helperText: "",
    },
  })

  const [submitting, setSubmitting] = React.useState(false)
  const [errors, setErrors] = React.useState([""])

  const [paymentName, setPaymentName] = React.useState("")
  const setupStripePayment = useSetupStripePayment()

  const validateFieldsOnSubmit = newErrors => {
    if (houseDetails.address.addressLine1 === "") {
      newErrors.postcodeLookup = {
        error: true,
        helperText: "Please search for an address",
      }
    }

    if (houseDetails.address.addressLine1 === "") {
      newErrors.addressLine1 = {
        error: true,
        helperText: "Address line 1 is required",
      }
    }

    if (houseDetails.address.city === "") {
      newErrors.city = { error: true, helperText: "Town is required" }
    }

    if (houseDetails.address.postCode === "") {
      newErrors.postcode = { error: true, helperText: "Postcode is required" }
    }

    setValidationErrors(newErrors)
    return newErrors
  }

  return (
    <>
      <Centered1200Wrapper>
        <TextWithSubtext
          text={"Payment"}
          subtext={
            chargeNow ? (
              <div>
                <div style={{ marginBottom: 10 }}>
                  Please enter your billing details. You will be charged £
                  {proRataSignUpFeeToday.toFixed(2)} today, which is a portion
                  of this month's payment according to your start date.
                </div>
                <div>
                  Future payments of £{costPerMonth.toFixed(2)} will be due on
                  the 1st of the month, payable via this card.
                </div>
              </div>
            ) : (
              <div>
                <div style={{ marginBottom: 10 }}>
                  Please enter your billing details, you will not be charged, but your card will be stored to be used when your contract begins.
                </div>
                <div>
                  Future payments of £{costPerMonth.toFixed(2)} will be due on
                  the 1st of the month, payable via this card.
                </div>
              </div>
            )
          }
        />

        <FormLine>
          <FormLabels popupText="Any problems, call us on the live chat below" />
          <div style={{ width: "100%" }}>
            <CardSection />
          </div>
        </FormLine>

        <FormLine>
          <InputSection
            label="Cardholder Name"
            placeholder="Cardholder Name"
            type="text"
            value={paymentName}
            onChange={e => setPaymentName(e.target.value)}
          />
        </FormLine>

        <FormLine>
          <FormLabels label="Billing Address*" />
          <AddressLookup
            placeholder="Billing Address"
            value={houseDetails.address}
            onChange={payload => {
              setHouseDetails({ ...houseDetails, address: payload })
            }}
            required={true}
          />
        </FormLine>
        <Errors>{errors && errors.map(e => <>{e}</>)}</Errors>
        <Button
          className="SignUp-Step-05-PaymentDetails"
          fullWidth
          disabled={
            submitting ||
            !houseDetails.address ||
            !houseDetails.address.postCode
          }
          showShine
          onClick={() => {
            if (submitting) {
              return false
            }

            const newErrors = validateFieldsOnSubmit(
              JSON.parse(JSON.stringify(validationErrors))
            )
            let hasError = false
            Object.values(newErrors).forEach(value => {
              if (value.error) {
                hasError = true
              }
            })

            if (hasError) {
              return false
            }

            setSubmitting(true)
            setErrors(null)
            setupStripePayment({
              clientSecret: paymentClientSecret,
              name: paymentName,
              address: houseDetails.address,
            }).then(r2 => {
              if (r2.isSuccess) {
                setSubmitting(false)
                onContinue()
              } else if (r2.error) {
                setSubmitting(false)
                setErrors([`Your payment has failed. ${r2.error}`])
              } else {
                setSubmitting(false)
                setErrors([
                  "Your payment has failed. Please try again, or contact us for assistance.",
                ])
              }
            })
          }}
        >
          {submitting ? "Saving..." : "Pay"}
        </Button>
      </Centered1200Wrapper>
    </>
  )
}

export default AcceptTenantInvitePage
