import React, { useCallback } from "react"
import AppTitle from "../../components/appTitle/AppTitle"
import PageWrapper from "../../components/PageWrapper"
import { GlobalStateContext } from "../../context/GlobalContextProvider"

import * as usageStyles from "./usage.module.css"

import {
  getTenancyUsage as apiGetTenancyUsage
} from "../../services/tenantsApiV2"
import Frame from "../../components/frame/Frame"
import VerifyAccountToContinue from "../../components/verifyAccountToContinue/VerifyAccountToContinue"
import ServicesUsageGraph from "../../components/servicesUsageGraph/ServicesUsageGraph"

export default ({ location, tenancyId }) => {
  const context = React.useContext(GlobalStateContext)

  const [tenancy, storeTenancy] = React.useState(null)
  const [gettingTenancyDetails, setGettingTenancyDetails] = React.useState(true)
  const [usage, storeUsage] = React.useState(null)
  const [getTenancyError, setGetTenancyError] = React.useState(null)

  const getTenancyDetails = useCallback(async () => {
    try {
      setGettingTenancyDetails(true)
      const data = await Promise.all([
        apiGetTenancyUsage(tenancyId)
      ])

      if (data[0] && data[0].value) {
        storeTenancy(data[0].value.tenancy)
        storeUsage(data[0].value.contractServiceUsages)
      }
    } catch (e) {
      console.log(e)
    }
    setGettingTenancyDetails(false)
  }, [tenancyId])

  React.useEffect(() => {
    getTenancyDetails()
  }, [context.loggedIn])

  return (
    <PageWrapper
      location={location}
      showSidebar={true}
      showPortalHeader={true}
      hideHeader={true}
      showLoadingIndicator={gettingTenancyDetails}
      showLoadingLogo={true}
      showLoadingText="Getting your live data"
      tenancy={tenancy}
    >
      {context.tenantUser && (
        <div className={usageStyles.usage}>
          <AppTitle title={"Your account usage!"} />
          {getTenancyError &&
            getTenancyError === "Please verify your account to continue" && (
              <VerifyAccountToContinue />
            )}
          {tenancy && !tenancy.unlimited && <div>
            <Frame title="Usage Overview">
              <div>
                <div>
                  <div style={{ fontWeight: "bold" }}>Usage review</div>
                  <p>
                    Every month we review your usage compared to your
                    allowances, to ensure you are not at risk of going over
                    your allowance.
                  </p>
                  <p>
                    If it looks like you're going to over use your
                    allowance, then we will adjust your monthly card payment
                    amount to avoid your account from building up debt with
                    notice.
                  </p>
                  <p>
                    We do not want any of our customers to be under any
                    financial difficulties during this time and therefore
                    would like to make our customers aware if they're
                    overusing. Please ensure you are checking your customer
                    portal and are sending in monthly readings.
                  </p>
                  <p>
                    If you would like help and advice on how to reduce your
                    energy consumption, then please check out our energy
                    saving tips{" "}
                    <a
                      target="_blank"
                      href="https://www.the-bunch.co.uk/5-ways-to-save-energy-this-winter/"
                    >
                      here
                    </a>
                    .
                  </p>
                </div>
              </div>
              <div className={usageStyles.usagePanels}>
                <ServicesUsageGraph
                  tenancyId={tenancyId}
                  type="Energy"
                  data={usage}
                />
                <ServicesUsageGraph
                  tenancyId={tenancyId}
                  type="Electric"
                  data={usage}
                />
                <ServicesUsageGraph
                  tenancyId={tenancyId}
                  type="Gas"
                  data={usage}
                />
              </div>
              <div className={usageStyles.usagePanels}>
                <ServicesUsageGraph
                  tenancyId={tenancyId}
                  type="Water"
                  data={usage}
                  hideGraph
                />
                <ServicesUsageGraph
                  tenancyId={tenancyId}
                  type="Broadband"
                  data={usage}
                  hideGraph
                />
                <ServicesUsageGraph
                  tenancyId={tenancyId}
                  type="TV Licence"
                  data={usage}
                  hideGraph
                />
              </div>
            </Frame>
          </div>}
        </div>
      )}
    </PageWrapper>
  )
}