import React, { useContext } from "react"
import { navigate } from "gatsby"

import { GlobalStateContext } from "../../../context/GlobalContextProvider"

import LoadingIcon from "../../../components/loadingIcon/LoadingIcon"

const UpdatePaymentDetailsOnlyRoute = ({
  component: Component,
  location,
  ...rest
}) => {
  const context = useContext(GlobalStateContext)

  if (!context.portalLoaded) {
    return (
      <div className="loadingWrapper">
        <LoadingIcon />
      </div>
    )
  }

  if (!context.loggedInToTenantsPortalForUpdatingPaymentDetails) {
    navigate("/tenants/app/login")
    return null
  }

  return <Component {...rest} location={location} />
}
export default UpdatePaymentDetailsOnlyRoute
