import React from "react"
import { makeStyles, withStyles } from "@material-ui/core/styles"
const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: "1",
    overflowY: "auto",
    maxWidth: 1200,
    margin: "0 auto",
    padding: "60px 20px",
  },
  sectionRoot: {
    display: "flex",
    flexDirection: "column",
    padding: "30px 0px 0px 0px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
    },
  },
  "@media only screen and (max-width: 600px)": {
    root: {
      padding: "10px",
      scrollbarWidth: "none",
      msOverflowStyle: "none",
    },
    sectionRoot: {
      display: "flex",
      flexDirection: "column",
      padding: "15px 0px 0px 0px",
    },
  },
}))

export default function Centered1200Wrapper({ children }) {
  const classes = useStyles()
  return (
    <div>
      <div className={classes.root}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ width: "100%", maxWidth: "700px" }}>{children}</div>
        </div>
      </div>
    </div>
  )
}

const mainContentSectionStyles = theme => ({
  mainContentSection: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      padding: "0px",
    },
  },
})

Centered1200Wrapper.Section = withStyles(mainContentSectionStyles)(function ({
  children,
  classes,
}) {
  return <div className={classes.mainContentSection}>{children}</div>
})
