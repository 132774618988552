import React from "react"
import CircularProgress from "@material-ui/core/CircularProgress"
import { makeStyles } from "@material-ui/core/styles"
import { convertToCurrency } from "../../util/finance"

import { b64toBlob } from "../../util/files"

import { getInvoicePdf as apiGetInvoicePdf } from "../../services/tenantsApiV2"

const useStyles = makeStyles({
  invoiceSummary: {
    height: 300,
    border: "1px solid #21544f",
    borderRadius: 10,
    width: 200,
    overflow: "hidden",
  },
  invoiceContent: {
    padding: 20,
    height: "calc(100% - 70px)",
    backgroundColor: "#f7f7f7",
  },
  invoiceDownloadButton: {
    padding: 20,
    height: 70,
    backgroundColor: "#21544f",
    color: "#fff",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& a": {
      color: "#fff !important",
      fontWeight: "normal",
    },
  },
})

export default function InvoiceSummary({ invoice }) {
  const classes = useStyles()

  const [invoicePdfs, storeInvoicePdfs] = React.useState({})
  const [loadingInvoicePdf, setLoadingInvoicePdf] = React.useState(null)

  const getInvoicePdf = async invoiceId => {
    try {
      setLoadingInvoicePdf(invoiceId)
      const data = await Promise.all([apiGetInvoicePdf(invoiceId)])

      if (data[0] && data[0].value) {
        storeInvoicePdfs({
          ...invoicePdfs,
          [invoiceId]: data[0].value,
        })
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoadingInvoicePdf(null)
    }
  }

  const contentType = "application/pdf"
  let pdfDownloadUrl = null

  if (invoicePdfs[invoice.zohoEntityId]) {
    const blob = b64toBlob(invoicePdfs[invoice.zohoEntityId], contentType)
    pdfDownloadUrl = URL.createObjectURL(blob)
  }

  return (
    <div className={classes.invoiceSummary}>
      <div className={classes.invoiceContent}>
        <div>Invoice</div>
        <div>
          {new Date(invoice.invoiceDate).toLocaleDateString("en-US", {
            day: "numeric",
            month: "long",
            year: "numeric",
          })}
        </div>
        <div>£{convertToCurrency(invoice.grandTotal)}</div>
        <div>
          Status:{" "}
          {invoice.hasBeenPaid ? (
            <span style={{ color: "#37C63C" }}>Paid</span>
          ) : (
            <span style={{ color: "#EB545A" }}>Unpaid</span>
          )}
        </div>
      </div>
      <div className={classes.invoiceDownloadButton}>
        {!invoicePdfs[invoice.zohoEntityId] &&
          loadingInvoicePdf !== invoice.zohoEntityId && (
            <a
              onClick={() => getInvoicePdf(invoice.zohoEntityId)}
              style={{ cursor: "pointer" }}
            >
              <div>Generate PDF</div>
            </a>
          )}
        {loadingInvoicePdf === invoice.zohoEntityId && (
          <div>
            <CircularProgress size={20} color="#fff" />
          </div>
        )}
        {invoicePdfs[invoice.zohoEntityId] &&
          loadingInvoicePdf !== invoice.zohoEntityId && (
            <a
              href={pdfDownloadUrl}
              target="_blank"
              download={`bill-${new Date(invoice.invoiceDate).toISOString().slice(0, 10)}.pdf`}
            >
              Download PDF
            </a>
          )}
      </div>
    </div>
  );
}
