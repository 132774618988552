import React, { useEffect } from "react"
import qs from "qs"
import { Link, navigate } from "gatsby"

import PageWrapper from "../../components/PageWrapper"
import AuthenticationWrapper from "../../components/authenticationWrapper/AuthenticationWrapper"
import Form from "../../components/Form"
import Button from "../../components/button/Button"

import { storeTenantPlans as apiStoreTenantPlans, storeLeadTenantPlans as apiStoreLeadTenantPlans } from "../../services/tenantsApiV2"

import * as authPageStyles from "../authPage.module.css"

const tenancyEndDateFormOptions = {
  fields: [
    {
      placeholder: "Tenancy end date",
      clearable: true,
      type: "date",
      required: true,
      name: "tenancyEndDate",
    },
  ],
  submitText: "Submit",
}

const TenantPlans = ({ location, type }) => {
  const [storingTenantPlans, setStoringTenantPlans] = React.useState(false)
  const [storeTenantPlansError, setStoreTenantPlansError] = React.useState("")
  const [successfulResponse, setSuccessfulResponse] = React.useState(false)
  const [endOfTenancyPayload, setEndOfTenancyPayload] = React.useState({})

  const initalQueryStrings = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  })

  const storeTenantPlans = async initialPageLoad => {
    if (!initalQueryStrings.email || !initalQueryStrings.plans) {
      return false
    }

    if (initalQueryStrings.plans !== "Staying in the same house" && initalQueryStrings.plans !== "Leaving university") {
      var date = new Date(endOfTenancyPayload.tenancyEndDate);
      if (isNaN(date.getTime())) {
        setStoreTenantPlansError("Please enter a valid date");
        return false;
      }
    }

    setStoreTenantPlansError("")

    try {
      if (!initialPageLoad) setStoringTenantPlans(true)
      let data = null;

      if (type === "contact") {
        data = await apiStoreTenantPlans({
          ...initalQueryStrings,
          ...endOfTenancyPayload,
        })
      } else if (type === "lead") {
        data = await apiStoreLeadTenantPlans({
          ...initalQueryStrings,
          ...endOfTenancyPayload,
        })
      }

      if (data.success) {
        if (!initialPageLoad) setSuccessfulResponse(true)

        if (initalQueryStrings.plans.indexOf("Looking for bills") !== -1) {
          navigate(`/tenants/app/quote?email=${initalQueryStrings.email}&firstName=${initalQueryStrings.firstName || ""}&lastName=${initalQueryStrings.lastName || ""}`)
        }
      } else if (data.errors) {
        setStoreTenantPlansError(data.errors[0].reason)
      } else {
        setStoreTenantPlansError("An error occurred trying to login")
      }
      if (!initialPageLoad) setStoringTenantPlans(false)
    } catch (e) {
      console.log(e)
      if (!initialPageLoad) setStoringTenantPlans(false)
      setStoreTenantPlansError(e.message)
    }
  }

  useEffect(() => {
    storeTenantPlans(true)
  }, [])

  const renderContent = () => {
    if (
      initalQueryStrings.plans === "Moving to a new house" &&
      successfulResponse
    ) {
      return (
        <AuthenticationWrapper
          title="Thanks for letting us know your intentions for next year!"
          description="Would you like to get a quote for your new house?"
        >
          <Link to="/tenants/app/quote">
            <Button title="Get a quote"></Button>
          </Link>
        </AuthenticationWrapper>
      )
    }

    if (
      initalQueryStrings.plans === "Staying in the same house" || initalQueryStrings.plans === "Leaving university" || initalQueryStrings.plans.indexOf("Looking for bills") !== -1 ||
      successfulResponse
    ) {
      return (
        <AuthenticationWrapper title="Thanks for letting us know your intentions for next year!"></AuthenticationWrapper>
      )
    }

    return (
      <AuthenticationWrapper title="What date does your tenancy end on?">
        <Form
          options={tenancyEndDateFormOptions}
          onChange={payload =>
            setEndOfTenancyPayload({ ...endOfTenancyPayload, ...payload })
          }
          onSubmit={() => storeTenantPlans()}
          submitting={storingTenantPlans}
          apiErrorMessage={storeTenantPlansError}
        />
      </AuthenticationWrapper>
    )
  }

  return (
    <PageWrapper location={location} hideHeader={true} hideHeaderBorder={true}>
      <div className={authPageStyles.authPage}>{renderContent()}</div>
    </PageWrapper>
  )
}

export default TenantPlans
