import React from "react"
import clsx from "clsx"
import ButtonBase from "@material-ui/core/ButtonBase"
import { makeStyles } from "@material-ui/core/styles"
import DoneIcon from "@material-ui/icons/Done"

function TenantButton(props) {
  const useStyles = makeStyles(() => ({
    primary: {
      display: "flex",
      justifyContent: "space-between",
      fontFamily: "mundial, sans-serif",
      width: "100%",
      color: "#243373",
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: "bold",
      textAlign: "left",
    },
    button: {
      backgroundColor: "#F8C929",
      fontWeight: "bold",
      fontSize: "15px",
      lineHeight: "26px",
      display: "flex",
      textAlign: "center",
      justifyContent: "center",
      alignItems: "center",
      padding: "8px 10%",
      borderRadius: "4px",
      cursor: "pointer",
      width: "184px",
      position: "relative",
    },
  }))
  const classes = useStyles()
  const {
    children,
    onClick,
    name,
    paid,
    leadPaid,
    lead,
    buttonTitle,
    isPartnerPrimaryLead,
    ...rest
  } = props
  return (
    <>
      <ButtonBase onClick={onClick} className={classes.primary} {...rest}>
        <div style={{ color: "black" }}>{name}</div>
        {!lead ? (
          !leadPaid && !isPartnerPrimaryLead ? (
            <div style={{ fontWeight: "bold", color: "black" }}>-</div>
          ) : paid ? (
            <div
              style={
                !leadPaid
                  ? { padding: " 0px 10px", color: "#cdcdcd" }
                  : { padding: " 0px 10px", color: "#ea5655" }
              }
            >
              <DoneIcon
                style={{
                  backgroundColor: "#4CAF50",
                  color: "white",
                  borderRadius: "50%",
                  height: "20px",
                  width: "20px",
                  padding: "3px",
                }}
              />
            </div>
          ) : (
            <div className={clsx(classes.button, "quotingTool-submitButton")}>
              Sign Up
            </div>
          )
        ) : (
          <>
            {paid ? (
              <div style={{ padding: " 0px 10px" }}>
                <DoneIcon
                  style={{
                    backgroundColor: "#4CAF50",
                    color: "white",
                    borderRadius: "50%",
                    height: "20px",
                    width: "20px",
                    padding: "3px",
                  }}
                />
              </div>
            ) : (
              <div className={clsx(classes.button, "quotingTool-submitButton")}>
                <div className="shine-box" />
                {buttonTitle || "Sign Up"}
              </div>
            )}
          </>
        )}
      </ButtonBase>
      <div style={{ padding: "0px 10px" }}>
        <div style={{ width: "100%", borderBottom: "1px solid #dcdcdc" }}></div>
      </div>
    </>
  )
}

export default TenantButton
