import React, { useContext } from "react"

import {
  getMe as apiGetMe,
  favouriteProperty as apiFavouriteProperty,
  unfavouriteProperty as apiUnfavouriteProperty,
} from "../../services/tenantsApi"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../../context/GlobalContextProvider"

import PageWrapper from "../../components/PageWrapper"
import PropertyResult from "../../components/propertyListings/propertyResult/PropertyResult"
import AppTitle from "../../components/appTitle/AppTitle"

import * as favouritesStyles from "./favourites.module.css"

export default ({ location }) => {
  const dispatch = useContext(GlobalDispatchContext)
  const context = useContext(GlobalStateContext)

  const [
    togglingFavouriteProperty,
    setTogglingFavouriteProperty,
  ] = React.useState(false)

  const toggleFavouriteProperty = async propertyId => {
    if (context.loggedInToTenantsPortal && context.tenantUser) {
      setTogglingFavouriteProperty(true)

      let existingFavourite = null
      if (
        context.tenantUser.favourites &&
        context.tenantUser.favourites.length > 0
      ) {
        existingFavourite = context.tenantUser.favourites.find(
          _ => _.propertyId === propertyId
        )
      }

      console.log(existingFavourite)

      if (existingFavourite) {
        try {
          await apiUnfavouriteProperty(propertyId)
        } catch (e) {
          console.log(e)
        }
      } else {
        try {
          await apiFavouriteProperty(propertyId)
        } catch (e) {
          console.log(e)
          setTogglingFavouriteProperty(false)
        }
      }

      try {
        const data = await apiGetMe()
        if (data.user) {
          dispatch({
            type: "storeTenantUser",
            tenantUser: data.user,
          })
        }
        setTogglingFavouriteProperty(false)
      } catch (e) {
        console.log(e)
        setTogglingFavouriteProperty(false)
      }
    }
  }

  return (
    <PageWrapper
      location={location}
      showSidebar={true}
      showPortalHeader={true}
      hideHeader={true}
    >
      <div className={favouritesStyles.favourites}>
        <AppTitle title="Favourited Properties" />
        <div className={favouritesStyles.favouritesSummary}>
          <div className={favouritesStyles.favouritesummaryText}>
            {context.tenantUser && (
              <div className={favouritesStyles.propertiesFavourited}>
                {context.tenantUser.favourites
                  ? context.tenantUser.favourites.length
                  : 0}{" "}
                properties favourited
              </div>
            )}
          </div>
        </div>
        <div className={favouritesStyles.favouritesList}>
          {context.tenantUser &&
            context.tenantUser.favourites &&
            context.tenantUser.favourites.map(favouritedProperty => {
              return (
                <PropertyResult
                  view="list"
                  property={favouritedProperty.property}
                  toggleFavouriteProperty={() =>
                    !togglingFavouriteProperty &&
                    toggleFavouriteProperty(favouritedProperty.propertyId)
                  }
                  favouritedProperty={
                    context.tenantUser &&
                    context.tenantUser.favourites &&
                    context.tenantUser.favourites.find(
                      _ => _.propertyId === favouritedProperty.propertyId
                    )
                  }
                />
              )
            })}
        </div>
      </div>
    </PageWrapper>
  )
}
