import React, { useCallback } from "react"
import { makeStyles } from "@material-ui/core/styles"
import PageWrapper from "../../components/PageWrapper"
import {
  getMyTenancy as apiGetMyTenancy,
  getInvoices as apiGetInvoices,
} from "../../services/tenantsApiV2"
import { GlobalStateContext } from "../../context/GlobalContextProvider"
import AppTitle from "../../components/appTitle/AppTitle"
import VerifyAccountToContinue from "../../components/verifyAccountToContinue/VerifyAccountToContinue"
import * as billsStyles from "./bills.module.css"

const useStyles = makeStyles({
  tooltip: {
    fontSize: 17,
  },
})

export default ({ location, tenancyId }) => {
  const context = React.useContext(GlobalStateContext)

  const [gettingTenancies, setGettingTenancies] = React.useState(true)
  const [tenancy, storeTenancy] = React.useState(null)
  const [gettingTenancyDetails, setGettingTenancyDetails] = React.useState(
    false
  )
  const [invoices, storeInvoices] = React.useState([])
  const [getMyTenancyError, setGetMyTenancyError] = React.useState(null)

  const classes = useStyles()

  const getInvoices = useCallback(async () => {
    try {
      setGettingTenancyDetails(true)
      const data = await Promise.all([apiGetInvoices(tenancyId)])

      if (data[0] && data[0].value) {
        storeInvoices(data[0].value)
      }
    } catch (e) {
      console.log(e)
    }
    setGettingTenancyDetails(false)
  }, [tenancyId])

  const getMyTenancy = useCallback(async () => {
    try {
      setGettingTenancies(true)
      const data = await apiGetMyTenancy(tenancyId)
      if (data.value.tenancy) {
        storeTenancy(data.value.tenancy)
        getInvoices()
      }
    } catch (e) {
      setGetMyTenancyError(e.message)
    }
    setGettingTenancies(false)
  }, [tenancyId, getInvoices])

  React.useEffect(() => {
    getMyTenancy()
  }, [context.loggedIn, getMyTenancy])

  const renderInvoices = () => {
    if (invoices) {
      return (
        <div>
          {invoices &&
            invoices
              .sort((a, b) => {
                return new Date(b.invoiceDate) > new Date(a.invoiceDate)
                  ? 1
                  : -1
              })
              .map(invoice => {
                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      height: 50,
                    }}
                  >
                    <div style={{ marginRight: 10, width: 100 }}>
                      {new Date(invoice.invoiceDate).toLocaleDateString("en-GB")}
                    </div>
                    <div></div>
                  </div>
                )
              })}
        </div>
      )
    }
  }

  return (
    <PageWrapper
      location={location}
      showSidebar={true}
      showPortalHeader={true}
      hideHeader={true}
      showLoadingIndicator={gettingTenancies || gettingTenancyDetails}
      showLoadingLogo={true}
      showLoadingText="Getting your live data"
      tenancy={tenancy}
    >
      <div className={billsStyles.bills}>
        <AppTitle title="Invoices" />
        {getMyTenancyError &&
          getMyTenancyError === "Please verify your account to continue" && (
            <VerifyAccountToContinue />
          )}
        {invoices && renderInvoices()}
      </div>
    </PageWrapper>
  )
}
