import React, { useEffect } from "react"
import qs from "qs"

import PageWrapper from "../../components/PageWrapper"
import AuthenticationWrapper from "../../components/authenticationWrapper/AuthenticationWrapper"
import Form from "../../components/Form"

import { storeCallbackRequest as apiStoreCallbackRequest } from "../../services/tenantsApiV2"

import * as authPageStyles from "../authPage.module.css"

const CallbackRequest = ({ location }) => {
  const [storingCallbackRequest, setStoringCallbackRequest] = React.useState(
    false
  )
  const [
    storeCallbackRequestError,
    setStoreCallbackRequestError,
  ] = React.useState("")
  const [successfulResponse, setSuccessfulResponse] = React.useState(false)

  const initalQueryStrings = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  })

  const storeCallbackRequest = async initialPageLoad => {
    setStoreCallbackRequestError("")

    try {
      if (!initialPageLoad) setStoringCallbackRequest(true)
      const data = await apiStoreCallbackRequest({
        ...initalQueryStrings,
      })

      if (data.success) {
        if (!initialPageLoad) setSuccessfulResponse(true)
      } else if (data.errors) {
        setStoreCallbackRequestError(data.errors[0].reason)
      } else {
        setStoreCallbackRequestError("An error occurred trying to login")
      }
      if (!initialPageLoad) setStoringCallbackRequest(false)
    } catch (e) {
      console.log(e)
      if (!initialPageLoad) setStoringCallbackRequest(false)
      setStoreCallbackRequestError(e.message)
    }
  }

  useEffect(() => {
    storeCallbackRequest(true)
  }, [])

  const renderContent = () => {
    return (
      <AuthenticationWrapper title="Thank you for letting us know!">
        <Form options={{ fields: [] }} />
      </AuthenticationWrapper>
    )
  }

  return (
    <PageWrapper location={location}>
      <div className={authPageStyles.authPage}>{renderContent()}</div>
    </PageWrapper>
  )
}

export default CallbackRequest
