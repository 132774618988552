import React, { useEffect } from "react"

import QuoteFormContainer from "./containers/QuoteFormContainer"

import { getMatchingQuotingToolVersion } from "../services/utils"

export default function StartQuotePage({
  currentQueryStrings,
  toolId,
  siteData,
}) {
  const [tenancyDetails, setTenancyDetails] = React.useState({})
  const [errors, setErrors] = React.useState([""])
  const [loading, setLoading] = React.useState(false)

  let matchingQuotingToolVersion = getMatchingQuotingToolVersion(
    siteData,
    toolId
  )

  // If the url that the user has visited doesn't have a tool id, then we need to pick the default tool layout, which is set in Zoho
  if (
    !toolId &&
    siteData.allSiteSettings &&
    siteData.allSiteSettings.edges[0].node
  ) {
    toolId = siteData.allSiteSettings.edges[0].node.defaultQuoteToolLayout
      .toLowerCase()
      .replace(/_/, "-")
  }

  if (!matchingQuotingToolVersion) {
    return <div />
  }

  const isHomeownerOrRenter =
    matchingQuotingToolVersion.type === "Homeowner" ||
    matchingQuotingToolVersion.type === "Renter" ||
    matchingQuotingToolVersion.type === "Residential"

  const handleContinueFromTenancyDetailsPage = async () => {
    console.log("tenancyDetails", tenancyDetails)
    const url = `${process.env.GATSBY_QUOTING_URL}/tenants/app/${
      toolId ? `${toolId}/` : ""
    }quote?addressLine1=${tenancyDetails.Address.addressLine1}&addressLine2=${
      tenancyDetails.Address.addressLine2
    }&city=${tenancyDetails.Address.city}&postCode=${
      tenancyDetails.Address.postCode
    }&country=${tenancyDetails.Address.country}`
    window.open(url, "_blank").focus()
  }

  let currentQuotingToolPage = {
    pageNumber: 1,
    quotingToolPageFields: [
      {
        name: "Address",
        title: "Address",
        description: "Please enter an address.",
        order: 1,
        type: "Address",
        infoText: "",
        emptyErrorText: "Please enter an address.",
        invalidErrorText: "",
        minimumValue: 0,
        maximumValue: 0,
        defaultValue: 0,
        products: null,
        required: true,
        dropdownOptions: "",
        showServicePrices: false,
        showWeeklyPrice: false,
        showMonthlyPrice: false,
        showYearlyPrice: false,
        quotingToolMultipliers: [],
      },
    ],
  }

  return (
    <>
      {matchingQuotingToolVersion.customCSSURL && (
        <link
          rel="stylesheet"
          type="text/css"
          href={matchingQuotingToolVersion.customCSSURL}
        />
      )}
      <QuoteFormContainer
        quotingToolPage={currentQuotingToolPage}
        setErrors={setErrors}
        loading={loading}
        errors={errors}
        onContinue={() => handleContinueFromTenancyDetailsPage()}
        matchingQuotingToolVersion={matchingQuotingToolVersion}
        tenancyDetails={tenancyDetails}
        setTenancyDetails={payload => setTenancyDetails(payload)}
        backgroundColor={"#f6f8f9"}
        isHomeownerOrRenter={isHomeownerOrRenter}
      />
    </>
  )
}
