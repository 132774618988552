import React, { useState } from "react"
import { navigate } from "gatsby"
import {
  Centered1200Wrapper,
  TextWithSubtext,
  Button,
  Marker,
  PageTitle,
} from "../../components"

import SecondaryButton from "../../../../components/secondaryButton/SecondaryButton"

export default function SignUpInformationContainer({
  onContinue,
  queueJump,
  isHomeownerOrRenter,
}) {
  React.useEffect(() => {
    window.history.replaceState(
      undefined,
      undefined,
      "#SignUp-SignUpInformation"
    )
    document.title = "Bunch - Sign Up Information"
  }, [])

  const steps = [
    {
      title: "Tenancy information",
      description:
        "You'll need to know your letting agency's name and contract start and end dates",
    },
    {
      title: "Personal details",
      description:
        "Including name, email address, mobile phone number, date of birth and intended move-in date",
    },
    {
      title: `Invite ${isHomeownerOrRenter ? "bill payers" : "housemates"
        } (if applicable)`,
      description: `You'll need to know your ${isHomeownerOrRenter ? "bill payers" : "housemates"
        }' details including first and last name, email address and mobile phone number`,
      hideIfQueueJump: true,
    },
    {
      title: "Payment details",
      description: "We'll need your payment details",
    },
  ]

  return (
    <div style={{ minHeight: "calc(100vh - 300px)" }}>
      <Centered1200Wrapper>
        <div>
          <div style={{ marginBottom: "20px" }}>
            <PageTitle
              style={{ textAlign: "left" }}
            >
              <div className="quotingTool-pageTitle">Sign-up information</div>
            </PageTitle>
            <div
              style={{
                fontWeight: "400",
                color: "#000",
                opacity: "0.54",
                marginBottom: 20,
              }}
              className="quotingTool-pageDescription"
            >
              Make sure you have the following information with you before you
              start the signup process.
            </div>
          </div>
          <div>
            {steps
              .filter(step => !queueJump || !step.hideIfQueueJump)
              .map((step, index) => (
                <div
                  key={step.title}
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    marginBottom: 20,
                    color: "#fff",
                  }}
                >
                  <Marker active={true}>{index + 1}</Marker>
                  <div>
                    <div style={{ fontSize: 18, marginBottom: 5 }}>
                      {step.title}
                    </div>
                    <div style={{ fontSize: 15 }}>{step.description}</div>
                  </div>
                </div>
              ))}
          </div>
          <div style={{ marginBottom: 20 }}>
            <Button
              onClick={() => onContinue()}
              style={{ width: "100%", marginBottom: 20 }}
              className="quotingTool-submitButton"
            >
              Continue
            </Button>
          </div>
        </div>
      </Centered1200Wrapper>
    </div>
  )
}
