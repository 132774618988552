import React from "react"
import Paper from "@material-ui/core/Paper"

import SectionHeader from "../sectionHeader/SectionHeader"

import * as frameStyles from "./frame.module.css"

function Frame({ title, description, children }) {
  return (
    <Paper className={frameStyles.frame}>
      {title && <SectionHeader title={title} description={description} />}
      {children}
    </Paper>
  )
}
export default Frame
