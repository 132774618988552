import React from "react"
import { makeStyles } from "@material-ui/core/styles"
const useStyles = makeStyles(() => ({
  root: {
    minHeight: "calc(100vh - 300px)",
  },
}))

export default function Wrapper(props) {
  const classes = useStyles()
  const { children } = props

  return <div className={classes.root}>{children}</div>
}
