import React from "react"
import { Link } from "gatsby"

import { resetPassword as apiResetPassword } from "../../services/tenantsApiV2"

import PageWrapper from "../../components/PageWrapper"
import AuthenticationWrapper from "../../components/authenticationWrapper/AuthenticationWrapper"
import Form from "../../components/Form"

import * as authPageStyles from "../authPage.module.css"

const resetPasswordFormOptions = {
  fields: [
    {
      placeholder: "Password",
      type: "password",
      required: true,
      name: "password",
      helperText: "min 8 characters, 1 upper case, 1 number",
      validate: value => /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(value),
    },
    {
      placeholder: "Repeat password",
      type: "password",
      required: true,
      name: "repeatPassword",
      validate: (value, formPayload) => value === formPayload["password"],
    },
  ],
  submitText: "Save password",
}

const ResetPassword = ({ location, code }) => {
  const [resettingPassword, setResettingPassword] = React.useState(false)
  const [requestPasswordResetMessage, setResetPasswordMessage] = React.useState(
    ""
  )
  const [requestPasswordResetError, setResetPasswordError] = React.useState("")

  const requestPasswordReset = async payload => {
    setResetPasswordError("")

    if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(payload.password)) {
      return setResetPasswordError("Password does not match requirements")
    }

    if (payload.password !== payload.repeatPassword) {
      return setResetPasswordError("Passwords do not match")
    }

    try {
      setResettingPassword(true)
      const data = await apiResetPassword({
        code,
        ...payload,
      })

      if (data.success) {
        setResetPasswordMessage("Your password has been updated")
      } else if (data.errors) {
        setResetPasswordError(data.errors[0].reason)
      } else {
        setResetPasswordError("An error occurred trying to register")
      }
      setResettingPassword(false)
    } catch (e) {
      setResettingPassword(false)
      setResetPasswordError(e.message)
    }
  }

  return (
    <PageWrapper location={location} hideHeader={true} hideHeaderBorder={true}>
      <div className={authPageStyles.authPage}>
        <AuthenticationWrapper title="Tenant portal">
          <Form
            options={resetPasswordFormOptions}
            onSubmit={payload => requestPasswordReset(payload)}
            submitting={resettingPassword}
            apiErrorMessage={requestPasswordResetError}
            apiSuccessMessage={requestPasswordResetMessage}
          />
          <div>
            Already have an account?{" "}
            <Link to="/tenants/app/login">Sign in</Link>
          </div>
        </AuthenticationWrapper>
      </div>
    </PageWrapper>
  )
}

export default ResetPassword
