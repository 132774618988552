import React from "react"

import SectionHeader from "../sectionHeader/SectionHeader"

import * as informationCardsStyles from "./informationCards.module.css"

function InformationCards({ title, rows, style }) {
  const renderRows = () => {
    return rows.map(row => {
      return (
        <div
          key={row.id}
          className={informationCardsStyles.informationCardsRow}
          style={style || {}}
        >
          <div className={informationCardsStyles.informationCardsRowTitle}>
            <div>{row.title}</div>
            <div>{row.value}</div>
          </div>
          {row.details
            .filter(detail => detail)
            .map(detail => {
              return <div>{detail}</div>
            })}
          <div className={informationCardsStyles.informationCardsFooter}>
            <div>{row.footerTitle}</div>
            {row.footerValue && (
              <div
                onClick={() =>
                  row.onFooterValueClick && row.onFooterValueClick()
                }
                onKeyPress={() =>
                  row.onFooterValueClick && row.onFooterValueClick()
                }
                role="button"
                tabIndex="0"
                style={{
                  textDecorationLine: row.onFooterValueClick
                    ? "underline"
                    : "none",
                  cursor: row.onFooterValueClick ? "pointer" : "default",
                }}
              >
                {row.footerValue}
              </div>
            )}
          </div>
        </div>
      )
    })
  }

  return (
    <div>
      {title && <SectionHeader title={title} />}
      <div>{renderRows()}</div>
    </div>
  )
}

export default InformationCards
