import React from "react"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
  primary: { color: "red", marginTop: 10, width: "100%" },
})

export default function Errors({ children, style }) {
  const classes = useStyles()

  if (Array.isArray(children)) {
    return (
      <div className={classes.primary}>
        {children && children[0] && <div>{children[0]}</div>}
      </div>
    )
  }

  return (
    <div className={classes.primary} style={style || {}}>
      <div>{children}</div>
    </div>
  )
}
