import React, { useCallback } from "react"
import { makeStyles } from "@material-ui/core/styles"
import OptimizedTable from "../../components/optimizedTable/OptimizedTable"
import PageWrapper from "../../components/PageWrapper"
import {
  getMyTenancy as apiGetMyTenancy,
  getTenancyBillsAndPayments as apiGetTenancyBillsAndPayments,
} from "../../services/tenantsApiV2"
import { GlobalStateContext } from "../../context/GlobalContextProvider"
import AppTitle from "../../components/appTitle/AppTitle"
import VerifyAccountToContinue from "../../components/verifyAccountToContinue/VerifyAccountToContinue"
import Frame from "../../components/frame/Frame"

import * as billsStyles from "./bills.module.css"
import { b64toBlob } from "../../util/files"

const useStyles = makeStyles({
  tooltip: {
    fontSize: 17,
  },
})

export default ({ location, tenancyId }) => {
  const context = React.useContext(GlobalStateContext)

  const [gettingTenancies, setGettingTenancies] = React.useState(true)
  const [tenancy, storeTenancy] = React.useState(null)
  const [gettingTenancyDetails, setGettingTenancyDetails] = React.useState(
    false
  )
  const [billsAndPayments, storeBillsAndPayments] = React.useState(null)
  const [getMyTenancyError, setGetMyTenancyError] = React.useState(null)

  const classes = useStyles()

  const getTenancyDetails = useCallback(async () => {
    try {
      setGettingTenancyDetails(true)
      const data = await Promise.all([apiGetTenancyBillsAndPayments(tenancyId)])

      if (data[0] && data[0].value) {
        storeBillsAndPayments(data[0].value)
      }
    } catch (e) {
      console.log(e)
    }
    setGettingTenancyDetails(false)
  }, [tenancyId])

  const getMyTenancy = useCallback(async () => {
    try {
      setGettingTenancies(true)
      const data = await apiGetMyTenancy(tenancyId)
      if (data.value) {
        storeTenancy(data.value)
        getTenancyDetails()
      }
    } catch (e) {
      setGetMyTenancyError(e.message)
    }
    setGettingTenancies(false)
  }, [tenancyId, getTenancyDetails])

  React.useEffect(() => {
    getMyTenancy()
  }, [context.loggedIn, getMyTenancy])

  const bills =
    billsAndPayments &&
    billsAndPayments.reduce((acc, bill) => {
      let { type } = bill
      return { ...acc, [type]: [...(acc[type] || []), bill] }
    }, {})

  const renderBills = () => {
    if (billsAndPayments && billsAndPayments.finalStatementUrl) {
      const contentType = "application/pdf"

      const blob = b64toBlob(billsAndPayments.finalStatementUrl, contentType)
      const blobUrl = URL.createObjectURL(blob)

      return (
        <div>
          Your account has been reconcilled and you can download your final bill{" "}
          <a href={blobUrl} target="_blank" download="final-statement.pdf">
            here
          </a>
          .
        </div>
      )
    } else {
      return (
        <div>
          {billsAndPayments.bills && billsAndPayments.bills.length === 0 && (
            <div className={classes.description} style={{ marginBottom: 20 }}>
              The bills we have paid or committed to pay on your behalf will
              start appearing here soon.
            </div>
          )}

          {/* <div style={{ marginTop: 20, fontStyle: 'italic', fontSize: 20 }}>We have noticed some houses are seeing duplicate bills. We are auditing your house bills now to ensure that the bills are correct, and will remove any incorrect ones. We apologise for any inconvenience, any issues will be sorted shortly, so please bear with us while we sort this, and get your accounts up to date</div> */}

          {bills &&
            Object.keys(bills)
              .sort((a, b) => a.localeCompare(b))
              .map(billType => (
                <Frame title={billType}>
                  {bills[billType].map((bill, index) => (
                    <div
                      style={{
                        borderBottom:
                          index === bills.length - 1
                            ? "0px solid #dedede"
                            : "1px solid #dedede",
                        padding: 10,
                      }}
                    >
                      <div>Status: {bill.status}</div>
                      {/* <div>{bill.amount}</div> */}
                      <div>
                        Period {new Date(bill.startDate).toLocaleDateString("en-GB")} to{" "}
                        {new Date(bill.endDate).toLocaleDateString("en-GB")}
                      </div>
                      <div>
                        {bill.breakdown &&
                          bill.breakdown.map(breakdownLineItem => (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                maxWidth: "700px",
                                margin: "5px 0px",
                              }}
                            >
                              <div>{breakdownLineItem[0]}</div>
                              <div>{breakdownLineItem[1]}</div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
                </Frame>
              ))}
        </div>
      )
    }
  }

  return (
    <PageWrapper
      location={location}
      showSidebar={true}
      showPortalHeader={true}
      hideHeader={true}
      showLoadingIndicator={gettingTenancies || gettingTenancyDetails}
      showLoadingLogo={true}
      showLoadingText="Getting your live data"
      tenancy={tenancy}
    >
      <div className={billsStyles.bills}>
        <AppTitle
          title="Bills"
          description="The bills we have paid or committed to pay on your behalf."
        />
        {getMyTenancyError &&
          getMyTenancyError === "Please verify your account to continue" && (
            <VerifyAccountToContinue />
          )}
        {billsAndPayments && renderBills()}
      </div>
    </PageWrapper>
  )
}
