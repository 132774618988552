import React from "react"
import { makeStyles } from "@material-ui/core/styles"
const useStyles = makeStyles(() => ({
  root: {
    width: "80px",
    height: "80px",
    margin: "10px",
    objectFit: "contain",
  },
  "@media only screen and (max-width: 600px)": {
    root: {
      width: "60px",
      height: "60px",
      margin: "10px",
      objectFit: "contain",
    },
  },
}))

export default function Image({ src, alt, shadow }) {
  const classes = useStyles()

  return (
    <img
      className={classes.root}
      src={src}
      alt={alt}
      style={{
        WebkitBoxShadow: shadow && "0px 0px 20px 10px #f00",
        MozBoxShadow: shadow && "0px 0px 20px 10px #f00",
        boxShadow: shadow && "0px 0px 20px 10px #f00",
        borderRadius: shadow && "100%",
      }}
    ></img>
  )
}
