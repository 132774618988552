import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft"

const useStyles = makeStyles(() => ({
  root: {
    fontSize: "24px",
    fontWeight: "bold",
    textAlign: "center",
    color: "#000",
  },
  goBack: {
    color: "#838D9B",
    cursor: "pointer",
    fontSize: 15,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginLeft: -7,
  },
  goBackIcon: {
    fontSize: 25,
    cursor: "pointer",
  },
  "@media only screen and (max-width: 600px)": {
    root: {
      fontSize: "24px",
      fontWeight: "bold",
      marginBottom: "20px",
    },
  },
}))

export default function PageTitle(props) {
  const classes = useStyles()
  const { children, ...rest } = props

  return (
    <div className={classes.root} {...rest}>
      {props.goBack && (
        <div className={classes.goBack} onClick={() => props.goBack()}>
          <ArrowLeftIcon className={classes.goBackIcon} />
          <span style={{ marginLeft: -5 }}>BACK</span>
        </div>
      )}{" "}
      {children}
    </div>
  )
}
