import React from "react"
import { Link } from "gatsby"
import Tooltip from "@material-ui/core/Tooltip"
import InfoIcon from "@material-ui/icons/Info"
import { makeStyles } from "@material-ui/core/styles"

import Button from "../../components/button/Button"

import { convertToCurrency } from "../../util/finance"

import UsageGraph from "../usageGraph/UsageGraph"

import * as servicesUsageGraphStyles from "./servicesUsageGraph.module.css"

const useStyles = makeStyles({
  tooltip: {
    fontSize: 17,
  },
})

export default ({ data, type, hideGraph, tenancyId }) => {
  const classes = useStyles()

  const matchingContractService = data.find(_ => _.type === type)

  if (!matchingContractService || !matchingContractService.allowance) {
    return null
  }

  return (
    <div
      style={{ position: "relative" }}
      className={servicesUsageGraphStyles.servicesUsageGraph}
    >
      <div style={{ width: "100%" }}>
        <div className={servicesUsageGraphStyles.titleSection}>
          <div className={servicesUsageGraphStyles.title}>
            <div style={{ fontWeight: "bold" }}>
              {type}{" "}
              <span style={{ fontWeight: "normal" }}>
                (Status: {matchingContractService.status})
              </span>
            </div>
            {matchingContractService.currentBills > 0 && (
              <div>
                <Tooltip
                  title={
                    "Don't panic if you're overusing. We're checking all the data and will contatct you shortly. Please call Customer Services on 0333 358 3377 if you're still worried."
                  }
                  classes={{ tooltip: classes.tooltip }}
                  role="button"
                  tabIndex="0"
                >
                  <InfoIcon />
                </Tooltip>
              </div>
            )}
          </div>
          <div>Current v Allowance</div>
        </div>
        {!hideGraph && (
          <div
            style={{
              width: "100%",
              backgroundColor: "#f0f0f0",
              position: "relative",
            }}
          >
            <UsageGraph
              data={
                matchingContractService.contractServiceMonthlyUsageBreakdown
              }
            />
            {matchingContractService.currentBills === 0 &&
              (matchingContractService.status === "Live" ||
                matchingContractService.status === "Closing Down") && (
                <div className={servicesUsageGraphStyles.noBillsMessage}>
                  There are no bills currently in our system for this house.
                  We're working with our suppliers to obtain these bills
                </div>
              )}
            {matchingContractService.currentBills === 0 &&
              matchingContractService.status !== "Live" &&
              matchingContractService.status !== "Closing Down" && (
                <div className={servicesUsageGraphStyles.noBillsMessage}>
                  This service is not yet live
                </div>
              )}
          </div>
        )}
        <div className={servicesUsageGraphStyles.detailedSection}>
          <div>
            <b>{type} Usage:</b>
          </div>
          <div className={servicesUsageGraphStyles.detailedSectionLine}>
            <div>Current bills</div>
            <div>
              £{convertToCurrency(matchingContractService.currentBills)}
            </div>
          </div>
          <div className={servicesUsageGraphStyles.detailedSectionLine}>
            <div>Yearly allowance</div>
            <div>£{convertToCurrency(matchingContractService.allowance)}</div>
          </div>
          {/* <div className={servicesUsageGraphStyles.detailedSectionLine}>
            <div>Predicted total bills</div>
            <div>
              £{convertToCurrency(matchingContractService.predictedBills)}
            </div>
          </div> */}

          <div style={{ marginTop: 20 }}>
            <Link to={`/tenants/app/tenancies/${tenancyId}/bills`}>
              <Button title="View all bills" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
