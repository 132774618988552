import React from "react";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "21px"
  },
  "@media only screen and (max-width: 600px)": {
    root: {
      fontWeight: "bold",
      fontSize: "16px",
      lineHeight: "18px"
    }
  }
}));

export default function SubTitleText(props) {
  const classes = useStyles();
  const { children } = props;

  return <div className={classes.root}>{children}</div>;
}
