// extracted by mini-css-extract-plugin
export var allowanceLine = "servicesUsageGraph-module--allowanceLine--u-Urw";
export var bars = "servicesUsageGraph-module--bars--N--Ny";
export var colourKeys = "servicesUsageGraph-module--colourKeys--DZ39H";
export var detailedSection = "servicesUsageGraph-module--detailedSection--UHJ+p";
export var detailedSectionLine = "servicesUsageGraph-module--detailedSectionLine--DJt5+";
export var graph = "servicesUsageGraph-module--graph--tFY6g";
export var graphKey = "servicesUsageGraph-module--graphKey--nDLi0";
export var graphKeySquare = "servicesUsageGraph-module--graphKeySquare--XgqHB";
export var graphKeys = "servicesUsageGraph-module--graphKeys--V+4wN";
export var noBillsMessage = "servicesUsageGraph-module--noBillsMessage--8v2kt";
export var servicesUsageGraph = "servicesUsageGraph-module--servicesUsageGraph--3Yn5l";
export var title = "servicesUsageGraph-module--title--4SNpq";
export var titleSection = "servicesUsageGraph-module--titleSection--nsGmJ";