import React, { useContext, useEffect, useCallback } from "react"
import { Link } from "gatsby"

import {
  loginWithPaymentDetailsToken as apiLoginWithPaymentDetailsToken,
  getBillingPortal as apiGetBillingPortal,
} from "../../services/tenantsApiV2"

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../../context/GlobalContextProvider"

import LoadingIcon from "../../components/loadingIcon/LoadingIcon"
import PageWrapper from "../../components/PageWrapper"
import AuthenticationWrapper from "../../components/authenticationWrapper/AuthenticationWrapper"
import Form from "../../components/Form"

import * as authPageStyles from "../authPage.module.css"

const UpdatePaymentDetailsRequest = ({
  location,
  updatePaymentDetailsToken,
}) => {
  const dispatch = useContext(GlobalDispatchContext)
  const context = useContext(GlobalStateContext)

  const [loginAndRedirectError, setLoginAndRedirectError] = React.useState("")

  const loginAndRedirect = useCallback(async () => {
    if (context.portalLoaded) {
      const data = await apiLoginWithPaymentDetailsToken({
        token: updatePaymentDetailsToken,
      })

      if (data.success) {
        dispatch({
          type: "loggedInToTenantsPortalForUpdatingPaymentDetails",
          tokens: {
            accessToken: data.value.accessToken,
            refreshToken: data.value.refreshToken,
          },
        })
        try {
          const getBillingPortalData = await apiGetBillingPortal(
            data.value.tenancyId,
            data.value.accessToken,
          )
          if (getBillingPortalData.value.url) {
            if (typeof window.location !== "undefined") {
              window.location = getBillingPortalData.value.url
            }
          } else {
            setLoginAndRedirectError(
              <div>
                Looks like something has gone wrong! Please login to your portal with the link below.
              </div>
            )
          }
        } catch (e) {
          setLoginAndRedirectError(
            <div>
              Looks like something has gone wrong! Please login to your portal with the link below.
            </div>
          )
        }
      } else if (data.errors) {
        setLoginAndRedirectError(data.errors[0].reason)
      } else {
        setLoginAndRedirectError(<div>
          Looks like something has gone wrong! Please login to your portal with the link below.
        </div>)
      }
    }
  }, [context.portalLoaded, updatePaymentDetailsToken, dispatch])

  useEffect(() => {
    loginAndRedirect()
  }, [loginAndRedirect])

  if (!context.portalLoaded) {
    return (
      <div className="loadingWrapper">
        <LoadingIcon />
      </div>
    )
  }

  return (
    <PageWrapper location={location} hideHeader={true} hideHeaderBorder={true}>
      <div className={authPageStyles.authPage}>
        <AuthenticationWrapper
          title="Update payment details request"
          description="You will be redirected shortly"
        >
          <>
            <Form
              options={{ fields: [] }}
              apiErrorMessage={loginAndRedirectError}
              showMessageOverride={true}
            />
            <div>
              <div>Link not working? Login to your portal <Link style={{ textDecorationLine: 'underline' }} to="/tenants/app/tenancies">here</Link></div>
            </div>
          </>
        </AuthenticationWrapper>
      </div>
    </PageWrapper>
  )
}

export default UpdatePaymentDetailsRequest
