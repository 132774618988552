import React, { useEffect, useCallback } from "react"

import { verifyAccount as apiVerifyAccount } from "../../services/tenantsApiV2"

import PageWrapper from "../../components/PageWrapper"
import AuthenticationWrapper from "../../components/authenticationWrapper/AuthenticationWrapper"
import Form from "../../components/Form"

import * as authPageStyles from "../authPage.module.css"

export default ({ location, code }) => {
  const [verifyAccountMessage, setVerifyAccountMessage] = React.useState("")
  const [verifyAccountError, setVerifyAccountError] = React.useState("")

  const verifyAccount = useCallback(async () => {
    try {
      const data = await apiVerifyAccount({ code })
      if (data.success) {
        setVerifyAccountMessage("Your account has been verified")
      }
    } catch (e) {
      console.log(e)
      setVerifyAccountError(e.message)
    }
  }, [code])

  console.log(verifyAccountError)

  useEffect(() => {
    verifyAccount()
  }, [verifyAccount])

  return (
    <PageWrapper location={location} hideHeader={true} hideHeaderBorder={true}>
      <div className={authPageStyles.authPage}>
        <AuthenticationWrapper title="Tenant portal">
          <Form
            options={{ fields: [] }}
            showMessageOverride={true}
            apiErrorMessage={verifyAccountError}
            apiSuccessMessage={verifyAccountMessage}
          />
        </AuthenticationWrapper>
      </div>
    </PageWrapper>
  )
}
