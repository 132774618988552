import React from "react"
import Tooltip from "@material-ui/core/Tooltip"
import InfoIcon from "@material-ui/icons/Info"
import { makeStyles } from "@material-ui/core/styles"
import { TiTick } from 'react-icons/ti'

import * as optimizedTableStyles from "./optimizedTable.module.css"

const useStyles = makeStyles({
  optimizedTableContent: {},
  tooltip: {
    fontSize: 17,
  },
  infoIcon: {
    marginLeft: 5,
  },
})

function OptimizedTable({
  title,
  rows,
  footerTitle,
  footerValue,
  description
}) {
  const classes = useStyles()

  const renderRows = () => {
    return rows
      ?.filter(row => !row.hideIfTrue)
      .map((row, index) => {
        if (Array.isArray(row)) {
          return (
            <div
              className={optimizedTableStyles.optimizedTableRow}
              style={{
                borderBottom:
                  index === rows.length - 1
                    ? "0px solid #dedede"
                    : "1px solid #dedede",
              }}
            >
              <div style={{ width: "100%" }}>
                {row.map(subRow => (
                  <div className={optimizedTableStyles.optimizedTableSubrow}>
                    {subRow.map(col => (
                      <div className={optimizedTableStyles.optimizedTableCol}>
                        {col.tick && <TiTick style={{ visibility: col.visibility }} />}
                        <div style={{ color: col.color, marginLeft: '5px' }}>{col.value}</div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          )
        }

        return (
          <div
            key={row.title}
            className={optimizedTableStyles.optimizedTableRow}
            style={{
              borderBottom:
                index === rows.length - 1
                  ? "0px solid #dedede"
                  : "1px solid #dedede",
            }}
          >
            <div className={optimizedTableStyles.optimizedTableRowTitle}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {row.title}
                {row.titleInfo && (
                  <Tooltip
                    title={row.titleInfo}
                    classes={{ tooltip: classes.tooltip }}
                    enterTouchDelay={0}
                    leaveTouchDelay={5000}
                    role="button"
                    tabIndex="0"
                  >
                    <InfoIcon className={classes.infoIcon} />
                  </Tooltip>
                )}
              </div>
              <div>{row.value}</div>
            </div>
            {row?.details?.map((detail, index) => {
              return <div key={`detail-${index}`}>{detail}</div>
            })}
          </div>
        )
      })
  }

  return (
    <div className={optimizedTableStyles.optimizedTable}>
      <div className={classes.optimizedTableContent}>
        {description && (
          <div className={optimizedTableStyles.optimizedTableDescription}>
            {description}
          </div>
        )}
        <div className={optimizedTableStyles.optimizedTableRows}>
          {renderRows()}
        </div>
        {(footerTitle || footerValue) && (
          <div className={optimizedTableStyles.optimizedTableFooter}>
            <div>{footerTitle}</div>
            <div>{footerValue}</div>
          </div>
        )}
      </div>
    </div>
  )
}

export default OptimizedTable
