import React, { useContext } from "react"

import { Button, ProductTooltip, Spacer, FormLabels } from "../../../components"
import {
  calculatePrices,
  toPoundFormat,
  currencyToNumber,
  getServiceAllowance,
  checkIfServiceIsInPackage
} from "../../../services/utils"

import {
  GlobalStateContext,
} from "../../../../../context/GlobalContextProvider"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    color: "#fff",
  },

  buttons: {
    display: "flex",
    flexDirection: "row",
    alignItems: "end",
    justifyContent: "end",
  },
  individualButtons: {
    display: "flex",
    flexDirection: "row",
    alignItems: "end",
    justifyContent: "end",
    fontSize: "14px",
    marginTop: "8px"
  },
  priceModeSelect: {
    color: "grey",
    cursor: "pointer",
    margin: "0px 5px",
    textDecorationLine: 'underline'
  },
  activePriceModeSelect: {
    color: "#fff",
    fontWeight: "bold",
    cursor: "default",
    textDecorationLine: 'none'
  },
  serviceContainer: {},
  services: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "calc(100vh - 150px)",
    overflowY: "auto",
    "@media only screen and (min-width: 768px)": {
      height: "550px",
    },
  },
  servicesWithoutEndSpace: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    overflowY: "auto",
    padding: 20,
    paddingTop: 0,
  },
  service: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  nameAndDescription: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  serviceName: {
    width: "90%",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "24px",
  },
  serviceDescription: {
    fontSize: "16px",
    fontWeight: "24px",
  },
  servicePriceContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    marginBottom: "12px",
  },
  servicePriceItem: {
    marginTop: "14px",
    textAlign: 'right'
  },
  servicePrice: {
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "24px",
  },
  perPerson: {
    fontSize: "12px",
  },
  free: {
    fontWeight: "bold",
    backgroundColor: "#4CAF50",
    color: "white",
    padding: "4px",
    borderRadius: "4px",
    fontSize: "16px",
    lineHeight: "24px",
    alignSelf: "center",
    marginBottom: "12px",
  },
}))

export default function QuoteSummary({
  quote,
  disabled,
  loading,
  isSummaryPage,
  isMobile,
  onContinue,
  continueButtonText,
  toolElementLayout,
  mandatoryFieldsNotSelected,
  setCurrentView,
  matchingQuotingToolVersion,
  isHomeownerOrRenter,
}) {

  const styles = useStyles()
  const context = useContext(GlobalStateContext)
  const tenancyDetails = JSON.parse(quote.tenancyDetails)
  const numberOfBillPayers = parseInt(tenancyDetails.NumberOfBillPayers ?? tenancyDetails.NumberOfTenants);

  let unlimitedPackageSelected = false;
  let unlimitedServiceIds = quote.services.filter(_ => _.unlimitedPackage).map(_ => _.id);

  if (JSON.parse(quote.packageDetails).filter(_ => unlimitedServiceIds.find(x => x === _.serviceId)).length > 0) {
    unlimitedPackageSelected = true;
  }

  const { weeklyTotal, monthlyTotal, yearlyTotal, services } = calculatePrices(
    quote,
    matchingQuotingToolVersion,
    quote.tenancyDetails,
    isHomeownerOrRenter,
    unlimitedPackageSelected
  )

  const quotingToolMultipliers = matchingQuotingToolVersion.quotingToolPages
    .map(quotingToolPage =>
      quotingToolPage.quotingToolPageFields
        .filter(
          quotingToolField => quotingToolField.quotingToolMultipliers.length > 0
        )
        .map(quotingToolField => quotingToolField.quotingToolMultipliers)
    )
    .flat()

  function convertNumberToPrice(number) {
    if (context.individualPriceModeView === "bill-payer")
      return '£' + (number.toLocaleString(undefined, { minimumFractionDigits: 2 }));

    if (context.individualPriceModeView === "household")
      return '£' + (number.toLocaleString(undefined, { minimumFractionDigits: 2 }));
  }

  return (
    <>
      {toolElementLayout && (
        <FormLabels
          label={toolElementLayout.box3HeadingText}
          description={toolElementLayout.box3DescriptionText}
          popupText={toolElementLayout.box3PopupText}
        />
      )}
      <div className={styles.root}>
        <div style={{ "background": "#8effb4", "color": "#21544f", "padding": "15px", "width": "100%", "marginTop": "40px", "textAlign": "center", borderRadius: 20 }}>
          <div style={{ fontWeight: 'bold' }}>Book a call with a move specialist!</div>
          <div>Get set up instantly and hear about all our offers.</div>
          <a href="https://calendly.com/the-bunch/speak-to-the-bunch" target="_blank"><Button style={{ margin: '0 auto', marginTop: 20 }}>Book a call</Button></a>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: 20,
            marginTop: 20,
          }}
        >
          <div style={{ fontFamily: "new-spirit", fontSize: 24 }}>Your breakdown</div>
        </div>

        <div
          className={
            isSummaryPage ? styles.servicesWithoutEndSpace : styles.services
          }
        >
          {services
            .filter(service => service.displayInQuote !== "4. Hidden")
            .sort((x, y) => x.displayOrder - y.displayOrder)
            .map((s, index) => {

              return (
                <div className={styles.serviceContainer}>
                  <div className={styles.service} key={"service" + index}>
                    <div style={{ flexDirection: 'column' }}>
                      <div className={styles.nameAndDescription}>
                        <div className={styles.serviceName}>
                          {s.name} {s.description && <ProductTooltip description={s.description} />}
                        </div>
                        {s.subName !== null ? (
                          <div className={styles.serviceDescription}>
                            {s.subName}
                          </div>
                        ) : null}
                      </div>

                      {s.products &&
                        <div style={{ marginTop: 35 }}>
                          {s.products
                            .filter(product => product.allowance > 0).sort((a, b) => {
                              if (a.category === "Electricity" && b.category === "Gas") {
                                return -1;
                              } else if (a.category === "Gas" && b.category === "Electricity") {
                                return 1;
                              }
                              return 0;
                            })
                            .map(product => {
                              const packageService = checkIfServiceIsInPackage(JSON.parse(quote.packageDetails), s.id)

                              if (!packageService) {
                                return <div />
                              }

                              return (
                                <div style={{ marginTop: 10 }}>
                                  <div>
                                    {product.category} Allowance:{" "}
                                    <b>
                                      {getServiceAllowance(
                                        product,
                                        packageService.allowance,
                                        quotingToolMultipliers,
                                        quote.tenancyDetails
                                      )}
                                      {product.productAllowanceUnits}
                                    </b>
                                  </div>
                                  {product.sellRate1 > 0 && !product.sellRate2 && (
                                    <div>
                                      Unit Rate:{" "}
                                      <b style={{ fontWeight: 'bold' }}>
                                        {(product.sellRate1 * 100).toFixed(2)}p
                                        per kWh
                                      </b>
                                      <br />Standing Charge:{" "}
                                      <b style={{ fontWeight: 'bold' }}>
                                        {(product.sellSC * 100).toFixed(2)}p a day
                                      </b>
                                    </div>
                                  )}
                                  {product.sellRate1 > 0 &&
                                    product.sellRate2 > 0 && (
                                      <div>
                                        Day Rate:{" "}
                                        <b style={{ fontWeight: 'bold' }}>
                                          {(product.sellRate1 * 100).toFixed(2)}p
                                          per kWh
                                        </b>
                                        <br />Night Rate:{" "}
                                        <b style={{ fontWeight: 'bold' }}>
                                          {(product.sellRate2 * 100).toFixed(2)}p
                                          per kWh
                                        </b>
                                        <br />Standing Charge:{" "}
                                        <b style={{ fontWeight: 'bold' }}>
                                          {(product.sellSC * 100).toFixed(2)}p a
                                          day
                                        </b>
                                      </div>
                                    )}
                                </div>
                              )
                            })
                          }
                        </div>
                      }
                    </div>
                    <div style={{ flexDirection: 'column' }}>
                      <div className={styles.servicePriceContainer}>
                        {s.servicePricePerWeek ? (
                          s.servicePricePerWeek !== "£0.00" && (!unlimitedPackageSelected || !s.freeWithUnlimited) ? (
                            <div className={styles.servicePriceItem}>
                              <div className={styles.servicePrice}>
                                {
                                  context.priceModeView === "week"
                                    ? convertNumberToPrice(currencyToNumber(s.servicePricePerWeek) * (context.individualPriceModeView === "household" ? numberOfBillPayers : 1))
                                    : context.priceModeView === "month"
                                      ? convertNumberToPrice(currencyToNumber(s.servicePricePerMonth) * (context.individualPriceModeView === "household" ? numberOfBillPayers : 1)) :
                                      convertNumberToPrice(currencyToNumber(s.servicePricePerYear) * (context.individualPriceModeView === "household" ? numberOfBillPayers : 1))}
                                /{context.priceModeView}
                              </div>
                              {numberOfBillPayers > 1 && <div className={styles.perPerson}>{context.individualPriceModeView === "household" ? "per household" : "per bill-payer"}</div>}

                            </div>
                          ) : (
                            <div className={styles.free}>FREE</div>
                          )
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <hr
                    style={{
                      width: "100%",
                      backgroundColor: "#fff",
                      margin: "30px auto",
                    }}
                  />
                </div>
              )
            })}
        </div>
        {
          <div style={{ width: '100%', textAlign: 'right' }}>
            <div style={{ fontSize: 40, fontWeight: "bold", margin: "0px 0" }}>
              {context.priceModeView === "month"
                ? toPoundFormat(monthlyTotal * (context.individualPriceModeView === "household" ? numberOfBillPayers : 1))
                : context.priceModeView === "year"
                  ? toPoundFormat(yearlyTotal * (context.individualPriceModeView === "household" ? numberOfBillPayers : 1))
                  : toPoundFormat(weeklyTotal * (context.individualPriceModeView === "household" ? numberOfBillPayers : 1))}/{context.priceModeView}
            </div>
            {numberOfBillPayers > 1 && <div className={styles.perPerson}>{context.individualPriceModeView === "household" ? "per household" : "per bill-payer"}</div>}
          </div>
        }
      </div>
    </>
  )
}
