import React from "react"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import clsx from "clsx"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: "1",
    overflowY: "auto",
    maxWidth: 1400,
    margin: "0 auto",
    padding: "10px",
  },
  sectionRoot: {
    display: "flex",
    flexDirection: "column",
    padding: "30px 0px 0px 0px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
    },
  },
  "@media only screen and (max-width: 600px)": {
    root: {
      padding: "10px",
    },
    sectionRoot: {
      display: "flex",
      flexDirection: "column",
      padding: "15px 0px 0px 0px",
    },
  },
}))

export default function MainContent({ children, className, ...rest }) {
  const classes = useStyles()
  return (
    <div>
      <div className={clsx(classes.root, className)} {...rest}>
        {children}
      </div>
    </div>
  )
}

const mainContentSectionStyles = theme => ({
  mainContentSection: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      padding: "0px",
    },
  },
})

MainContent.Section = withStyles(mainContentSectionStyles)(function ({
  children,
  classes,
  ...rest
}) {
  return (
    <div className={classes.mainContentSection} {...rest}>
      {children}
    </div>
  )
})
