import React from "react"
import Tooltip from "@material-ui/core/Tooltip"
import InfoIcon from "@material-ui/icons/Info"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    fontFamily: "mundial",
    flexDirection: "column",
    minHeight: "76px",
    alignItems: "center",
    borderRadius: "20px",
    border: "0.5px solid rgba(0, 0, 0, 0.23)",
    margin: "10px 0",
    overflow: "hidden",
    position: "relative",
  },
  imageContainer: {
    width: "100%",
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  textandIconContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: "10px",
    cursor: "pointer",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "0 10px",
  },
  title: {
    fontSize: "16px",
    lineHeight: "19.5px",
    fontWeight: "bold",
  },
  normalText: {
    fontSize: "16px",
    lineHeight: "19.5px",
  },
  popper: {
    fontSize: 20,
    opacity: 1,
    zIndex: 9999999999,
  },
  tooltip: {
    backgroundColor: "#5d5d5d",
    fontSize: 15,
    padding: 10,
    zIndex: 9999999999,
    "& a": {
      color: "#fff !important",
      textDecorationLine: "underline",
    },
  },
  icon: {
    opacity: "0",
  },
  infoIcon: {
    fontSize: 15,
  },
  infoIconWrapper: {
    position: "absolute",
    bottom: "9px",
    right: "9px",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
    "@media only screen and (max-width: 768px)": {
      cancelIcon: {
        position: "relative",
        right: "8px",
        bottom: "21px",
      },
    },
  },
  infoIconWrapperCenter: {
    position: "absolute",
    top: "35%",
    right: 10,
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
    "@media only screen and (max-width: 768px)": {
      cancelIcon: {
        position: "relative",
        right: "8px",
        bottom: "21px",
      },
    },
  },
  "@media only screen and (min-width: 768px)": {
    root: {
      display: "flex",
      flexDirection: "row",
      width: "500px",
    },
    imageContainer: {
      height: "100%",
      width: "auto",
      padding: "10px",
    },
  },
}))

/* The ModalSelectionBox is displayed when the user needs to pick a service option.
e.g. they select broadband, but then have to select a broadband option.
*/

export default function ModalSelectionBox({
  title,
  description,
  bottom,
  image,
  onClick,
  active,
  showInfoTooltip,
  tooltipText,
  tooltipLink,
  tooltipLinkText,
}) {
  console.log(tooltipLink, tooltipLinkText)
  const classes = useStyles()
  return (
    <div
      className={classes.root}
      onClick={onClick}
      onKeyPress={onClick}
      role="button"
      tabIndex="0"
      style={{
        backgroundColor: active ? "#021A3A" : "white",
        color: active ? "white" : "black",
      }}
    >
      <div className={classes.imageContainer}>
        {image !== null ? <div className={classes.image}>{image}</div> : null}
      </div>
      <div className={classes.textandIconContainer}>
        <div className={classes.textContainer}>
          <div className={classes.title}>{title}</div>
          <div className={classes.normalText}>{description}</div>
          <div className={classes.title}>{bottom}</div>
        </div>
      </div>

      {showInfoTooltip && (
        <div
          className={classes.infoIconWrapper}
          onClick={e => e.stopPropagation()}
          onKeyPress={e => e.stopPropagation()}
          role="button"
          tabIndex="0"
        >
          <Tooltip
            title={
              <div>
                {tooltipText && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: tooltipText,
                    }}
                  />
                )}
              </div>
            }
            classes={{ popper: classes.popper, tooltip: classes.tooltip }}
            interactive
            disableHoverListener={tooltipText === undefined}
            enterTouchDelay={0}
            leaveTouchDelay={5000}
            role="button"
            tabIndex="0"
          >
            <InfoIcon classes={{ popper: classes.infoIcon }} />
          </Tooltip>
        </div>
      )}
    </div>
  )
}
