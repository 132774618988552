// extracted by mini-css-extract-plugin
export var shareAndEarn = "shareAndEarn-module--shareAndEarn--ZE-ps";
export var shareAndEarnDetail = "shareAndEarn-module--shareAndEarnDetail--9yqqz";
export var shareAndEarnDetailLabel = "shareAndEarn-module--shareAndEarnDetailLabel--W92Fb";
export var shareAndEarnDetailValue = "shareAndEarn-module--shareAndEarnDetailValue--RFCht";
export var shareAndEarnDetails = "shareAndEarn-module--shareAndEarnDetails--ngB2N";
export var shareAndEarnDetailsHeader = "shareAndEarn-module--shareAndEarnDetailsHeader--QTpYY";
export var shareAndEarnHeader = "shareAndEarn-module--shareAndEarnHeader--XvE9N";
export var shareAndEarnStatus = "shareAndEarn-module--shareAndEarnStatus--djzsj";
export var shareAndEarnStatusCircle = "shareAndEarn-module--shareAndEarnStatusCircle--+uMqc";
export var shareAndEarnStatuses = "shareAndEarn-module--shareAndEarnStatuses--H-wcw";