import React, { useEffect, useState } from "react"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"
import { formatDuration, intervalToDuration } from "date-fns"
import { Link } from "gatsby"

import { Button, QuoteExpiryTimer } from "."

import PageWrapper from "../../../components/PageWrapper"
import DialogModal from "../../../components/dialogModal"

const useStyles = makeStyles({
  quoteExpiresBanner: {
    background: "#f7c829",
    textAlign: "center",
    padding: "5px",
  },
})

export default function Wrapper({
  matchingQuotingToolVersion,
  toolId,
  quoteId,
  group,
}) {
  const classes = useStyles()

  const [hideQuoteExpiredMessage, setHideQuoteExpiredMessage] = useState(false)
  const [currentTime, setCurrentTime] = useState(Date.now())

  let quotePricesExpireAt = null
  if (!group.pricesAtSignUp) {
    quotePricesExpireAt = group.quote.quotePricesExpireAt
  }
  const quotePricesExpired = group.quotePricesExpired

  const quoteLink =
    "/tenants/app/" +
    (toolId || "tool-a") +
    "/signup/" +
    quoteId +
    "#Quote-Step-0" +
    matchingQuotingToolVersion.quotingToolPages.length

  useEffect(() => {
    const interval = setInterval(() => setCurrentTime(Date.now()), 1000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  let duration =
    quotePricesExpireAt &&
    intervalToDuration({
      start: currentTime,
      end: new Date(quotePricesExpireAt + "+00:00"),
    })

  return (
    <>
      {quotePricesExpireAt && new Date() < new Date(quotePricesExpireAt) && (
        <div
          className={clsx(
            classes.quoteExpiresBanner,
            "quotingTool-quoteExpiresBanner"
          )}
        >
          Quote prices expire soon!{" "}
          {formatDuration(duration, {
            delimiter: ", ",
          })}
        </div>
      )}
      <DialogModal
        open={
          !hideQuoteExpiredMessage &&
          (quotePricesExpired ||
            (quotePricesExpireAt && new Date(quotePricesExpireAt) < new Date()))
        }
        quotingTool={true}
        title="Quote expired"
      >
        <div style={{ padding: 20 }}>
          Sorry, your previous quote expired. The quote has been recalculated
          with up-to-date prices.
          {!group.quote.id && (
            <Button
              fullWidth
              className={clsx(
                "SignUp-Step-06-Complete",
                "quotingTool-submitButton"
              )}
              style={{ marginTop: 20 }}
              onClick={() => setHideQuoteExpiredMessage(true)}
            >
              Return to quote
            </Button>
          )}
          {group.quote.id && (
            <Link to={quoteLink}>
              <Button
                fullWidth
                className={clsx(
                  "SignUp-Step-06-Complete",
                  "quotingTool-submitButton"
                )}
                style={{ marginTop: 20 }}
              >
                Return to quote
              </Button>
            </Link>
          )}
        </div>
      </DialogModal>
    </>
  )
}
