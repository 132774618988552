import React, { useEffect, useContext, useCallback } from "react"

import {
  getMyTenancies as apiGetMyTenancies,
  getTenancyMeterReadings as apiGetTenancyMeterReadings,
  getTenancyMeterReading as apiGetTenancyMeterReading,
} from "../../services/tenantsApiV2"

import { GlobalStateContext } from "../../context/GlobalContextProvider"

import PageWrapper from "../../components/PageWrapper"
import AppTitle from "../../components/appTitle/AppTitle"
import DialogModal from "../../components/dialogModal"
import LoadingIcon from "../../components/loadingIcon/LoadingIcon"
import InformationCards from "../../components/informationCards/InformationCards"

import * as tenancyDetailsStyles from "./tenancyDetails.module.css"

export default ({ location, tenancyId }) => {
  const context = useContext(GlobalStateContext)

  const [gettingTenancies, setGettingTenancies] = React.useState(true)
  const [tenancy, storeTenancy] = React.useState(null)
  const [gettingTenancyDetails, setGettingTenancyDetails] = React.useState(
    false
  )
  const [meterReadings, storeMeterReadings] = React.useState([])
  const [
    gettingMeterReadingEvidence,
    setGettingMeterReadingEvidence,
  ] = React.useState(false)
  const [meterReadingEvidence, storeMeterReadingEvidence] = React.useState("")

  const getTenancyDetails = useCallback(async tenancyId => {
    try {
      setGettingTenancyDetails(true)
      const data = await Promise.all([apiGetTenancyMeterReadings(tenancyId)])

      if (data[0] && data[0].value) {
        storeMeterReadings(data[0].value)
      }
    } catch (e) {
      console.log(e)
    }
    setGettingTenancyDetails(false)
  }, [])

  const getMyTenancies = useCallback(async () => {
    try {
      setGettingTenancies(true)
      const data = await apiGetMyTenancies()
      if (data.value.tenancies && data.value.tenancies.length > 0) {
        storeTenancy(data.value.tenancies[0])
        getTenancyDetails(data.value.tenancies[0].zohoEntityId)
      }
    } catch (e) {
      console.log(e)
    }
    setGettingTenancies(false)
  }, [getTenancyDetails])

  const getTenancyMeterReading = async meterReadingId => {
    try {
      setGettingMeterReadingEvidence(true)
      const data = await apiGetTenancyMeterReading(
        tenancy.zohoEntityId,
        meterReadingId
      )
      if (data && data.attachments && data.attachments.length > 0) {
        storeMeterReadingEvidence(data.attachments[0])
      }
    } catch (e) {
      console.log(e)
    }
    setGettingMeterReadingEvidence(false)
  }

  useEffect(() => {
    getMyTenancies()
  }, [context.loggedIn, getMyTenancies])

  return (
    <PageWrapper
      location={location}
      showSidebar={true}
      showPortalHeader={true}
      hideHeader={true}
      showLoadingIndicator={gettingTenancies || gettingTenancyDetails}
      tenancy={tenancy}
    >
      <div className={tenancyDetailsStyles.tenancyDetails}>
        {tenancy && (
          <AppTitle
            showBackHome={true}
            title="Meter readings"
            secondaryActionButtons={[
              {
                title: "Submit a meter reading",
                to: `/tenants/app/meter-readings/${tenancy.zohoEntityId}/submit-meter-reading`,
              },
            ]}
          />
        )}

        <div>
          <InformationCards
            title="PREVIOUS READINGS"
            rows={meterReadings.map(meterReading => ({
              id: meterReading.zohoEntityId,
              title: meterReading.type,
              value: new Date(meterReading.createdAt).toLocaleDateString("en-GB"),
              details: [
                `Reading 1: ${meterReading.reading1}`,
                meterReading.reading2 && `Reading 2: ${meterReading.reading2}`,
              ],
              footerTitle: meterReading.readingPeriod,
              footerValue: "View evidence",
              onFooterValueClick: () =>
                getTenancyMeterReading(meterReading.zohoEntityId),
            }))}
          />
        </div>
      </div>

      <DialogModal
        onClose={() => storeMeterReadingEvidence(null)}
        hideCancelButton={true}
        open={gettingMeterReadingEvidence || meterReadingEvidence}
      >
        <div className={tenancyDetailsStyles.meterReadingEvidence}>
          {gettingMeterReadingEvidence && <LoadingIcon />}
          {!gettingMeterReadingEvidence && (
            <img
              alt="Meter reading evidence"
              src={`data:image/png;base64, ${meterReadingEvidence}`}
              style={{ maxHeight: "100%", maxWidth: "100%" }}
            />
          )}
        </div>
      </DialogModal>
    </PageWrapper>
  )
}
