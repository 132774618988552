import React from "react"
import { useQuery } from "react-query"
import { useGet } from "./api"
import { subscribe, unsubscribe } from "../pubSub"

export async function getQuote(quoteId) {
  let response = await fetch(
    `${process.env.GATSBY_API_URL}/quoting/Quote/${quoteId}`,
    {
      method: "GET",
      cache: "no-store",
    }
  )
  let data = await response.json()
  return data
}

export async function upsertQuote(payload) {
  let response = await fetch(`${process.env.GATSBY_API_URL}/quoting/Quote`, {
    method: "POST",
    headers: {
      Accept: "json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  })
  let data = await response.json()
  return data
}

export async function createHomeownerLead(payload) {
  let response = await fetch(
    `${process.env.GATSBY_API_URL}/quoting/Quote/homeowner-interest`,
    {
      method: "POST",
      headers: {
        Accept: "json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  )
  let data = await response.json()
  return data
}

export async function finaliseQuote(
  id,
  tenancyDetails,
  packageDetails,
  lastCompletedStep,
  currentProgressUrl
) {
  let response = await fetch(
    `${process.env.GATSBY_API_URL}/quoting/Quote/finalise`,
    {
      method: "POST",
      headers: {
        Accept: "json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        quoteId: id,
        tenancyDetails: JSON.stringify(tenancyDetails),
        packageDetails: JSON.stringify(packageDetails),
        lastCompletedStep,
        currentProgressUrl,
      }),
    }
  )
  let data = await response.json()
  return data
}

export function useGetCities() {
  const get = useGet()

  const query = useQuery("cities", () => {
    return get("/quoting/Quote/cities")
  })

  React.useEffect(() => {
    subscribe("getCities.update", query.refetch)
    return () => unsubscribe("getCities.update", query.refetch)
  })

  return query
}

export async function getQuoteByEmail(email) {
  let response = await fetch(
    `${process.env.GATSBY_API_URL}/quoting/Quote/email/${email}`,
    {
      method: "POST",
      cache: "no-store",
    }
  )
  let data = await response.json()
  return data
}
