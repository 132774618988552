import React from "react"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  frames: {
    display: "flex",
    width: "100%",
    gap: "20px",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
}))

function Frames({ children }) {
  const classes = useStyles()

  return <div className={classes.frames}>{children}</div>
}
export default Frames
