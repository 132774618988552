import React from "react"
import Tooltip from "@material-ui/core/Tooltip"
import InfoIcon from "@material-ui/icons/Info"

import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import { makeStyles } from "@material-ui/core/styles"
import AddCircleIcon from "@material-ui/icons/AddCircle"
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    width: 500,
    minHeight: "76px",
    alignItems: "center",
    borderRadius: "20px",
    border: "0.5px solid rgba(0, 0, 0, 0.23)",
    margin: "4px 0",
    overflow: "hidden",
    position: "relative",
  },
  tabStyle: {
    display: "flex",
    flexDirection: "row",
    width: 500,
    minHeight: "76px",
    alignItems: "center",
    borderRadius: "20px",
    border: "0.5px solid rgba(0, 0, 0, 0.23)",
    margin: "4px 0",
    overflow: "hidden",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "calc(50% - 10px)",
      margin: 5,
    },
  },
  // subText: {
  //   [theme.breakpoints.down("sm")]: {
  //     display: "none",
  //   },
  // },
  subtitle: {
    fontSize: "0.9rem",
    lineHeight: "143%",
  },
  mobileSubText: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  imageContainer: {
    height: "100%",
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  textandIconContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "0 10px",
    padding: "16px",
  },
  mainText: {
    fontSize: "16px",
    lineHeight: "19.5px",
    fontWeight: "bold",
  },
  icon: {
    opacity: "0",
  },
  popper: {
    fontSize: 20,
    opacity: 1,
  },
  tooltip: {
    backgroundColor: "#5d5d5d",
    fontSize: 15,
    padding: 10,
    "& a": {
      color: "#fff !important",
      textDecorationLine: "underline",
    },
  },
  iconWrapper: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "column",
    padding: 10,
  },
  infoIcon: {
    fontSize: 15,
  },
}))

export default function SelectionBox({
  text,
  subtext,
  subtitle,
  active,
  showArrowIcon,
  onClick,
  color,
  image,
  showCancelButton,
  cancelButton,
  onClickCancelButton,
  tooltipText,
  tabStyleSelectionBox,
}) {
  const classes = useStyles()
  return (
    <div
      className={tabStyleSelectionBox ? classes.tabStyle : classes.root}
      onClick={onClick}
      onKeyPress={onClick}
      role="button"
      tabIndex="0"
      style={{
        backgroundColor: active
          ? color === "green"
            ? "#40a535"
            : color === "yellow"
            ? "#f6be40"
            : color === "light blue"
            ? "#ADD8E6"
            : color === "blue"
            ? "#021A3A"
            : "#021A3A"
          : "white",
        color: active ? (color === "yellow" ? "black" : "white") : "black",
      }}
    >
      <div className={classes.imageContainer}>
        {image !== null ? <div className={classes.image}>{image}</div> : null}
      </div>
      <div className={classes.textandIconContainer}>
        <div className={classes.textContainer}>
          <div className={classes.mainText}>{text}</div>
          <div className={classes.subtitle}>{subtitle}</div>
          <div className={classes.subText}>{subtext}</div>
        </div>
        <div className={classes.iconWrapper}>
          <div>
            {showCancelButton && cancelButton ? (
              <div
                onClick={e => {
                  if (onClickCancelButton) {
                    e.stopPropagation()
                    onClickCancelButton()
                  }
                }}
                onKeyPress={e => {
                  if (onClickCancelButton) {
                    e.stopPropagation()
                    onClickCancelButton()
                  }
                }}
                role="button"
                tabIndex="0"
                className={classes.cancelIcon}
              >
                <RemoveCircleIcon style={{ color: "#F44336" }} />
              </div>
            ) : null}
            {!cancelButton ? (
              <div className={classes.cancelIcon}>
                <AddCircleIcon style={{ color: "#4caf4f" }} />
              </div>
            ) : null}
          </div>
          <div>{showArrowIcon && <ChevronRightIcon />}</div>
          <Tooltip
            title={
              <div>
                {tooltipText && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: tooltipText,
                    }}
                  />
                )}
              </div>
            }
            classes={{ popper: classes.popper, tooltip: classes.tooltip }}
            interactive
            disableHoverListener={tooltipText === undefined}
            enterTouchDelay={0}
            leaveTouchDelay={5000}
            role="button"
            tabIndex="0"
            onClick={e => e.stopPropagation()}
            onKeyPress={e => e.stopPropagation()}
          >
            <InfoIcon />
          </Tooltip>
        </div>
      </div>
    </div>
  )
}

SelectionBox.Area = function ({ children }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        padding: "18px 0px",
        width: "100%",
        cursor: "pointer",
        flexWrap: "wrap",
        "@media only screen and (max-width: 600px)": {
          display: "flex",
          justifyContent: "flex-start",
          padding: "15px 0px",
          width: "100%",
        },
      }}
    >
      {children}
    </div>
  )
}
