// extracted by mini-css-extract-plugin
export var billPaymentActions = "tenancyDetails-module--billPaymentActions--6bYdE";
export var description = "tenancyDetails-module--description--XfbFV";
export var meterReadingEvidence = "tenancyDetails-module--meterReadingEvidence--k2e2g";
export var tenancyDetail = "tenancyDetails-module--tenancyDetail--jFCHr";
export var tenancyDetailLabel = "tenancyDetails-module--tenancyDetailLabel--ChXe+";
export var tenancyDetailValue = "tenancyDetails-module--tenancyDetailValue--AzjSt";
export var tenancyDetails = "tenancyDetails-module--tenancyDetails--AV1mY";
export var tenancyDetailsHeader = "tenancyDetails-module--tenancyDetailsHeader--87iIh";
export var tenancyStatus = "tenancyDetails-module--tenancyStatus--DXYcw";
export var tenancyStatusCircle = "tenancyDetails-module--tenancyStatusCircle--DJWrv";
export var tenancyStatusCircleFill = "tenancyDetails-module--tenancyStatusCircleFill--KEMEX";
export var tenancyStatusCircleWrapper = "tenancyDetails-module--tenancyStatusCircleWrapper--r0pQC";
export var tenancyStatusTitle = "tenancyDetails-module--tenancyStatusTitle--6mnyB";
export var tenancyStatuses = "tenancyDetails-module--tenancyStatuses--8iZZh";