import React from "react"

import { resendVerificationEmail as apiResendVerificationEmail } from "../../services/tenantsApiV2"
import Button from "../../components/button/Button"

export default () => {
  const [resendingEmail, setResendingEmail] = React.useState(false)

  const resendVerificationEmail = async () => {
    try {
      setResendingEmail(true)
      await apiResendVerificationEmail()
    } catch (e) {
      console.log(e)
    }
    setResendingEmail(false)
  }

  return (
    <div>
      <div style={{ marginBottom: 20 }}>
        Please verify your account to continue
      </div>
      <div style={{ marginBottom: 10 }}>Haven't received an email?</div>
      <div style={{ width: 200 }}>
        <Button
          title="Resend email"
          submitting={resendingEmail}
          onClick={() => resendVerificationEmail()}
        />
      </div>
    </div>
  )
}
