import React, { useContext } from "react"
import { Link, navigate } from "gatsby"

import { GlobalDispatchContext } from "../../context/GlobalContextProvider"
import { acceptInvite as apiAcceptInvite } from "../../services/tenantsApiV2"

import PageWrapper from "../../components/PageWrapper"
import AuthenticationWrapper from "../../components/authenticationWrapper/AuthenticationWrapper"
import Form from "../../components/Form"

import * as authPageStyles from "../authPage.module.css"

const acceptInviteFormOptions = {
  fields: [
    {
      placeholder: "Password",
      type: "password",
      required: true,
      name: "password",
      helperText: "min 8 characters, 1 upper case, 1 number",
      validate: value => /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(value),
    },
    {
      placeholder: "Repeat password",
      type: "password",
      required: true,
      name: "repeatPassword",
      validate: (value, formPayload) => value === formPayload["password"],
    },
    {
      type: "checkbox",
      label: "Sign me up to hear about the latest Bunch news",
      name: "signUpToNewsletter",
    },
    {
      type: "checkbox",
      label: (
        <div>
          I agree to Bunch{" "}
          <a
            href="https://www.the-bunch.co.uk/terms-conditions"
            target="_blank"
            rel="noreferrer"
          >
            terms
          </a>{" "}
          and{" "}
          <a
            href="https://www.the-bunch.co.uk/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            privacy policy
          </a>
        </div>
      ),
      name: "termsAndConditions",
    },
  ],
  submitText: "Sign up",
}

const AcceptInvite = ({ location, code }) => {
  const dispatch = useContext(GlobalDispatchContext)

  const [settingUpAccount, setSettingUpAccount] = React.useState(false)
  const [acceptInviteError, setAcceptInviteError] = React.useState("")

  const acceptInvite = async payload => {
    if (!payload.termsAndConditions) {
      return setAcceptInviteError("Please agree to the terms and conditions")
    }

    if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(payload.password)) {
      return setAcceptInviteError("Password does not match requirements")
    }

    if (payload.password !== payload.repeatPassword) {
      return setAcceptInviteError("Passwords do not match")
    }

    if (!payload.password) {
      return setAcceptInviteError("Please complete all fields")
    }

    setAcceptInviteError("")

    try {
      setSettingUpAccount(true)
      const data = await apiAcceptInvite({ ...payload, code })

      if (data.success) {
        dispatch({
          type: "loggedInToTenantsPortal",
          tokens: {
            accessToken: data.value.accessToken,
            refreshToken: data.value.refreshToken,
          },
        })
        navigate("/tenants/app/tenancies")
      } else if (data.errors) {
        setAcceptInviteError(data.errors[0].reason)
      } else {
        setAcceptInviteError("An error occurred trying to setup account")
      }
      setSettingUpAccount(false)
    } catch (e) {
      setSettingUpAccount(false)
      setAcceptInviteError(e.message)
    }
  }

  // TODO: Check that the setup account verification token is valid

  return (
    <PageWrapper location={location} hideHeader={true} hideHeaderBorder={true}>
      <div className={authPageStyles.authPage}>
        <AuthenticationWrapper
          title="Tenant portal"
          description={"Set up your account"}
        >
          <Form
            options={acceptInviteFormOptions}
            onSubmit={payload => acceptInvite(payload)}
            submitting={settingUpAccount}
            apiErrorMessage={acceptInviteError}
          />
          <div>
            Already have an account?{" "}
            <Link to="/tenants/app/login">Sign in</Link>
          </div>
        </AuthenticationWrapper>
      </div>
    </PageWrapper>
  )
}

export default AcceptInvite
