import React, { useContext, useEffect, useCallback } from "react"
import { navigate } from "gatsby-link"
import { Link } from "gatsby"

import {
  getTenancyFromMeterReadingRequestToken as apiGetTenancyFromMeterReadingRequestToken,
  loginWithMeterReadingRequestToken as apiLoginWithMeterReadingRequestToken,
} from "../../services/tenantsApiV2"

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../../context/GlobalContextProvider"

import LoadingIcon from "../../components/loadingIcon/LoadingIcon"
import PageWrapper from "../../components/PageWrapper"
import AuthenticationWrapper from "../../components/authenticationWrapper/AuthenticationWrapper"
import Form from "../../components/Form"

import * as authPageStyles from "../authPage.module.css"

const MeterReadingRequest = ({ location, meterReadingRequestToken }) => {
  const dispatch = useContext(GlobalDispatchContext)
  const context = useContext(GlobalStateContext)

  const [loginAndRedirectError, setLoginAndRedirectError] = React.useState("")

  const loginAndRedirect = useCallback(async () => {
    if (context.portalLoaded) {
      if (context.loggedInToTenantsPortal) {
        // If we are already logged in
        const data = await apiGetTenancyFromMeterReadingRequestToken(
          meterReadingRequestToken
        )
        navigate(`/tenants/app/tenancies/${data.value.tenancyId}/meters`)
      } else {
        const data = await apiLoginWithMeterReadingRequestToken({
          token: meterReadingRequestToken,
        })

        if (data.success) {
          dispatch({
            type: "loggedInToTenantsPortalForMeterReadings",
            tokens: {
              accessToken: data.value.accessToken,
              refreshToken: data.value.refreshToken,
            },
          })
          navigate(
            `/tenants/app/meter-reading-request/${data.value.tenancyId}/meters`
          )
        } else if (data.errors) {
          setLoginAndRedirectError(data.errors[0].reason)
        } else {
          setLoginAndRedirectError("An error occurred trying to login")
        }
      }
    }
  }, [
    context.portalLoaded,
    context.loggedInToTenantsPortal,
    meterReadingRequestToken,
    dispatch,
  ])

  useEffect(() => {
    loginAndRedirect()
  }, [loginAndRedirect])

  if (!context.portalLoaded) {
    return (
      <div className="loadingWrapper">
        <LoadingIcon />
      </div>
    )
  }

  return (
    <PageWrapper location={location} hideHeader={true} hideHeaderBorder={true}>
      <div className={authPageStyles.authPage}>
        <AuthenticationWrapper
          title="Meter reading request"
          description="You will be redirected shortly"
        >
          <>
            <Form
              options={{ fields: [] }}
              apiErrorMessage={loginAndRedirectError}
              showMessageOverride={true}
            />
            <div>
              <div>Link not working? Login to your portal <Link style={{ textDecorationLine: 'underline' }} to="/tenants/app/tenancies">here</Link></div>
            </div>
          </>
        </AuthenticationWrapper>
      </div>
    </PageWrapper>
  )
}

export default MeterReadingRequest
