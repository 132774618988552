import React from "react"
import { validatePostcode } from "../services/errorValidation"

import { Button, InputSection, Dropdown } from "./"

import CountriesList from "./countriesList.json"

export default function ManualAddressEntry({
  value,
  onChange,
  onCancel,
  ignorePostcodeValidation,
}) {
  const [manualAddress, setManualAddress] = React.useState(value)

  const [validationErrors, setValidationErrors] = React.useState({
    addressLine1: {
      error: false,
      helperText: "",
    },
    addressLine2: {
      error: false,
      helperText: "",
    },
    city: {
      error: false,
      helperText: "",
    },
    country: {
      error: false,
      helperText: "",
    },
    postcode: {
      error: false,
      helperText: "",
    },
  })

  const validateFieldsOnSubmit = newErrors => {
    if (!manualAddress.addressLine1) {
      newErrors.addressLine1 = {
        error: true,
        helperText: "Address line 1 is required",
      }
    }

    if (!manualAddress.city) {
      newErrors.city = { error: true, helperText: "Town is required" }
    }

    if (!manualAddress.postCode) {
      newErrors.postcode = { error: true, helperText: "Postcode is required" }
    }

    if (!manualAddress.country) {
      newErrors.country = { error: true, helperText: "Country is required" }
    }

    setValidationErrors({ ...validationErrors, ...newErrors })
    return newErrors
  }

  const confirmAddress = () => {
    const newErrors = validateFieldsOnSubmit(validationErrors)
    let hasError = false
    Object.values(newErrors).forEach(value => {
      if (value.error) {
        hasError = true
      }
    })

    if (hasError) {
      return false
    }

    onChange({ ...manualAddress, manuallyEntered: true })
  }

  return (
    <div style={{ width: "100%" }}>
      <InputSection
        label="Address line 1*"
        error={validationErrors.addressLine1?.error}
        helperText={validationErrors.addressLine1?.helperText}
        value={manualAddress.addressLine1}
        onChange={event => {
          let vErrors = {
            ...validationErrors,
            addressLine1: { ...validationErrors.addressLine1 },
          }
          if (event.target.value === "") {
            vErrors.addressLine1 = {
              error: true,
              helperText: "Address line 1 is required",
            }
          } else {
            vErrors.addressLine1 = { error: false, helperText: "" }
          }
          setValidationErrors(vErrors)
          setManualAddress({
            ...manualAddress,
            addressLine1: event.target.value,
          })
        }}
        style={{ marginBottom: 20 }}
      />
      <InputSection
        label="Address line 2"
        value={manualAddress.addressLine2}
        onChange={event =>
          setManualAddress({
            ...manualAddress,
            addressLine2: event.target.value,
          })
        }
        style={{ marginBottom: 20 }}
      />
      <InputSection
        label="Town*"
        value={manualAddress.city}
        error={validationErrors.city?.error}
        helperText={validationErrors.city?.helperText}
        onChange={event => {
          let vErrors = {
            ...validationErrors,
            city: { ...validationErrors.city },
          }
          if (event.target.value === "") {
            vErrors.city = { error: true, helperText: "City is required" }
          } else {
            vErrors.city = { error: false, helperText: "" }
          }
          setValidationErrors(vErrors)
          setManualAddress({ ...manualAddress, city: event.target.value })
        }}
        style={{ marginBottom: 20 }}
      />
      <InputSection
        label="Postcode*"
        error={validationErrors.postcode?.error}
        helperText={validationErrors.postcode?.helperText}
        value={manualAddress.postCode}
        onChange={event => {
          let vErrors = {
            ...validationErrors,
            postcode: { ...validationErrors.postcode },
          }
          if (event.target.value === "") {
            vErrors.postcode = {
              error: true,
              helperText: "Please enter postcode",
            }
          } else if (
            !ignorePostcodeValidation &&
            !validatePostcode(event.target.value.trim())
          ) {
            vErrors.postcode = {
              error: true,
              helperText: "Please enter a valid postcode",
            }
          } else {
            vErrors.postcode = { error: false, helperText: "" }
          }
          setManualAddress({ ...manualAddress, postCode: event.target.value })
          setValidationErrors(vErrors)
        }}
        style={{ marginBottom: 20 }}
      />
      {/* <InputSection
        label="Country*"
        value={manualAddress.country}
        error={validationErrors.country?.error}
        helperText={validationErrors.country?.helperText}
        onChange={event => {
          let vErrors = {
            ...validationErrors,
            country: { ...validationErrors.country },
          }
          if (event.target.value === "") {
            vErrors.country = { error: true, helperText: "Country is required" }
          } else {
            vErrors.country = { error: false, helperText: "" }
          }
          setValidationErrors(vErrors)
          setManualAddress({ ...manualAddress, country: event.target.value })
        }}
      /> */}
      <Dropdown
        // className={filterBarStyles.filterBarDropdown}
        label="Country*"
        placeholder="Country*"
        labels={CountriesList.map(country => country.name)}
        setValue={option => {
          let vErrors = {
            ...validationErrors,
            country: { ...validationErrors.country },
          }
          if (option === "") {
            vErrors.country = { error: true, helperText: "Country is required" }
          } else {
            vErrors.country = { error: false, helperText: "" }
          }
          setValidationErrors(vErrors)
          setManualAddress({ ...manualAddress, country: option })
        }}
        value={manualAddress.country}
        variant=""
        autocomplete
      />
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          marginTop: 20,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              textDecorationLine: "underline",
              cursor: "pointer",
              marginRight: 10,
              color: "#000",
            }}
            onClick={() => onCancel()}
          >
            Cancel
          </div>
          <Button white onClick={() => confirmAddress()}>
            Confirm address
          </Button>
        </div>
      </div>
    </div>
  )
}
