import React from "react"
import { publish } from "../pubSub"
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js"
import { usePost } from "./api"

const apiHost = process.env.GATSBY_API_URL

export function useCreateClientSecret(customerId) {
  const post = usePost(`/payments/customer/${customerId}/create-setup`)

  const postRef = React.useRef(() => {
    return post({}).then(r => {
      publish("group.update")
      return r
    })
  })

  return postRef.current
}

export function useSetup() {
  const stripe = useStripe()
  const elements = useElements()

  return async ({ customerId, clientSecret, name, address, dateOfBirth, isVulnerablePerson }) => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return {
        isSuccess: false,
        error: "Stripe could not be loaded, please use a different browser",
      }
    }

    let result = null;

    if (clientSecret.startsWith("seti")) {
      result = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: name,
            address: {
              line1: address.addressLine1,
              line2: address.addressLine1,
              postal_code: address.postcode,
              city: address.city,
              state: address.county, // county
              //country: address.country
            }
          },
        },
      })
    } else {
      result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: name,
            address: {
              line1: address.addressLine1,
              line2: address.addressLine1,
              postal_code: address.postcode,
              city: address.city,
              state: address.county, // county
              //country: address.country
            }
          },
        },
      })
    }

    fetch(`${apiHost}/payments/customer/${customerId}/attempted-signup`, {
      method: "POST",
      headers: {
        Accept: "json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        dateOfBirth,
        isVulnerablePerson
      })
    })

    console.log("stripeResult", result)

    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)

      fetch(`${apiHost}/payments/customer/${customerId}/payment-error`, {
        method: "POST",
        headers: {
          Accept: "json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          stripeError: result.error.message,
        }),
      })

      return { isSuccess: false, error: result.error.message }
    } else {
      // The payment has been processed!
      if (result.paymentIntent && result.paymentIntent.status === "succeeded") {
        return { isSuccess: true }
      }
      else if (result.setupIntent && result.setupIntent.status === "succeeded") {
        return { isSuccess: true }
      }
      else if (result.setupIntent.status === "succeeded") {
        return { isSuccess: true }
      } else {
        console.log("Payment failed: ", result.message)

        fetch(`${apiHost}/payments/customer/${customerId}/payment-error`, {
          method: "POST",
          headers: {
            Accept: "json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            stripeError: result.message,
          }),
        })

        return result
      }
    }
  }
}
