import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import Checkbox from "@material-ui/core/Checkbox"
import PageWrapper from "../../../components/PageWrapper"

import QuoteSummary from "../quote/containers/quoteCreation/QuoteSummary"

import {
  getResignDetails as apiGetResignDetails,
  agreeToResignTerms as apiAgreeToResignTerms,
} from "../../../services/tenantsApiV2"

import { Button, Center, TextWithSubtext } from "../components"

import * as signUpSuccessStyles from "./signUpSuccess.module.css"

const useStyles = makeStyles(() => ({}))

export default function ResignPage({ location, resignAuthToken }) {
  const classes = useStyles()

  const [gettingResignDetails, setGettingResignDetails] = useState(false)
  const [resignDetails, setResignDetails] = useState(null)
  const [agreeingToResignTerms, setAgreeingToResignTerms] = useState(false)
  const [agreedToTerms, setAgreedToTerms] = useState(false)
  const [agreed, setAgreed] = useState(false)

  const getResignDetails = async () => {
    try {
      setGettingResignDetails(true)
      const data = await apiGetResignDetails(resignAuthToken)
      if (data.value) {
        setResignDetails(data.value)
      }
    } catch (e) {
      if (e.message === "Already agreed to terms") {
        setAgreedToTerms(true)
      }
    } finally {
      setGettingResignDetails(false)
    }
  }

  useEffect(() => {
    getResignDetails()
  }, [resignAuthToken])

  const agreeToResignTerms = async () => {
    try {
      setAgreeingToResignTerms(true)
      const data = await apiAgreeToResignTerms(resignAuthToken)
      if (data.success) {
        getResignDetails()
      }
    } catch (e) {
      console.log(e)
    } finally {
      setAgreeingToResignTerms(false)
    }
  }

  return (
    <PageWrapper
      location={location}
      quoteViewMode={true}
      backgroundColor="#fff"
      //   topBannerBackgroundColor={
      //     matchingQuotingToolVersion.topBarBackgroundHexCode
      //   }
      //   logoImageUrl={matchingQuotingToolVersion.logoImageUrl}
      showLoadingIndicator={gettingResignDetails}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <div
          style={{
            maxWidth: "1200px",
            minWidth: "320px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ width: "100%", maxWidth: "600px" }}>
            {agreedToTerms && (
              <div
                className={signUpSuccessStyles.signUpSuccess}
                style={{ textAlign: "center" }}
              >
                <img
                  alt="Signup success"
                  className={signUpSuccessStyles.successImage}
                  src={require("./sign-up-success.png")}
                />
                <div className={signUpSuccessStyles.successTitle}>
                  You're all set!
                </div>
                <div className={signUpSuccessStyles.successDescription}>
                  Thank you for re-signing with Bunch.
                </div>
              </div>
            )}

            {!agreedToTerms && resignDetails && (
              <div style={{ marginTop: 40, width: "100%" }}>
                <div
                  style={{
                    borderRadius: "10px",
                    overflow: "hidden",
                    margin: "20px 0",
                  }}
                >
                  <QuoteSummary
                    quote={{
                      services: resignDetails.services,
                      tenancyDetails: "{}",
                      packageDetails: JSON.stringify(
                        resignDetails.selectedQuoteServices
                      ),
                    }}
                    isSummaryPage
                    matchingQuotingToolVersion={null}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginTop: 40,
                    marginBottom: 20,
                  }}
                >
                  <Checkbox
                    color="primary"
                    id="agree"
                    name="agree"
                    checked={agreed}
                    value={agreed}
                    onChange={e => {
                      setAgreed(e.target.checked)
                    }}
                  />
                  <div
                    htmlFor="agree"
                    style={{
                      fontSize: "15px",
                      fontWeight: "bold",
                      fontFamily: "mundial",
                      color: "#fff",
                    }}
                  >
                    I agree to Bunch{" "}
                    <a
                      href="https://www.the-bunch.co.uk/terms-conditions"
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: "#fff" }}
                    >
                      terms
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://www.the-bunch.co.uk/privacy-policy"
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: "#fff" }}
                    >
                      privacy policy
                    </a>
                  </div>
                </div>
                <div>
                  <Button
                    fullWidth={true}
                    onClick={() => agreeToResignTerms()}
                    showShine
                    loading={agreeingToResignTerms}
                    disabled={!agreed}
                  >
                    Re-sign
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </PageWrapper>
  )
}
