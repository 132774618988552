import React, { useContext, useRef } from "react"
import { withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import { Link } from "gatsby"
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../../../context/GlobalContextProvider"

import { bookViewing as apiBookViewing } from "../../../services/tenantsApi"

import PropertyGallery from "../propertyGallery/PropertyGallery"
import LivingInSection from "../../livingInSection/LivingInSection"
import DialogModal from "../../dialogModal"
import Button from "../../button/Button"
import Form from "../../Form"

import * as propertyResultStyles from "./propertyResult.module.css"

const PropertyMap = withGoogleMap(props => (
  <GoogleMap ref={props.mapRef} defaultZoom={14} defaultCenter={props.center}>
    {props.property && (
      <Marker
        onClick={() =>
          props.onMarkerClick && props.onMarkerClick(props.property)
        }
        position={{
          lat: props.property.location.latitude,
          lng: props.property.location.longitude,
        }}
      />
    )}
  </GoogleMap>
))

const bookPropertyViewingOptions = {
  fields: [
    {
      name: "existingAccount",
      type: "data",
    },
    {
      placeholder: "First name",
      type: "text",
      required: true,
      name: "firstName",
    },
    {
      placeholder: "Last name",
      type: "text",
      required: true,
      name: "lastName",
    },
    {
      placeholder: "Phone number",
      type: "text",
      required: true,
      name: "phoneNumber",
    },
    {
      placeholder: "Email address",
      type: "email",
      required: true,
      name: "email",
    },
    {
      placeholder: "Password",
      type: "password",
      required: true,
      name: "password",
      showIfFalse: "existingAccount",
    },
    {
      placeholder: "Repeat password",
      type: "password",
      required: true,
      name: "repeatPassword",
      showIfFalse: "existingAccount",
    },
    {
      type: "checkbox",
      label: "Sign me up to hear about the latest Bunch news",
      name: "signUpToNewsletter",
    },
    {
      type: "checkbox",
      label: (
        <div>
          I agree to Bunch{" "}
          <a
            href="https://www.the-bunch.co.uk/terms-conditions"
            target="_blank"
            rel="noreferrer"
          >
            terms
          </a>{" "}
          and{" "}
          <a
            href="https://www.the-bunch.co.uk/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            privacy policy
          </a>
        </div>
      ),
      name: "termsAndConditions",
      showIfFalse: "existingAccount",
    },
    {
      label: "Tick if you have a UK guarantor",
      type: "checkbox",
      name: "doesHaveUKGuarantor",
    },
    {
      showIfTrue: "doesHaveUKGuarantor",
      placeholder: "Guarantor name",
      type: "text",
      name: "guarantorName",
    },
    {
      showIfTrue: "doesHaveUKGuarantor",
      placeholder: "Guarantor email",
      type: "email",
      name: "guarantorEmail",
    },
  ],
  submitText: "Submit",
}

export default function PropertyResult({
  property,
  toggleFavouriteProperty,
  favouritedProperty,
  view,
  style,
  id,
  swipeMode,
}) {
  const dispatch = useContext(GlobalDispatchContext)
  const context = useContext(GlobalStateContext)

  const mapRef = useRef(null)

  const [showBookAViewing, setShowBookAViewing] = React.useState(false)
  const [bookingAViewing, setBookingAViewing] = React.useState(false)
  const [bookAViewingError, setBookAViewingError] = React.useState(null)
  const [bookAViewingMessage, setBookAViewingMessage] = React.useState(null)

  const propertyAddress = [
    property.addressLine1,
    property.addressLine2,
    property.city,
    property.postcode,
    property.country,
  ]
    .filter(addressLine => addressLine)
    .join(", ")

  const getStyle = () => {
    if (view === "list") {
      return propertyResultStyles.propertyResult
    } else if (view === "swipe") {
      return propertyResultStyles.swipePropertyResult
    }
  }

  const bookAViewing = async payload => {
    if (!context.tenantUser) {
      if (!payload.termsAndConditions) {
        return setBookAViewingError("Please agree to the terms and conditions")
      }

      if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(payload.password)) {
        return setBookAViewingError("Password does not match requirements")
      }

      if (payload.password !== payload.repeatPassword) {
        return setBookAViewingError("Passwords do not match")
      }

      if (!payload || !payload.email || !payload.password) {
        return setBookAViewingError("Please complete all fields")
      }
    }

    try {
      setBookingAViewing(true)
      setBookAViewingError("")
      setBookAViewingMessage("")
      const data = await apiBookViewing(property.id, {
        ...payload,
      })
      if (data.success) {
        if (data.value.accessToken) {
          dispatch({
            type: "loggedInToTenantsPortal",
            tokens: {
              accessToken: data.value.accessToken,
              refreshToken: data.value.refreshToken,
            },
          })
        }
        setBookAViewingMessage("All set! We'll be in contact.")
      } else if (data.errors) {
        setBookAViewingError(data.errors[0].reason)
      } else {
        setBookAViewingError("An error occurred, please try again")
      }
      setBookingAViewing(false)
    } catch (e) {
      console.log(e)
      setBookAViewingError("An error occurred, please try again.")
      setBookingAViewing(false)
    }
  }

  const renderDescription = () => {
    let description = []

    for (let i = 0; i < 10; i++) {
      let descriptionValue = property[`description${i}`]
      description.push(
        <div style={{ marginBottom: 10 }}>{descriptionValue}</div>
      )
    }

    return description
  }

  return (
    <div id={id} className={getStyle()} style={style || {}}>
      <Link
        to={`/property/${property.id}`}
        onClick={() => {
          document.body.style.overflow = "scroll"
          if (document.getElementById("page-wrapper")) {
            document.getElementById("page-wrapper").style.overflow = "scroll"
          }
        }}
        className={propertyResultStyles.propertyLink}
      ></Link>
      <div className={propertyResultStyles.propertyImageWrapper}>
        <PropertyGallery
          showThumbnails={false}
          images={Object.keys(property)
            .filter(key => key.startsWith("picture") && property[key])
            .map(key => property[key])}
          swipeMode={swipeMode}
          to={`/property/${property.id}`}
        />
        {!swipeMode && (
          <div
            role="button"
            tabIndex="0"
            className={propertyResultStyles.favouriteProperty}
            onClick={() => toggleFavouriteProperty()}
            onKeyPress={() => toggleFavouriteProperty()}
          >
            <i style={{ fontSize: 30 }} className="material-icons">
              {favouritedProperty ? "favorite" : "favorite_border"}
            </i>
          </div>
        )}
      </div>
      <div className={propertyResultStyles.propertyDetails}>
        <div className={propertyResultStyles.propertyDetailsLineOne}>
          <div className={propertyResultStyles.propertyBedsAndBathrooms}>
            <div className={propertyResultStyles.propertyBedsAndBathroomText}>
              <img
                className={propertyResultStyles.propertyBedsAndBathroomsIcon}
                src={require("../../../assets/bedroom.png")}
                alt="Bedrooms"
              />
              {property.numberOfBedrooms}bd
            </div>
            <div className={propertyResultStyles.propertyBedsAndBathroomText}>
              <img
                className={propertyResultStyles.propertyBedsAndBathroomsIcon}
                src={require("../../../assets/bathroom.png")}
                alt="Bathrooms"
              />
              {property.numberOfBathrooms}ba
            </div>
          </div>
        </div>
        <div className={propertyResultStyles.propertyDetailsLineOne}>
          <div className={propertyResultStyles.propertyPricePerWeek}>
            £{property.pricePerPersonPerWeekIncludingBills.toFixed(2)}pppw{" "}
            <span style={{ fontSize: 11 }}>incl. bills</span>
          </div>
        </div>
        <div className={propertyResultStyles.propertyDetailsLineTwo}>
          {propertyAddress}
        </div>
      </div>
      {view === "swipe" && (
        <div>
          <div style={{ padding: 20 }}>
            <Button
              title="Book viewing"
              onClick={() => setShowBookAViewing(true)}
            />
          </div>
          <div style={{ marginBottom: 40, padding: 20 }}>
            <div className={propertyResultStyles.descriptionHeading}>
              Description
            </div>
            {renderDescription()}
            <div>- This price includes household bills</div>
          </div>
          <div style={{ marginBottom: 20 }}>
            <PropertyMap
              containerElement={<div style={{ height: `300px` }} />}
              mapElement={<div style={{ height: `100%` }} />}
              center={{
                lat: property.location.latitude,
                lng: property.location.longitude,
              }}
              isMarkerShown={true}
              property={property}
              mapRef={mapRef}
            />
          </div>
          <div style={{ maxWidth: "900px", margin: "0 auto" }}>
            <PropertyGallery
              showThumbnails={true}
              images={Object.keys(property)
                .filter(key => key.startsWith("picture") && property[key])
                .map(key => property[key])}
              to={`/property/${property.id}`}
            />
          </div>
          <LivingInSection
            city={property.city && property.city.toLowerCase()}
          />
        </div>
      )}

      <DialogModal
        open={showBookAViewing}
        onClose={() => setShowBookAViewing(false)}
        hideCancelButton={true}
      >
        <div style={{ padding: 20, paddingBottom: 0 }}>
          <div style={{ fontSize: 22, marginBottom: 15 }}>Book a viewing</div>
          <Form
            initialPayload={
              context.tenantUser
                ? {
                  firstName: context.tenantUser.firstName,
                  lastName: context.tenantUser.lastName,
                  email: context.tenantUser.email,
                  existingAccount: true,
                }
                : {
                  existingAccount: false,
                }
            }
            options={bookPropertyViewingOptions}
            onSubmit={payload => bookAViewing(payload)}
            submitting={bookingAViewing}
            apiErrorMessage={bookAViewingError}
            apiSuccessMessage={bookAViewingMessage}
          />
        </div>
      </DialogModal>
    </div>
  )
}
