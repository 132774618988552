import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  primary: {
    fontSize: "16px",
    fontWeight: "bold",
    lineHeight: "24px",
  },
  secondary: {
    fontSize: "12px",
    lineHeight: "12px",
    color: "#d3d3d3",
  },
  // "@media only screen and (max-width: 800px)": {
  //   primary: {
  //     fontSize: "20px",
  //     fontWeight: "bold",
  //     color: "white",
  //     lineHeight: "27px",
  //   },
  //   secondary: {
  //     fontSize: "14px",
  //     lineHeight: "14px",
  //     color: "#d3d3d3",
  //   },
  // },
  // "@media only screen and (max-width: 500px)": {
  //   primary: {
  //     fontSize: "14px",
  //     lineHeight: "17px",
  //     marginBottom: "1px",
  //   },
  //   secondary: {
  //     fontSize: "10px",
  //     lineHeight: "12px",
  //     color: "#d3d3d3",
  //   },
  // },
  // "@media only screen and (max-width: 350px)": {
  //   secondary: {
  //     fontSize: "10px",
  //     lineHeight: "12px",
  //     color: "#d3d3d3",
  //   },
  // },
}));

export default function BottomBarText({ secondary, children }) {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.primary, {
        [classes.secondary]: secondary,
      })}
    >
      {children}
    </div>
  );
}
