import React, { useContext } from "react"
import { Link, navigate } from "gatsby"

import { GlobalDispatchContext } from "../../context/GlobalContextProvider"
import {
  login as apiLogin,
  checkAccountStatus as apiCheckAccountStatus,
} from "../../services/tenantsApiV2"

import PageWrapper from "../../components/PageWrapper"
import AuthenticationWrapper from "../../components/authenticationWrapper/AuthenticationWrapper"
import LoadingIcon from "../../components/loadingIcon/LoadingIcon"
import Form from "../../components/Form"

import * as authPageStyles from "../authPage.module.css"

const checkAccountFormOptions = {
  fields: [
    {
      name: "email",
      placeholder: "Email address",
      type: "email",
      required: true,
    },
  ],
  submitText: "Sign in",
}

const loginFormOptions = {
  fields: [
    {
      name: "email",
      placeholder: "Email address",
      type: "email",
      required: true,
      disabled: true,
    },
    {
      name: "password",
      placeholder: "Password",
      type: "password",
      required: true,
    },
  ],
  submitText: "Sign in",
}

const Login = ({ location }) => {
  const dispatch = useContext(GlobalDispatchContext)

  const [loggingIn, setLoggingIn] = React.useState(false)
  const [loginError, setLoginError] = React.useState("")
  const [checkAccountStatusError, setCheckAccountStatusError] = React.useState(
    ""
  )
  const [showLogin, setShowLogin] = React.useState(false)
  const [
    showSentEmailToSetupAccount,
    setShowSentEmailToSetupAccount,
  ] = React.useState(false)
  const [showNoAccount, setShowNoAccount] = React.useState(false)
  const [checkingAccountStatus, setCheckingAccountStatus] = React.useState(
    false
  )
  const [loginPayload, setLoginPayload] = React.useState({})

  const login = async payload => {
    if (!payload || !payload.email || !payload.password) {
      return setLoginError("Please complete all fields")
    }

    setLoginError("")

    try {
      setLoggingIn(true)
      const data = await apiLogin(payload)

      if (data.success) {
        dispatch({
          type: "loggedInToTenantsPortal",
          tokens: {
            accessToken: data.value.accessToken,
            refreshToken: data.value.refreshToken,
          },
        })
        navigate("/tenants/app/tenancies")
      } else if (data.errors) {
        setLoginError(data.errors[0].reason)
      } else {
        setLoginError("An error occurred trying to login")
      }
      setLoggingIn(false)
    } catch (e) {
      setLoggingIn(false)
      setLoginError(e.message)
    }
  }

  const checkAccountStatus = async payload => {
    if (!payload || !payload.email) {
      return setLoginError("Please complete all fields")
    }

    setCheckAccountStatusError("")

    try {
      setCheckingAccountStatus(true)
      const data = await apiCheckAccountStatus(payload)

      if (data.success) {
        if (data.value === "AccountSetup") {
          setLoginPayload(payload)
          setShowLogin(true)
        } else if (data.value === "AccountIncomplete") {
          setShowSentEmailToSetupAccount(true)
        } else if (data.value === "NoAccount") {
          setShowNoAccount(true)
        }
      } else if (data.errors) {
        setCheckAccountStatusError(data.errors[0].reason)
      } else {
        setCheckAccountStatusError("An error occurred")
      }
      setCheckingAccountStatus(false)
    } catch (e) {
      setCheckingAccountStatus(false)
      setCheckAccountStatusError(e.message)
    }
  }

  const renderContent = () => {
    if (showSentEmailToSetupAccount) {
      return (
        <>
          <AuthenticationWrapper
            title="We've sent you an email"
            description={
              <div>
                <div style={{ marginBottom: 10 }}>
                  Please check your emails for a link to finish setting up your
                  account.
                </div>
                <div>
                  If you do not receive the email in a few minutes, check your
                  “junk mail” folder or “spam” folder.
                </div>
              </div>
            }
          ></AuthenticationWrapper>
        </>
      )
    } else if (showNoAccount) {
      return (
        <>
          <AuthenticationWrapper
            title="No Account Found"
            description="We couldn't find an account associated with the provided email address. This is usually because you signed up to Bunch with a different email address."
          ></AuthenticationWrapper>
        </>
      )
    } else if (showLogin) {
      return (
        <>
          <AuthenticationWrapper
            title="Tenant portal"
            description="Sign in to access your tenant portal"
          >
            <Form
              key="loginForm"
              options={loginFormOptions}
              onSubmit={payload => login(payload)}
              submitting={loggingIn}
              apiErrorMessage={loginError}
              initialPayload={loginPayload}
            />
            <Link to="/tenants/app/forgot-password">
              <div>Forgot your password</div>
            </Link>
          </AuthenticationWrapper>
        </>
      )
    }

    return (
      <>
        <AuthenticationWrapper
          title="Tenant portal"
          description="Sign in to access your tenant portal"
        >
          <Form
            key="checkAccountForm"
            options={checkAccountFormOptions}
            onSubmit={payload => checkAccountStatus(payload)}
            submitting={checkingAccountStatus}
            apiErrorMessage={checkAccountStatusError}
          />
          <Link to="/tenants/app/forgot-password">
            <div>Forgot your password</div>
          </Link>
        </AuthenticationWrapper>
      </>
    )
  }

  return (
    <PageWrapper location={location} hideHeader={true} hideHeaderBorder={true}>
      <div className={authPageStyles.authPage}>{renderContent()}</div>
    </PageWrapper>
  )
}

export default Login
