import React from "react"
import { v4 } from "uuid"
import { OptimizelyFeature } from "@optimizely/react-sdk"
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles"

import { QuoteExpiryTimer, TrustpilotFooter } from "."

import PageWrapper from "../../../components/PageWrapper"
import QuoteBasket from "../../../components/quoteBasket/QuoteBasket"

import QuoteSummary from "../quote/containers/quoteCreation/QuoteSummary"
import HoldingPage from "./HoldingPage";

export default function Wrapper({
  children,
  location,
  matchingQuotingToolVersion,
  toolId,
  quoteId,
  group,
  isHomeownerOrRenter,
  quoteSummaryData,
  summaryContent,
  fullScreen,
  showTrustpilotWidget,
  backgroundColor,
  showHoldingPage,
  progress
}) {
  const useStyles = makeStyles(() => ({
    content: {
      padding: 0,
      display: "grid",
      gridTemplateColumns: "1fr minmax(400px, 450px)",
      direction: "ltr",
      "@media (max-width: 1300px)": {
        display: "block",
      },
    },
    fullScreen: {
      width: '100%',
      gridTemplateColumns: 'none'
    }
  }))

  const classes = useStyles()

  if (showHoldingPage) {
    return (
      <PageWrapper
        location={location}
        quoteViewMode={true}
        backgroundColor={backgroundColor || "#fff"}
        hideHeaderBorder={true}
        hideMobileMenuButton={true}
        topBannerBackgroundColor={
          matchingQuotingToolVersion.topBarBackgroundHexCode
        }
        logoImageUrl={matchingQuotingToolVersion.logoImageUrl}
      >

        <HoldingPage
          progress={progress}
        />

        {showTrustpilotWidget && (
          <TrustpilotFooter />
        )}

      </PageWrapper>
    )
  } else {

    return (
      <PageWrapper
        location={location}
        quoteViewMode={true}
        backgroundColor={backgroundColor || "#fff"}
        hideHeaderBorder={true}
        hideMobileMenuButton={true}
        topBannerBackgroundColor={
          matchingQuotingToolVersion.topBarBackgroundHexCode
        }
        logoImageUrl={matchingQuotingToolVersion.logoImageUrl}
        quoteTotalSectionVisible={quoteSummaryData}
      >
        <OptimizelyFeature
          feature="quote_price_expiry"
          overrideUserId={quoteId || v4()}
        >
          {(isEnabled, variables) =>
            isEnabled ? (
              <QuoteExpiryTimer
                matchingQuotingToolVersion={matchingQuotingToolVersion}
                toolId={toolId}
                quoteId={quoteId}
                group={group}
              />
            ) : (
              <div />
            )
          }
        </OptimizelyFeature>
        <div className={clsx(classes.content, {
          [classes.fullScreen]: fullScreen
        })}>
          <div>{children}</div>
          {quoteSummaryData && (
            <QuoteBasket>
              {summaryContent}
              {!summaryContent && (
                <QuoteSummary
                  quote={{
                    services: quoteSummaryData.services,
                    tenancyDetails: quoteSummaryData.tenancyDetails,
                    packageDetails: quoteSummaryData.packageDetails,
                  }}
                  matchingQuotingToolVersion={matchingQuotingToolVersion}
                  isSummaryPage
                  isHomeownerOrRenter={isHomeownerOrRenter}
                />
              )}
            </QuoteBasket>
          )}
        </div>
        {showTrustpilotWidget && (
          <TrustpilotFooter />
        )}
      </PageWrapper>
    )
  }
}
